// d style layouts

.disc-flex-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @include x-rem(gap, 40px);
  @include x-rem(margin-bottom, 46px);
  @include breakpoint($bp-m) {
    flex-direction: row;
  }
}
.disc-manager-layout {
 
}
.disc-manager-layout__top {
    @extend .disc-flex-wrapper; 
}
.disc-manager-layout__box1-title {
  color: $color-base;
  margin-bottom: 27px;
}
.disc-manager-layout__sbs {
  @extend .disc-flex-wrapper;
  align-items: flex-start;
}
