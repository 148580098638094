.training-kits {
  @include resetList;
  @include breakpoint($bp-small) {
    display: flex;
    flex-wrap: wrap;
  }
}
.training-kits__item {
  position: relative;
  @include breakpoint($bp-small) {
    width: 50%;
  }
  @include breakpoint($bp-m) {
    width: 33.33333333%;
  }
}
.training-kits__button {
  @include resetBtn;
  overflow: hidden;

  transition: $trans;
  &.active {
    opacity: 0;
    height: 0;
  }
  &:hover,
  &:focus {
    .training-kits__img {
      webkit-filter: blur(1px);
      filter: blur(1px);
      transform: scale(1.2);
    }
  }
}
.training-kits__front {
}
.training-kits__img {
  transition: $trans;
}
.training-kits__back[data-expander-target] {
  display: block;
  opacity: 0;
  height: 0;
  transition: $trans;

  &.active {
    height: fit-content;
    opacity: 1;
  }
}
 
.training-kits__back {
  background: $color-primary;
 
}
.training-kits__back-container{
  @include x-rem(padding, 124px 32px);
}
.training-kits__title {
  margin-bottom: 1rem;
  color: $color-invert;
}
.training-kits__sub {
  margin-bottom: 1rem;
}
.training-kits__text {
  margin-bottom: 1rem;
}
