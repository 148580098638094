// start featured insights
.blog-listing {
  @include resetList;
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 44px);
  @include breakpoint($bp-small) {
    @include x-rem(gap, 52px);
  }
  @include breakpoint($bp-m) {
    flex-direction: row;
    @include x-rem(gap, 24px);
  }
  @include breakpoint($bp-large) {
    @include x-rem(gap, 22px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(gap, 27px);
  }
  @include breakpoint($bp-xx-large) {
    @include x-rem(gap, 32px);
  }
}
.blog-listing__item {
  @include resetList;
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 21px);

  @include breakpoint($bp-small) {
    flex-direction: row;
    align-items: center;
    @include x-rem(gap, 24px);
  }
  @include breakpoint($bp-m) {
    flex-direction: column;
    align-items: initial;
    flex: 1;
  }
  @include breakpoint($bp-xx-large) {
    @include x-rem(gap, 32px);
  }
}
.blog-listing__imgbox {
  position: relative;
  padding-bottom: 56.25%;
  @include x-rem(min-height, 223px);
  overflow: hidden;
  border-radius: 10px;
  img {
    @include fitImage;
    position: absolute;
    top: 0;
    left: 0;
  }
  @include breakpoint($bp-small) {
    width: 50%;
  }
  @include breakpoint($bp-m) {
    width: 100%;
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(min-height, 420px);
  }

  &:hover,
  &:focus {
    img {
      transform: scale(1.1);
    }
  }
}
.blog-listing__content {
  @include breakpoint($bp-small) {
    flex: 1;
  }

  @include breakpoint($bp-m) {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
.blog-listing__link {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
.blog-listing__title {
  @include x-rem(margin-bottom, 15px);
  @include x-rem(font-size, 20px);
  @include x-rem(line-height, 27px);
  font-weight: $fontWeight-bold;
  @include breakpoint($bp-small) {
    @include x-rem(margin-bottom, 18px);
  }
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 9px);
  }
  @include breakpoint($bp-xx-large) {
    @include x-rem(margin-bottom, 16px);
    @include x-rem(font-size, 32px);
    @include x-rem(line-height, 44px);
  }
}

.blog-listing__info {
  margin: 0;
  font-size: 1rem;
  @include x-rem(line-height, 24px);
  color: $color-black5;
  @include breakpoint($bp-m) {
    margin-top: auto;
  }
}

// end featured insights

// start solutions
.solutions-listing {
  @include resetList;
  @include x-rem(margin-bottom, 32px);
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @include x-rem(gap, 32px);
  @include breakpoint($bp-small) {
    @include x-rem(max-width, 488px);
    margin: 0 auto 32px auto;
  }
  @include breakpoint($bp-m) {
    @include x-rem(gap, 29px 26px);
    grid-template-columns: repeat(2, 1fr);
    max-width: 100%;
  }
  @include breakpoint($bp-large) {
    @include x-rem(gap, 34px 32px);
    @include x-rem(max-width, 864px);
  }
  @include breakpoint($bp-x-large) {
    grid-template-columns: repeat(3, 1fr);
    max-width: 100%;
  }
}

.solutions-listing__item {
  width: 100%;
  background-color: $body-bg;
  box-shadow: 25px 25px 50px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  @include x-rem(padding, 32px 10px 32px 32px);
  border: 1px solid $color-gray2;
  @include breakpoint($bp-m) {
    @include x-rem(padding, 32px);
  }
}

.solutions-listing__header {
  @include x-rem(gap, 16px);
  @include x-rem(margin-bottom, 16px);
  @include breakpoint($bp-x-small) {
    display: flex;
    align-items: center;
  }
}
.solutions-listing__svg {
  @include x-rem(height, 64px);
  @include x-rem(width, 64px);
  flex-shrink: 0;
}
.solutions-listing__title {
  margin: 0;
  @include x-rem(font-size, 28px);
  @include x-rem(line-height, 38px);
}
.solutions-listing__text {
  @include x-rem(font-size, 20px);
  @include x-rem(line-height, 27px);
  letter-spacing: -0.01em;
  font-weight: $fontWeight-bold;
  color: $color-black5;
  @include x-rem(margin-bottom, 32px);
}
.solutions-box__disclaimer {
  text-align: center;
  @include x-rem(margin-top, 32px);
  p:last-child {
    margin: 0;
  }
  a,
  p {
    color: $color-black5;
  }
}
// end solutions
.testimonial-listing-box {
  @include x-rem(padding-bottom, 84px);
  @include breakpoint($bp-m) {
    @include x-rem(padding-bottom, 100px);
  }
  &--b-top {
    border-top: 1px solid $color-gray4;
    @include x-rem(padding-top, 50px);
    @include breakpoint($bp-m) {
      @include x-rem(padding-top, 142px);
    }
  }
  &--b-bottom {
    border-bottom: 1px solid $color-gray4;
  }
}
.testimonial-listing {
  @include resetList;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @include x-rem(gap, 32px);

  @include breakpoint($bp-small) {
    grid-template-columns: repeat(2, 1fr);
    @include x-rem(gap, 24px 29px);
  }
  @include breakpoint($bp-m) {
    @include x-rem(max-width, 640px);
    margin: 0 auto;
    grid-template-columns: repeat(2, 1fr);
    @include x-rem(gap, 29px 32px);
  }
  @include breakpoint($bp-x-large) {
    max-width: 100%;
    grid-template-columns: repeat(4, 1fr);
    @include x-rem(gap, 19px);
  }
}
.testimonial-listing__item {
  background: $body-bg;
  border: 1px solid $color-gray2;
  box-shadow: 25px 25px 50px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  @include x-rem(padding, 32px 22px 34px 32px);
  height: 100%;
  @include x-rem(min-height, 334px);
  @include breakpoint($bp-small) {
    @include x-rem(padding, 32px 22px 34px 20px);
  }
  @include breakpoint($bp-m) {
    @include x-rem(padding, 32px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(padding, 32px 21px 32px 23px);
    @include x-rem(min-height, 362px);
  }
}
.testimonial-listing__quote {
  font-size: 1rem;
  @include x-rem(line-height, 24px);
  color: $color-black5;
  @include x-rem(margin-bottom, 43px);
}
.testimonial-listing__bottom {
  margin-top: auto;
}
.testimonial-listing__svg {
  @include x-rem(margin-bottom, 16px);
  @include x-rem(height, 39px);
  @include x-rem(width, 150px);
  @include breakpoint($bp-small) {
    @include x-rem(height, 32px);
    @include x-rem(width, 163px);
    @include x-rem(margin-bottom, 20px);
  }
  @include breakpoint($bp-m) {
    @include x-rem(height, 39px);
    @include x-rem(width, 150px);
    @include x-rem(margin-bottom, 16px);
  }
}
.testimonial-listing__name {
  @include x-rem(margin-bottom, 8px);
  font-weight: $fontWeight-bold;
  @include x-rem(font-size, 20px);
  @include x-rem(line-height, 27px);
  color: $color-primary;
}
.testimonial-listing__job-title {
  margin: 0;
  color: $color-black5;
  font-size: 1rem;
  @include x-rem(line-height, 24px);
}

// start skills listing
.skills-listing {
  @include resetList;
  @include breakpoint($bp-large) {
    display: flex;
  }
}

.skills-listing__item {
  @include breakpoint($bp-small) {
    display: flex;
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
  @include breakpoint($bp-large) {
    flex: 1;
    position: relative;
    @include x-rem(min-height, 448px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(min-height, 542px);
  }
  @include breakpoint($bp-xx-large) {
    @include x-rem(min-height, 650px);
  }
}
.skills-listing__button {
  @include resetBtn;
  pointer-events: none;
  display: none;
  @include breakpoint($bp-small) {
    display: flex;
    width: 50%;
  }
  @include breakpoint($bp-large) {
    overflow: hidden;
    position: absolute;
    z-index: 3;
    height: 100%;
    width: 100%;
    pointer-events: initial;

    &.active {
      opacity: 0;
    }
  }

  &:hover,
  &:focus,
  &:active {
    .skill-listing__img {
      transform: scale(1.2);
    }
  }
}

.skill-listing__front {
  position: relative;
  @include bgImg;
  width: 100%;
  height: 100%;

  @include breakpoint($bp-large) {
    min-height: initial;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include x-rem(padding-bottom, 64px);
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.45) 9.78%,
      rgba(0, 0, 0, 0) 46.7%
    );
  }
}
.skill-listing__img {
  transition: $trans;
  @include bgImg;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.skills-listing__title2,
.skills-listing__top-title2 {
  display: none;
  position: relative;
  z-index: 12;
  @include breakpoint($bp-large) {
    display: block;
    color: $color-invert;
    @include x-rem(font-size, 32px);
    @include x-rem(line-height, 44px);
    margin: 0;
  }
}
.skills-listing__top-title2 {
  font-weight: normal;
}
.skill-listing__back {
  background: $color-primary;
  @include x-rem(padding, 43px 20px);
  color: $color-invert;
  text-align: center;
  display: block !important;
  @include breakpoint($bp-small) {
    @include x-rem(padding, 40px 25px);
    width: 50%;
  }
  @include breakpoint($bp-large) {
    @include x-rem(padding, 45px 20px);
    width: 100%;
    opacity: 0;
    &.active {
      opacity: 1;
      position: relative;
      z-index: 20;
    }
  }
}
.skill-listing__back-flex {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.skills-listing__icon {
  @include x-rem(height, 100px);
  @include x-rem(width, 100px);
  margin: 0 auto;
  @include x-rem(margin-bottom, 16px);
  @include breakpoint($bp-small) {
    @include x-rem(margin-bottom, 3px);
  }
  @include breakpoint($bp-large) {
    @include x-rem(margin-bottom, 5px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(margin-bottom, 8px);
  }
  @include breakpoint($bp-xx-large) {
    @include x-rem(margin-bottom, 32px);
  }
}
.skills-listing__title {
  color: $color-invert;
  @include x-rem(margin-bottom, 16px);
  @include breakpoint($bp-large) {
    @include x-rem(margin-bottom, 11px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(margin-bottom, 23px);
  }
  @include breakpoint($bp-xx-large) {
    @include x-rem(margin-bottom, 32px);
  }
}
.skills-listing__top-title {
  font-weight: normal;
  @include x-rem(margin-bottom, 5px);
  @include x-rem(font-size, 32px);
  @include x-rem(line-height, 44px);
}
.skills-listing__text {
  margin: 0;
  color: $color-invert;
}
.skills-listing__cta {
  display: inline-block;
  text-decoration: underline solid currentColor 2px;
  text-underline-offset: 9px;
  @include x-rem(font-size, 17px);
  @include x-rem(line-height, 23px);
  @include x-rem(margin-top, 22px);
  @include breakpoint($bp-large) {
    @include x-rem(margin-top, 32px);
  }
  &:hover,
  &:focus {
  }
}

// end skills listing
//start skills listing plain variant
.image-cards-plain {
  @include x-rem(margin-bottom, 58px);
  @media (min-width: $bp-m) {
    @include x-rem(margin-bottom, 108px);
  }

  .skills-listing {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    @media (min-width: 31.25em) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $bp-m) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .skills-listing__item {
    @include x-rem(min-height, 326px);
    width: 100%;
    @media (min-width: $bp-x-large) {
      @include x-rem(min-height, 480px);
    }
  }
  .skills-listing__button {
    width: 100%;
    height: 100%;
    display: none;
    &:hover,
    &:focus,
    &:active {
    }
    @media (min-width: $bp-large) {
      display: block;
    }
  }
  .skill-listing__front {
    &:before {
      content: none;
    }
  }
  .skill-listing__img {
    background-size: contain;

    @media (min-width: $bp-x-large) {
      // background-size: cover;
    }
  }
  .skill-listing__back {
    text-align: left;
    width: 100%;
    height: 100%;
  }
  .skill-listing__back-flex {
    align-items: flex-start;
    justify-content: center;
  }
  .skills-listing__title {
    margin-bottom: 1rem;
  }

  .skills-listing__title2,
  .skills-listing__top-title2 {
    display: none;
  }
  a.skills-listing__cta {
    @extend .btn;
    @extend .primary-button;
    color: $color-black2;
  }
}

// end skills listing plain variant

// start benefit listing
.benefits-listing1 {
  @include resetList;
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 46px);
  @include breakpoint($bp-x-large) {
    flex-direction: row;
    @include x-rem(gap, 28px);
  }
}
.benefits-listing1__item {
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 19px);

  @include breakpoint(42.5rem) {
    flex-direction: row;
    align-items: center;
    @include x-rem(gap, 33px);
  }
  @include breakpoint($bp-x-large) {
    flex-direction: column;
  }
}
.benefits-listing1__imgbox {
  @include bgImg;
  width: 100%;
  @include x-rem(height, 163px);

  @include breakpoint($bp-small) {
    @include x-rem(height, 234px);
  }
  @include breakpoint(42.5rem) {
    flex: 1;
  }
  @include breakpoint($bp-x-large) {
    flex: initial;
  }
}
.benefits-listing1__content-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  @include breakpoint(42.5rem) {
    flex: 1;
  }
}
.benefits-listing1__title {
  @include x-rem(margin-bottom, 7px);
  @include x-rem(font-size, 20px);
  @include x-rem(line-height, 27px);
}
.benefits-listing1__text {
  margin: 0;
  font-size: 1rem;
  @include x-rem(line-height, 27px);
  margin-top: auto;
}
// end benefit listing

.standard-card-listing {
  @include resetList;
  display: grid;
  grid-template-columns: 1fr;
  @include x-rem(gap, 45px);
  @include breakpoint($bp-m) {
    grid-template-columns: 1fr 1fr;
    @include x-rem(gap, 61px 34px);
  }
}

.standard-card-listing__item {
  display: flex;
  align-items: flex-s tart;
  @include x-rem(gap, 20px);
  @include breakpoint($bp-m) {
    @include x-rem(gap, 34px);
  }
}
.standard-card-listing__icon {
  flex-shrink: 0;
  @include x-rem(height, 84px);
  @include x-rem(width, 76px);
}
.standard-card-listing__text {
  flex: 1;
}
