.thank-you-page {
  @include x-rem(padding-top, 91px);
  @include x-rem(padding-bottom, 80px);
  @include breakpoint($bp-small) {
    @include x-rem(padding-bottom, 110px);
  }

  @include breakpoint($bp-m) {
    @include x-rem(padding-bottom, 210px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(padding-bottom, 250px);
  }
}

// todo
.thank-you-page__top {
}

.thank-you-page__middle {
}
.thank-you-page__desc {
}
.wrap_thanks {
}
.thanksCard {
}
.masonry-col_tab {
}
.thanksCard h3 {
}
.masonry-col_tab a {
  @extend .primary-button;
}
.thank-you-page__bottom {
}
.thank-you-page__ex-link {
  a,
  .pushBtm20 {
    @extend .heavy-link;
  }
}

.tym {
  text-align: center;
  width: 100%;
  @include x-rem(max-width, 482px);
  h2 {
    @include x-rem(font-size, 30px);
    line-height: normal;
    @include x-rem(margin-bottom, 14px);
  }
  p {
    margin: 0;
  }
}
.thank-you-details_col1,
.thank-you-details_col2 {
  flex: 1;
}

.thank-you-details_col2 { 
  border: 1px solid $color-gray4;
  box-shadow: $box-shadow1;
  border-radius: 10px;
  padding: 2rem;
}
.wrap_thanks {
  display: flex;
  gap: 60px;
  flex-direction: column;
  align-items: center;
}
.thanksCard {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column-reverse;
}
.masonry-col_tab {
  text-align: center;
}
.masonry-col_tab h3 {
  margin: 0;
}
.masonry-col_tab .icon_xlg {
  height: 150px;
  width: 150px;
}
.masonry-col_tab .btn {
  margin-top: 20px;
}
@media (min-width: 36em) {
  .wrap_thanks {
    align-items: flex-start;
  }
  .thanksCard {
    flex-direction: row-reverse;
  }
  .masonry-col_tab {
    text-align: left;
  }
}
@media (min-width: 48em) {
  .wrap_thanks {
    gap: 100px;
    flex-direction: row;
    justify-content: center;
  }
  .thanksCard {
    flex-direction: column-reverse;
    gap: 50px;
    height: 100%;
  }
  .masonry-col_tab {
    text-align: center;
  }
}
.thank-you-content {
  display: flex;
  flex-direction: column;
  gap: 77px;
  gap: 2rem;
}

@media (min-width: 62em) {
  .thank-you-content {
    padding: 1rem 0 1rem 0;
    flex-direction: row;
    align-items: flex-start;
    gap: 2.125rem;
    display: flex;
  }
}
