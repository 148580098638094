.personality-model {
  position: relative;
  @include x-rem(margin-top, 34px);
}
.personality-model__img {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  @include x-rem(height, 335px);
  @include x-rem(width, 335px);

  @include breakpoint($bp-small) {
    @include x-rem(height, 344px);
    @include x-rem(width, 344px);
    top: 28%;
  }
  @include breakpoint($bp-m) {
    @include x-rem(height, 402px);
    @include x-rem(width, 402px);
    top: 26%;
  }

  @include breakpoint($bp-large) {
    @include x-rem(height, 554px);
    @include x-rem(width, 554px);
    top: 14%;
  }

  @include breakpoint($bp-x-large) {
    @include x-rem(height, 640px);
    @include x-rem(width, 640px);
    top: 12%;
  }
}
.personality-listing {
  @include resetList;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @include x-rem(gap, 20px);
  @include breakpoint($bp-small) {
    grid-template-columns: repeat(2, 1fr);
    @include x-rem(gap, 257px 25px);
  }
  @include breakpoint($bp-m) {
    grid-template-columns: repeat(2, 1fr);
    @include x-rem(gap, 280px 88px);
  }
  @include breakpoint($bp-large) {
    grid-template-columns: repeat(2, 1fr);
    @include x-rem(gap, 391px 120px);
  }
  @include breakpoint($bp-x-large) {
    grid-template-columns: repeat(2, 1fr);
    @include x-rem(gap, 372px 257px);
  }
  @include breakpoint($bp-xx-large) {
    grid-template-columns: repeat(2, 1fr);
    @include x-rem(gap, 372px 257px);
  }
}
.personality-listing__item {
  position: relative;
  z-index: 3;
  background: $body-bg;
  border: 1px solid $category-green;
  box-shadow: 25px 25px 50px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  @include x-rem(padding, 16px 20px 22px 20px);

  &--green {
    border: 1px solid $category-green;
  }
  &--red {
    border: 1px solid $category-red;
  }

  &--blue {
    border: 1px solid $category-blue;
  }

  &--yellow {
    border: 1px solid $category-yellow;
  }
  &:nth-child(2) {
    @include x-rem(margin-bottom, 269px);
    @include breakpoint($bp-small) {
      margin: 0;
    }
  }

  @include breakpoint($bp-small) {
    @include x-rem(padding, 16px 18px 13px 18px);
  }
  @include breakpoint($bp-m) {
    @include x-rem(padding, 16px 32px 32px 32px);
  }
  @include breakpoint($bp-large) {
    @include x-rem(padding, 16px 32px 16px 32px);
  }
}

// swapping the positions for the yellow + blue variants on mobile
.personality-listing__item {
  &--yellow {
    grid-row: 4;
  }
  &--blue {
    grid-row: 3;
  }
  @include breakpoint($bp-small) {
    &--yellow {
      grid-row: initial;
    }
    &--blue {
      grid-row: initial;
    }
  }
}

.personality-listing__top {
  display: flex;
  align-items: center;
  @include x-rem(gap, 13px);
  @include x-rem(margin-bottom, 11px);
  @include breakpoint($bp-small) {
    @include x-rem(margin-bottom, 8.5px);
  }
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 14px);
    @include x-rem(gap, 16px);
  }
}
.personality-listing__title {
  margin: 0;
  @include x-rem(font-size, 18px);
  @include x-rem(line-height, 25px);
  font-weight: $fontWeight-bold;
  @include breakpoint($bp-m) {
    @include x-rem(font-size, 20px);
    @include x-rem(line-height, 27px);
  }
}
.personality-listing__text {
  p:last-child {
    margin: 0;
  }
}
