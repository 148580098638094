// listing / form
.find-a-partner {
  @include x-rem(padding-bottom, 118px); 
  @include breakpoint($bp-large) {
    @include x-rem(padding-bottom, 130px);
  }
}
.find-a-partner__title {
  @include x-rem(margin-bottom, 48px);
  text-align: center;
}
.find-a-partner__form {
  @include x-rem(margin-bottom, 48px);
  @include breakpoint($bp-large) {
    @include x-rem(margin-bottom, 115px);
  }
}

.find-a-partner__loadmore {
  display: block;
  margin: 0 auto;
  @include x-rem(padding, 16px 32px);
  @include x-rem(margin-top, 76px);
  @include breakpoint($bp-large) {
    @include x-rem(margin-top, 92px);
  }
}
// details
.partner-details {
  @include x-rem(padding-bottom, 86px);
  @include x-rem(padding-top, 32px);
  @include breakpoint($bp-large) {
    @include x-rem(padding-bottom, 145px);
    @include x-rem(gap, 40px);
  }

  .search-results__item {
    @include x-rem(padding, 33px 25px 24px 25px);
    @include x-rem(margin-top, 53px);
    flex-direction: column;
    @include x-rem(gap, 22px);

    @include breakpoint(62.5em) {
      @include x-rem(gap, 40px);
    }
    @include breakpoint($bp-large) {
      @include x-rem(padding, 45px 40px 61px 48px); 
      @include x-rem(margin-top, 70px);
      
    }
  }
  .search-results__content {
    max-width: 100%;
  }
  .search-results__item-wrap {
    display: flex;
    flex-direction: column;
    @include x-rem(gap, 26px);
    @include breakpoint(62.5em) {
      flex-direction: row;
      @include x-rem(gap, 47px);
    }
  }
  .partner-details__desc {
    p:last-child {
      margin: 0;
    }
  }
}
