// side by side variations (3) s , m , l

.sbs-box {
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 10px);
  @include breakpoint($bp-m) {
    flex-direction: row;
    align-items: center;
    .sbs-box__item {
    }
  }
}
.sbs-box--reverse {
  @include breakpoint($bp-m) {
    flex-direction: row-reverse;
  }
}
.sbs-box--even {
  .sbs-box__item {
    @include breakpoint($bp-m) {
      width: 50%;
    }
  }
}
.sbs-box--uneven {
  @include x-rem(gap, 20px);
  .sbs-box__item--textbox {
    @include breakpoint($bp-m) {
      width: 66.66%;
    }
  }
}
