.interior-hero {
}
.interior-hero__top {
  @extend .bottom-curve;
  z-index: 2;
  @include bgImg;
  @include x-rem(min-height, 400px);
  @include x-rem(padding, 155px 0 20px 0);
  @include breakpoint($bp-m) {
    @include x-rem(min-height, 550px);
  }
  @include breakpoint($bp-large) {
    @include x-rem(min-height, 700px);
    @include x-rem(padding, 284px 0 20px 0);
  }
  @include breakpoint($bp-x-large) {
    background-position: initial;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: block;
    background: rgba(0, 0, 0, 0.35);
  }
}

.interior-hero__top--grayed {
  &:before {
    content: "";
    background-image: url("../images/shapes/bottomcurve-gray.png");
  }
}

.interior-hero__title {
  margin: 0;
  color: $color-invert;
  @include x-rem(font-size, 44px);
  @include x-rem(line-height, 52px);
  text-align: center;
  position: relative;
  z-index: 3;
  width: fit-content;
  margin: 0 auto;

  @include breakpoint($bp-large) {
    @include x-rem(font-size, 72px);
    @include x-rem(line-height, 72px);
  }
}
.interior-hero__bottom {
  background: $color-gray2;
  @include x-rem(padding, 64px 0);
}
.interior-hero__bottom .container {
  max-width: $container-size-paragraph;
}
.interior-hero__text {
  p:last-child {
    margin: 0;
  }
}
.interior-hero__text2 {
  margin: 0;
  text-align: center;
  @include x-rem(font-size, 24px);
  @include x-rem(line-height, 34px);
  letter-spacing: -0.01em;
  p,
  a,
  ol,
  ul {
    @include x-rem(font-size, 24px);
    @include x-rem(line-height, 34px);
  }
  p:last-child {
    margin: 0;
  }

  @include breakpoint($bp-large) {
    @include x-rem(font-size, 32px);

    p,
    a,
    ol,
    ul {
      @include x-rem(font-size, 32px);
      @include x-rem(line-height, 44px);
    }
  }
}
