/* ================================================================
   Typography Styles
   ================================================================ */

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0 0 $typography-margin-bottom;
  font-family: $font-heading;
  color: $color-primary;
}

h1,
.h1 {
  @include x-rem(font-size, 50px);
  @include x-rem(line-height, 56px);
  font-weight: $fontWeight-extrabold;
  letter-spacing: -0.01em;
  @include breakpoint($bp-large) {
    @include x-rem(font-size, 62px);
    @include x-rem(line-height, 62px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(font-size, 72px);
    @include x-rem(line-height, 82px);
  }
}
h2,
.h2 {
  @include x-rem(font-size, 32px);
  @include x-rem(line-height, 44px);
  font-weight: $fontWeight-extrabold;
  letter-spacing: -0.01rem;

  @include breakpoint($bp-small) {
    @include x-rem(font-size, 38px);
    @include x-rem(line-height, 52px);
  }
  @include breakpoint($bp-large) {
    @include x-rem(font-size, 42px);
    @include x-rem(line-height, 56px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(font-size, 48px);
    @include x-rem(line-height, 64px);
  }
}
h3,
.h3 {
  @include x-rem(font-size, 28px);
  @include x-rem(line-height, 38px);
  font-weight: $fontWeight-extrabold;
  letter-spacing: -0.01em;

  @include breakpoint($bp-x-large) {
    @include x-rem(font-size, 32px);
    @include x-rem(line-height, 44px);
  }

  &--large {
    @include x-rem(font-size, 36px);
    @include breakpoint($bp-x-large) {
      @include x-rem(font-size, 38px);
    }
  }
}
h4,
.h4 {
  @include x-rem(font-size, 28px);
  @include x-rem(line-height, 38px);
  font-weight: $fontWeight-extrabold;
}
h5,
.h5 {
  color: $color-black5;
  @include x-rem(font-size, 24px);
  line-height: normal;
  font-weight: $fontWeight-bold;
  letter-spacing: -0.36px;
}
// Very simple blockquote styling
blockquote {
  margin: 1em 1.5em;
  padding-left: 1.5em;
  border-left: 5px solid hsl(0, 0%, 85%);
}

.smallest {
  font-size: 0.8rem;
}
.smaller {
  font-size: 0.9rem;
}
.larger {
  font-size: 1.1rem;
}
.largest {
  @include x-rem(font-size, 18px);
  @include x-rem(line-height, 32px);
  @include breakpoint($bp-x-large) {
    @include x-rem(font-size, 20px);
  }
}
a {
  @extend .largest;
  color: $color-category-blue;
  transition: $trans;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
a.heavy-link {
  color: $color-black5;
  transition: $trans;
  font-weight: $fontWeight-bold;
  text-decoration-thickness: 2px;
  text-decoration: underline solid currentColor 2px;
  text-underline-offset: 5px;

  &--no-offset {
    text-underline-offset: initial;
  }
  &:hover,
  &:focus {
    text-decoration: underline;
    color: $color-link-heavy;
  }
}

.button.primary-button a {
  color: $color-black5;
}
p {
  color: $color-black5;
  margin: 0 0 32px;
  @extend .largest;
}
p.thirtysix {
  @include breakpoint($bp-x-large) {
    @include x-rem(line-height, 36px);
  }
}
ol,
ul {
  @extend .largest;
  color: $color-black5;
  @include x-rem(font-size, 20px);
  @include x-rem(line-height, 32px);
}
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.strike {
  text-decoration: line-through;
}

.nowrap {
  white-space: nowrap;
}
.important {
  color: red;
  font-weight: bold;
  text-transform: uppercase;
}

.title {
  @include fluid-type($bp-s, $bp-mw, 20px, 30px);
  font-weight: $fontWeight-semibold;
  margin: 0 0 5px;
}
.center-text {
  text-align: center;
}
.title-box {
  text-align: center;
  width: 100%;
  @include x-rem(max-width, 1088px);
  margin: 0 auto;
  @include x-rem(margin-bottom, 31px);
  position: relative;
  z-index: 2;

  &__content-box {
  }
  h2 {
    @include x-rem(margin-bottom, 32px);
  }
  p:last-child {
    margin: 0;
  }

  @include breakpoint($bp-large) {
    @include x-rem(margin-bottom, 64px);
  }
}
.italic-style1 {
  @include x-rem(font-size, 26px);
  @include x-rem(line-height, 36px);
  color: $color-black5;
  font-weight: $fontWeight-normal;
  @include breakpoint($bp-m) {
    @include x-rem(font-size, 32px);
    @include x-rem(line-height, 44px);
  }
}
.italic-style2 {
  @include x-rem(font-size, 24px);
  @include x-rem(line-height, 33px);
  color: $color-black5;
  font-weight: $fontWeight-normal;
  @include breakpoint($bp-m) {
    @include x-rem(font-size, 32px);
    @include x-rem(line-height, 44px);
  }
}
.italic-style3 {
  @include x-rem(font-size, 24px);
  @include x-rem(line-height, 36px);
  color: $color-black5;
  font-weight: $fontWeight-normal;
  @include breakpoint($bp-m) {
    @include x-rem(font-size, 32px);
    @include x-rem(line-height, 44px);
  }
}

.italic-style4 {
  @include x-rem(font-size, 24px);
  line-height: normal;
  color: $color-black5;
  font-weight: $fontWeight-normal;
  @include breakpoint($bp-m) {
    @include x-rem(font-size, 32px);
  }
}
.italic-style5 {
  @include x-rem(line-height, 44px);

  @include x-rem(font-size, 32px);
}
.italic-style6 {
  @include x-rem(font-size, 24px);
  line-height: normal;
  color: $color-black5;
  font-weight: $fontWeight-normal;
}
.italic-style7 {
  @include x-rem(font-size, 24px);
  @include x-rem(line-height, 36px);
}

.italic-style8 {
  @include x-rem(font-size, 24px);
  @include x-rem(line-height, 34px);
  color: $color-black5;
  @include breakpoint($bp-m) {
    @include x-rem(font-size, 32px);
    line-height: normal;
  }
}
.title-border {
  text-align: center;
  position: relative;
  @include x-rem(padding-bottom, 30px);
  &:before {
    content: "";
    width: 60%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 7px;
  }
  &--yellow {
    &:before {
      background: $category-yellow;
    }
  }
  &--red {
    &:before {
      background: $category-red;
    }
  }
  &--green {
    &:before {
      background: $category-green;
    }
  }
  &--blue {
    &:before {
      background: $category-blue;
    }
  }
}
