/* ================================================================
   Button Styles
   ================================================================ */

.button,
.btn {
  display: inline-block;
  border: 0;
  font-family: $font-primary;
  color: $color-invert;
  text-decoration: none;
  text-align: center;
  background: $color-primary;
  cursor: pointer;
  transition: $trans;
  @include x-rem(font-size, 17px);
  @include x-rem(line-height, 23px);
  font-weight: $fontWeight-semibold;
  @include x-rem(padding, 16px 32px);

  &:hover,
  &:focus {
    text-decoration: none;
    background: $color-secondary;
  }
}

.primary-button {
  background: $color-yellow;
  color: $color-black2;
  outline: 3px solid transparent;

  &:hover {
    background: $color-secondary;
    color: $color-black3;
  }
  &:focus {
    background: $color-secondary;
    color: $color-black3;
    outline: 3px solid $color-invert;
  }
  a {
    text-decoration: none;
  }
}
.button-grayed {
  background: $color-gray4;
  color:$color-gray7;

  &:hover, &:focus {
    background: transparent;
    color: $color-primary;
  }

}
.ghost-white {
  outline: 1px solid $color-invert;
  color: $color-invert;
  background: transparent;
  &:hover {
    color: $color-base;
    background: $color-yellow;
  }
  &:focus {
    color: $color-base;
    background: $color-yellow;
    outline: 3px solid $color-invert;
  }
  a {
    text-decoration: none;
  }
} 
.ghost-black {
}
.button-2 {
  display: inline-block;
  padding: 0.47em 1.2em;
  border: 0;
  font-family: $font-primary;
  color: $color-invert;
  text-decoration: none;
  text-align: center;
  background: $color-secondary;
  cursor: pointer;
  transition: $trans;
  line-height: 1.4;

  &:hover,
  &:focus {
    text-decoration: none;
    background: $color-invert;
    color: $color-primary;
  }
}

.back {
  @extend .button-small !optional;
  text-transform: capitalize;
}

.more {
  @extend .button-small !optional;
  text-transform: capitalize;
}

%carousel-btn {
  @include resetBtn;
  position: absolute;
  width: 60px;
  height: 60px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  font-size: 0;
  color: $color-primary;
  transition: $trans;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  svg {
    display: inline-block;
    width: 100%;
    height: 100%;
    fill: currentColor;
    transition: $trans;
  }

  &:hover,
  &:focus {
    color: $color-secondary;
  }

  @media (min-width: $bp-mw) {
    width: 60px;
    height: 60px;
  }
}
