.video-banner {
  position: relative;
  width: 100%;
  //   padding-bottom: 73.7%;
  @include x-rem(height, 280px);
  @include breakpoint($bp-mw) {
    // padding-bottom: 56.61%;
    @include x-rem(height, 617px);
  }
}
.video-banner__thumb {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: $trans;
  position: absolute;
  top: 0;
  left: 0;
}
.video-banner__cta,
.video-banner__btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  @include x-rem(gap, 8px);

  svg {
    @include x-rem(width, 32px);
    @include x-rem(height, 32px);
  }
  &:hover,
  &:focus {
    svg {
      transform: rotate(360deg);
    }
  }
}

// modal
.video-banner__modal {
  .modaal-inner-wrapper {
    @include x-rem(padding-left, 15px);
    @include x-rem(padding-right, 15px);
  }
  .modaal-video-wrap {
    margin: 0;
  }
}
// embedded code popup
.video-banner-pop {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .popup {
    width: 100%;
  }
}
