// start left card alignment

.left-card__item {
  display: flex;
  flex-direction: column;

  @include x-rem(gap, 20px);
  @include x-rem(margin-bottom, 45px);
  @include breakpoint($bp-x-small) {
    flex-direction: row;
    align-items: center;
    @include x-rem(margin-bottom, 64px);
  }
  @include breakpoint($bp-large) {
    @include x-rem(gap, 40px);
  }
}
.left-card__item.landscape-card--small {
  .left-card__icon {
    @include x-rem(height, 77px);
    width: auto;
  }
  .left-card__title {
    @include x-rem(font-size, 20px);
    @include x-rem(line-height, 27px);
    margin-bottom: 1px;
  }
  .left-card__content {
    font-size: 1rem;
    @include x-rem(line-height, 26px);
  }
}

.left-card__item.landscape-card--large {
  .left-card__icon {
    @include x-rem(height, 95px);
    @include x-rem(width, 95px);

    @include breakpoint($bp-large) {
      @include x-rem(height, 180px);
      @include x-rem(width, 180px);
    }
  }
  .left-card__title {
    @include x-rem(margin-bottom, 8px);
    @include x-rem(font-size, 28px);
    @include x-rem(line-height, 38px);
    @include x-rem(min-height, 95px);
    @include breakpoint(31.25em) {
      min-height: initial;
    }
    @include breakpoint($bp-large) {
      @include x-rem(font-size, 32px);
      @include x-rem(line-height, 44px);
    }
  }
  .left-card__content {
    @include x-rem(max-width, 864px);
    @include x-rem(font-size, 18px);
    @include x-rem(line-height, 32px);
    @include breakpoint($bp-large) {
      @include x-rem(font-size, 20px);
    }
  }
}
.left-card__iconbox {
  border-radius: 50%;
  background-color: $color-gray2;
}
.left-card__icon {
  flex-shrink: 0;
  object-fit: cover;
}
.left-card__content {
  margin: 0;
}

// unique styling for large, left aligned cards on the smallest breakpoint where the image is right next to the title then looks normal (as it should) around 500px
.left-card__item.landscape-card--large {
  position: relative;
  .left-card__icon {
    position: absolute;
    top: 0;
    left: 0;

    @include breakpoint(31.25em) {
      position: initial;
      top: initial;
      left: initial;
    }
  }
  .left-card__title {
    @include x-rem(margin-left, 115px);
    @include breakpoint(31.25em) {
      margin-left: 0;
    }
  }
  .left-card__content {
    @include x-rem(margin-top, 20px);
    @include breakpoint(31.25em) {
      margin-top: 0;
    }
  }
}
// start center card alignment
.centered-card {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include x-rem(gap, 24px);
  @include breakpoint($bp-m) {
    flex-wrap: nowrap;
    @include x-rem(gap, 32px);
  }
}
.centered-card__item {
  position: relative;
  width: calc(50% - 16px);
  &:last-child {
    width: 100%;
    @include x-rem(max-width, 300px);
  }
  @include breakpoint($bp-m) {
    width: 100%;
    @include x-rem(max-width, 416px);
    &:last-child {
      @include x-rem(max-width, 416px);
    }
  }
}
.centered-card__item--plus,
.centered-card__item--equals {
  &::before {
    position: absolute;
    top: 63px;
    font-weight: $fontWeight-bold;
    @include x-rem(font-size, 48px);
    @include x-rem(line-height, 64px);
    color: $color-primary;
  }
}
.centered-card__item--plus {
  &::before {
    content: "+";
    left: -30px;
  }
  @include breakpoint($bp-m) {
    &::before {
      left: -3px;
    }
  }
}
.centered-card__item--equals {
  &::before {
    content: "=";
    left: 16px;
  }

  @include breakpoint($bp-m) {
    &::before {
      left: -3px;
    }
  }
}
.centered-card__icon {
  @include x-rem(height, 130px);
  @include x-rem(width, 130px);
  @include x-rem(margin-bottom, 17px);
  @include breakpoint($bp-large) {
    @include x-rem(height, 191px);
    @include x-rem(width, 191px);
    @include x-rem(margin-bottom, 31px);
  }
}
.left-card__title {
  font-weight: $fontWeight-bold;
  @include x-rem(margin-bottom, 17px);
  @include x-rem(font-size, 22px);
  @include x-rem(line-height, 26px);
  @include breakpoint($bp-large) {
    @include x-rem(font-size, 32px);
    @include x-rem(line-height, 44px);
  }
}
.centered-card__content {
  margin: 0;
  font-size: 1rem;
  @include x-rem(line-height, 24px);
}
