// left aligned version

.modules-listing {
  @include resetList;
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 45px);
  @include breakpoint($bp-m) {
    @include x-rem(gap, 65px);
  }
  @include breakpoint($bp-large) {
    @include x-rem(gap, 45px);
  }
  @include breakpoint($bp-xx-large) {
    @include x-rem(gap, 30px);
  }
}

.modules-listing__item {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  @include x-rem(gap, 16px 15px);
  @include breakpoint($bp-x-small) {
    grid-template-columns: 100px 1fr;
    grid-template-rows: auto;
  }
  @include breakpoint($bp-small) {
    grid-template-columns: 156px 1fr;
    @include x-rem(gap, 0 41px);
  }
  @include breakpoint($bp-m) {
    grid-template-columns: 170px 1fr;
    @include x-rem(gap, 0 21px);
  }
  @include breakpoint($bp-large) {
    grid-template-columns: 192px 1fr;
  }
}
.modules-listing__image-box {
  @include breakpoint($bp-x-small) {
    grid-row: 1 / -1;
    grid-column: 1 / 2;
  }
  @include breakpoint($bp-small) {
    grid-row: 1 / 4;
  }
}

.modules-listing__title {
  margin: 0;
  @include breakpoint($bp-x-small) {
    grid-row: 1 / -1;
    grid-column: 2 / -1;
  }
  @include breakpoint($bp-small) {
    @include x-rem(margin-bottom, 10px);
  }
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 20px);
  }
  @include breakpoint($bp-large) {
    @include x-rem(margin-bottom, 16px);
  }
}
.modules-listing__text {
  margin: 0;
  @include breakpoint($bp-x-small) {
    grid-row: 3 / -1;
    grid-column: 1 / -1;
  }
  @include breakpoint($bp-small) {
    grid-column: 2 / -1;
  }
}

// center aligned version

.modules-listing--centered {
  flex-direction: row;
  flex-wrap: wrap;
  @include x-rem(gap, 24px);

  @include breakpoint($bp-small) {
    flex-wrap: nowrap;
    gap: 0;
  }
  .psuedoelement {
    &:after,
    &:before {
      display: block;
      position: absolute;
      @include x-rem(font-size, 48px);
      @include x-rem(line-height, 64px);
      font-weight: $fontWeight-bold;
      color: $color-primary;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .modules-listing__item {
    display: flex;
    flex-direction: column;
    @include x-rem(gap, 17px);
    text-align: center;
    flex: 0 0 46%;

    @include breakpoint($bp-small) {
      flex: 1;
      position: relative;
    }

    &:first-child {
      .modules-listing__image-box {
        &:after {
          @extend .psuedoelement;
          content: "+";
          right: -40px;

          @include breakpoint($bp-small) {
            right: 0;
          }
        }
      }
    }
    &:last-child {
      flex-basis: 100%;
      @include breakpoint($bp-small) {
        flex: 1;
      }
      .modules-listing__image-box {
        &:before {
          @extend .psuedoelement;
          content: "=";
          left: -40px;
          @include breakpoint($bp-small) {
            left: 0;
          }
        }
      }
    }
  }
  .modules-listing__image-box {
    @include x-rem(height, 129px);
    @include x-rem(width, 129px);
    position: relative;

    @include breakpoint($bp-small) {
      position: initial;
    }
  }
}
