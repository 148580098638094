.hero-newsletter-box {
}
.hero-newsletter-box__wrapper {
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 40px);

  @include breakpoint($bp-large) {
    flex-direction: row;
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(gap, 55px);
  }
}
.hero-newsletter-box__col1 {
  @include x-rem(min-height, 600px);
  width: 100%;
  box-shadow: 25px 25px 50px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: flex-end;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    width: 100%;
    display: block;
    background: $linear-gradient-dark;
  }
  @include breakpoint($bp-large) {
    width: 50%;
    @include x-rem(min-height, 636px);
  }
}
.hero-newsletter-box__blog {
}
.hero-newsletter-box__container {
}
.hero-newsletter-box__imgbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  img {
    @include fitImage;
  }
}
.hero-newsletter-box__content {
  position: relative;
  z-index: 3;
  @include x-rem(padding, 35px 20px 35px 30px);
  @include breakpoint($bp-large) {
    @include x-rem(padding, 35px 55px 33px 30px);
  }
}
.hero-newsletter-box__link {
}
.hero-newsletter-box__title {
  @include x-rem(font-size, 34px);
  @include x-rem(line-height, 38px);
  font-weight: $fontWeight-bold;
  @include x-rem(margin-bottom, 15px);
  @include breakpoint($bp-large) {
    @include x-rem(font-size, 42px);
    @include x-rem(line-height, 46px);
  }
}
.hero-newsletter-box__desc {
  // @extend .whiten-text;
  @include x-rem(margin-bottom, 22px);

  p:last-child {
    margin: 0;
  }
  @include x-rem(font-size, 18px);
  @include x-rem(line-height, 26px);

  @include breakpoint($bp-large) {
    @include x-rem(font-size, 20px);
    @include x-rem(line-height, 30px);
    @include x-rem(margin-bottom, 30px);
  }
}
.hero-newsletter-box__readmore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include x-rem(gap, 35px);
  .primary-button {
    flex-shrink: 0;
  }
}
.hero-newsletter-box__info {
  @include x-rem(font-size, 14px);
  @include x-rem(line-height, 24px);
}
.hero-newsletter-box__col2 {
  position: relative;
  z-index: 3;
  @include breakpoint($bp-large) {
    width: 50%;
  }
}
.hero-newsletter-box__formbox {
  border-radius: 10px;
  border: 1px solid $color-gray4;
  background: $body-bg;
  box-shadow: $box-shadow1;
  @include x-rem(min-height, 636px);
  height: 100%;
  display: flex;
  flex-direction: column;

  &,
  p {
    color: $color-black5;
  }
  a {
    color: $color-category-blue;
  }

  @include x-rem(padding, 31px 22px 27px 22px);
  @include breakpoint($bp-large) {
    @include x-rem(padding, 36px 33px 27px 32px);
  }
}
.hero-newsletter-box__formbox form {
  label,
  a {
    text-underline-offset: initial;
    @include x-rem(font-size, 17px);
    line-height: normal;
  }
  a {
    text-decoration: underline;
    @extend .heavy-link;
    text-underline-offset: initial;
    font-weight: $fontWeight-bold !important;
    color: $color-black5 !important;
    &:hover,
    &:focus {
      color: $color-category-blue !important;
    }
  }
  .ktc-checkbox {
    display: flex;
    align-items: flex-start;
    gap: 5px;
  }
  input[type="checkbox"] {
    height: 23px !important;
    width: 23px !important;
    margin: 0 !important;
    position: relative;
    top: 5px;
  }
}

h2.hero-newsletter-box__formbox-title {
  color: $color-primary;
  text-align: center;
  font-weight: $fontWeight-bold;
  @include x-rem(font-size, 26px);
  @include x-rem(line-height, 42px);
  @include x-rem(margin-bottom, 5px);
  @include breakpoint($bp-large) {
    @include x-rem(font-size, 32px);
    @include x-rem(margin-bottom, 15px);
  }
}
.hero-newsletter-box__formimg {
  display: block;
  margin: 0 auto;
  @include x-rem(margin-bottom, 24px);
  @include breakpoint($bp-large) {
    @include x-rem(margin-bottom, 30px);
  }
}
// thank you message
.hero-newsletter-box__formbox {
  .formwidget-submit-text {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
  }
  .tym {
    @include x-rem(max-width, 413px);
    h2 {
      color: $color-primary;
    }
    @include breakpoint($bp-large) {
      @include x-rem(margin-top, -100px);
    }
  }
}
