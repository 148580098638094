.featured-articles {
  @include resetList;
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 45px);

  @include breakpoint($bp-small) {
    @include x-rem(gap, 60px);
  }
  @include breakpoint($bp-mw) {
    flex-direction: row;
    @include x-rem(gap, 24px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(gap, 29px);
  }
  @include breakpoint($bp-xx-large) {
    @include x-rem(gap, 32px);
  }
}
.featured-articles__item {
  flex: 1;
  display: flex;
  flex-direction: column;

  @include x-rem(margin-bottom, 45px);
  @include x-rem(gap, 25px);
  @include breakpoint($bp-m) {
    flex-direction: row;
  }
  @include breakpoint($bp-mw) {
    flex-direction: column;
  }
}
.featured-articles__imagebox {
  width: 100%;
  @include x-rem(height, 234px);

  @include breakpoint($bp-m) {
    width: 50%;
  }
  @include breakpoint($bp-mw) {
    width: 100%;
  }
}

.featured-articles__imagebox img {
  @extend .fit-image;
}

.featured-articles__content-box {
  @include breakpoint($bp-m) {
    width: 50%;
  }
  @include breakpoint($bp-mw) {
    width: 100%;
  }
}
.featured-articles__title {
  @include x-rem(margin-bottom, 16px);
  font-weight: $fontWeight-bold;  
  @include x-rem(font-size, 20px);
  @include x-rem(line-height, 27px);

}

.featured-articles__text {
  @extend .largest;
  margin: 0;
  p:last-child {
    margin: 0;
  }
}
