.profiles-listing-slider {
  @include x-rem(margin-left, 20px);
  @include breakpoint($bp-x-large) {
    @include x-rem(margin-left, 64px);
  }
}

.profiles-listing__item {
  background: $body-bg;
  border: 1px solid $color-gray2;
  box-shadow: 25px 25px 50px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  @include x-rem(padding, 13.35px);
  position: relative;
  width: 100%;
  @include x-rem(max-width, 255px);
  @include x-rem(margin-right, 26px);

  @include breakpoint($bp-x-large) {
    @include x-rem(max-width, 304px);
  }
}

.profiles-listing__tag {
  position: absolute;
  top: 13.5px;
  left: 13.5px;
  @include x-rem(width, 37px);
  @include x-rem(height, 45px);
}
.profiles-listing__imgbox {
  position: relative;
  width: 100%;
  @include x-rem(height, 256px);
  margin: 0 auto;
  @include x-rem(margin-bottom, 13.5px);
}
.profiles-listing__img {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  display: block;
}
.profiles-listing__halo {
  @include x-rem(height, 200px);
  @include x-rem(width, 200px);
}
.profiles-listing__content {
  text-align: center;
}
.profiles-listing__name {
  @include x-rem(font-size, 20px);
  @include x-rem(line-height, 27px);
  @include x-rem(margin-bottom, 6px);
  color: $color-black5;
}
.profiles-listing__job-title {
  @include x-rem(font-size, 12px);
  @include x-rem(line-height, 16px);
  @include x-rem(margin-bottom, 10.7px);
}
.profiles-listing__category {
  border: 2px solid $color-category-blue;
  border-radius: 5px;
  @include x-rem(padding, 3px);
  @include x-rem(margin-bottom, 7px);

  p {
    margin: 0;
    font-weight: $fontWeight-bold;
    @include x-rem(font-size, 14px);
    @include x-rem(line-height, 19px);
  }
}
.profiles-listing__text {
  background: $color-gray2;
  border-radius: 5px;
  @include x-rem(padding, 13.5px 13.5px 2px 13.5px);
  @include x-rem(font-size, 14px);
  @include x-rem(line-height, 24px);

  @include breakpoint($bp-m) {
    padding: 1rem;
  }
}
.skills-listing1 {
  @include resetList;
  background: $color-gray2;
  border-radius: 5px;
  @include x-rem(padding, 13.5px 13.5px 2px 13.5px);
  @include x-rem(font-size, 14px);
  @include x-rem(line-height, 24px);
}
// slick styles

.profiles-listing-slider {
  .profiles-listing-slider__dots {
    position: relative;
    top: -30px;
    z-index: 3;
  }
  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
  }
  .slick-list {
    padding: 0 20% 0 0 !important;
    padding: 70px 0px !important;
  }
  .slick-current {
    box-shadow: 50px 25px 50px rgba(0, 0, 0, 0.15);
  }
  .slick-last {
    box-shadow: none;
  }
}
