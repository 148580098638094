.hero-form {
  background-image: url("../images/shapes/blue-curve-bottom.png");
  @include bgImg;
  @include x-rem(padding, 120px 0 78px 0);
  @include breakpoint($bp-m) {
    @include x-rem(padding, 149px 0 136px 0);
  }
}

.hero-form__wrapper {
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 38px);

  @include breakpoint($bp-large) {
    flex-direction: row;
    @include x-rem(gap, 54px);
  }
}
.hero-form__box1 {
  @include bgImg;
  @include x-rem(min-height, 636px);
  border-radius: 10px;
  @include x-rem(padding, 163px 19px 35px 30px);
  flex: 1;
  @include breakpoint($bp-large) {
    @include x-rem(padding, 275px 29px 33px 29px);
  }
}
.hero-form__textbox {
}

.hero-form__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include x-rem(gap, 35px);
}

.hero-form__title {
  color: $color-invert;
  @include x-rem(font-size, 34px);
  @include x-rem(line-height, 44px);
  @include x-rem(margin-bottom, 15px);
  font-weight: $fontWeight-bold;
  @include breakpoint($bp-large) {
    @include x-rem(font-size, 42px);
    @include x-rem(line-height, 46px);
  }
}

.hero-form__description {
  & > * {
    font-weight: $fontWeight-semibold;
    color: $color-invert;
  }
  @include x-rem(margin-bottom, 22px);

  @include breakpoint($bp-large) {
    @include x-rem(margin-bottom, 30px);
  }
}
.hero-form__bottom .btn {
  flex-shrink: 0;
}
.hero-form__date {
  color: $color-invert;
  margin: 0;
  @include x-rem(font-size, 14px);
  @include x-rem(line-height, 24px);

  @include breakpoint($bp-large) {
    font-size: 1rem;
  }
}
// temp styles
.form-hero {
  border: 1px solid red;
  width: 100%;

  height: 768px;

  @include breakpoint($bp-large) {
    height: initial;
    flex: 1;
  }
}
