.expert-box-container {
  background-color: $color-gray2;
}
.expert-box {
  position: relative;
  @include breakpoint($bp-m) {
    position: relative;

    &:before {
      content: "";
      background-image: url("../images/shapes/bottomcurve.png");
      @include bgImg;
      background-size: cover;
      background-position: bottom;
      position: absolute;
      z-index: 3;
      width: 100%;
      bottom: -1px;
      left: 0;
      height: 100%;
    }
  }
}
.expert-box__textbox {
  position: relative;
  z-index: 3;
  @include x-rem(padding, 64px 0 81px 0);
  @include breakpoint($bp-m) {
    @include x-rem(max-width, 640px);
    @include x-rem(padding, 98px 0 45px 0);
  }
}
.expert-box__title {
  text-align: center;
  @include x-rem(margin-bottom, 32px);
  @include breakpoint($bp-small) {
    text-align: left;
  }
}
.expert-box__text {
  @include x-rem(margin-bottom, 37px);
  p:last-child {
    margin: 0;
  }

  @include breakpoint($bp-small) {
    @include x-rem(margin-bottom, 53px);
  }
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 32px);
  }
}
.expert-box__cta {
  width: 100%;
  @include x-rem(max-width, 241px);
  display: block;
  margin: 0 auto;
  @include breakpoint($bp-small) {
    max-width: fit-content;
  }
  @include breakpoint($bp-m) {
    display: inline-block;
  }
}
.expert-box__imgbox {
  display: none;
  position: relative;
  z-index: 4;
  @include breakpoint($bp-m) {
    display: block;
    @include x-rem(margin-top, -190px);
    // padding-bottom: 62%;
    bottom: -55px;
  }
  @include breakpoint($bp-large) {
    @include x-rem(margin-top, -380px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(margin-top, -460px);
  }
  @include breakpoint(87.5em) {
    @include x-rem(margin-top, -515px);
  }
  @include breakpoint(111.875em) {
    @include x-rem(margin-top, -561px);
  }
}
.expert-box__imgbox img {
  @include fitImage;
  // position: absolute;
  // top: 0;
  // left: 0;
  display: block;
  max-width: 100%;
  height: auto;
}
