.not-found-page {
  border-top: 1px solid $color-gray2;
  @include x-rem(padding, 185px 0 290px 0);

  @include breakpoint($bp-m) {
    @include x-rem(padding, 287px 0 304px 0);
  }

  @include breakpoint($bp-large) {
    @include x-rem(padding, 213px 0 281px 0);
  }
}
