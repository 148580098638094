.global-impact {
  @include resetList;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include x-rem(gap, 70px);
}
.global-impact__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  @include breakpoint($bp-m) {
    width: calc(33.3333% - 70px);
  }
  @include breakpoint($bp-large) {
  }
  @include breakpoint($bp-x-large) {
    width: calc(20% - 70px);
  }
}
.global-impact__icon {
  flex-shrink: 0;
  @include x-rem(margin-bottom, 38px);
  @include breakpoint($bp-x-large) {
    margin-bottom: 0;
    @include x-rem(width, 110px);
  }
}
.global-impact__content {
  text-align: center;
  height: 100%;
  margin-top: auto;
}
.global-impact__title {
  margin: 0;
}
.globabl-impact__text {
  margin: 0;
}
