.standard-hero {
  @include x-rem(min-height, 400px);
  background: $linear-gradient-navy;
  @extend .bottom-curve;
  @include x-rem(padding, 114px 0 92px 0);
  @include breakpoint($bp-m) {
    @include x-rem(padding, 184px 0 100px 0);
    @include x-rem(min-height, 502px);
  }
}
.standard-hero__content {
  text-align: center;
  width: 100%;
  @include x-rem(max-width, 640px);
  margin: 0 auto;
}
.standard-hero__title {
  color: $color-invert;
  @include x-rem(margin-bottom, 10px);
  @include x-rem(font-size, 44px);
  @include x-rem(line-height, 72px);
  @include breakpoint($bp-m) {
    @include x-rem(font-size, 72px);
    @include x-rem(line-height, 72px);
  }
}
.standard-hero__text {
  text-align: center;
  @include x-rem(margin-top, 20px);
  p:last-child {
    margin: 0;
  }
  p,
  ol,
  ul {
    color: $color-invert;
  }
  a:not(.btn) {
    color: $color-invert;
  }
}
