// =========
$circle-bp: 37.5em; //600px
.tab-twelve-box .container {
  padding: 0;
  padding-bottom: 150px;
  @include breakpoint($circle-bp) {
    padding-bottom: 0;
  }
}
.inna {
  border: 50%;
  @include x-rem(height, 576px);
  @include x-rem(width, 576px);
  background: $body-bg;
  position: relative;
  z-index: 6;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.circle-splitter {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include x-rem(gap, 40px);
  overflow: hidden;

  @include breakpoint($circle-bp) {
    overflow: initial;
  }
  @include breakpoint($bp-large) {
    flex-direction: row;
  }
}

.circle-wrapper {
  position: relative;
  width: fit-content;
  margin: 48px 0 0 0;
}
.multi-quadrant-circle__center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @include x-rem(width, 35px);
  @include x-rem(height, 35px);
  border: 1px solid $color-gray8;
  background: $body-bg;
  border-radius: 50%;
  z-index: 51;
}

.multi-quadrant-circle__plus {
  position: absolute;
  z-index: 7;
  &--green {
    background: $category-green3;
    width: 50%;
    height: 7px;
    left: -7px;
    top: 48%;
    transform: translateY(50%);

    @include breakpoint($circle-bp) {
      height: 12px;
      left: -11px;
    }
  }
  &--red {
    background: $category-red3;
    height: 50%;
    width: 7px;
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
    @include breakpoint($circle-bp) {
      width: 12px;
      top: -11px;
    }
  }
  &--blue {
    background: $category-blue3;
    right: -7px;
    top: 48%;
    transform: translateY(50%);
    width: 50%;
    height: 7px;
    @include breakpoint($circle-bp) {
      height: 12px;
      right: -13px;
    }
  }
  &--yellow {
    background: $category-yellow3;
    bottom: -7px;
    height: 50%;
    left: 50%;
    transform: translateX(-50%);
    width: 7px;
    z-index: 50;

    @include breakpoint($circle-bp) {
      width: 12px;
      bottom: -13px;
    }
  }
}
.outer-circle {
  position: absolute;
  top: -7px;
  left: -7px;
  z-index: 2;
  border-radius: 50%;
  width: 335px;
  height: 335px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
  @include breakpoint($circle-bp) {
    width: 575px;
    height: 575px;
    top: -11px;
    left: -11px;
  }
}
.multi-quadrant-circle__quadrant {
  position: relative;
  z-index: 8;
  overflow: hidden;
  &--d {
    background: $category-green3;
  }
  &--i {
    background: $category-red3;
  }
  &--s {
    background: $category-blue3;
  }
  &--c {
    background: $category-yellow3;
  }
}
.circle1 {
  @include resetList;
  z-index: 3;
  position: relative;
  padding: 0;
  width: 321px;
  height: 321px;
  border-radius: 50%;
  list-style: none;
  overflow: hidden;

  @include breakpoint($circle-bp) {
    width: 551px;
    height: 551px;
  }
}

.circle1 li {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  transform-origin: 0% 100%;
  &.toBottom {
    top: initial;
    bottom: 0;
  }
}
.circle1 li.push-down {
  top: 276px;
}
.circle1__button {
  @include resetBtn;
  position: absolute;
  left: -100%;
  width: 200%;
  height: 200%;
  text-align: center;
  transform: skewY(60deg) rotate(15deg);
  padding-top: 20px;
  background: $body-bg;

  &.open,
  &:hover,
  &:focus {
    outline: none;
    &.circle1__button--d {
      background: $category-green-hover;
    }
    &.circle1__button--i {
      background: $category-red-hover;
    }
    &.circle1__button--s {
      background: $category-blue-hover;
    }
    &.circle1__button--c {
      background: $category-yellow-hover;
    }
  }
}

.circle1 li:first-child {
  transform: rotate(0deg) skewY(-60deg);
}

.circle1 li:nth-child(2) {
  transform: rotate(30deg) skewY(-60deg);
}

.circle1 li:nth-child(3) {
  transform: rotate(60deg) skewY(-60deg);
}

.circle1 li:nth-child(4) {
  transform: rotate(90deg) skewY(-60deg);
}

.circle1 li:nth-child(5) {
  transform: rotate(120deg) skewY(-60deg);
}

.circle1 li:nth-child(6) {
  transform: rotate(150deg) skewY(-60deg);
}

.circle1 li:nth-child(7) {
  transform: rotate(180deg) skewY(-60deg);
}

.circle1 li:nth-child(8) {
  transform: rotate(210deg) skewY(-60deg);
}

.circle1 li:nth-child(9) {
  transform: rotate(240deg) skewY(-60deg);
}

.circle1 li:nth-child(10) {
  transform: rotate(270deg) skewY(-60deg);
}

.circle1 li:nth-child(11) {
  transform: rotate(300deg) skewY(-60deg);
}

.circle1 li:nth-child(12) {
  transform: rotate(330deg) skewY(-60deg);
}
// targets

.multi-quadrant-circle-target__item {
  border-radius: 10px;
  border: 2px solid transparent;
  background: $body-bg;
  box-shadow: $box-shadow1;
  @include x-rem(padding, 17px 13px 20px 24px);
  position: absolute;
  width: 100%;
  left: 0;

  &.active {
    display: none !important;
  }
  &.open {
    display: block !important;
  }
  @include breakpoint($circle-bp) {
    left: initial;
    position: initial;
  }

  p {
    margin: 0;
    @include x-rem(font-size, 20px);
    @include x-rem(line-height, 24px);
  }
  &--d {
    border-color: $category-green;
  }
  &--i {
    border-color: $category-red;
  }
  &--s {
    border-color: $category-blue;
  }
  &--c {
    border-color: $category-yellow;
  }

  @include breakpoint($bp-m) {
    @include x-rem(padding, 30px 32px);
  }
  &.open {
    display: block;
  }
}
.multi-quadrant-circle__line {
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  &.multi-quadrant-circle__line--1 {
    height: 87%;
  }
  &.multi-quadrant-circle__line--2 {
    height: 86.7%;
  }
  &.multi-quadrant-circle__line--3 {
    height: 50.5%;
  }
  &.multi-quadrant-circle__line--4 {
    height: 49.98%;
  }

  @include breakpoint($circle-bp) {
    &.multi-quadrant-circle__line--1 {
      height: 87%;
    }
    &.multi-quadrant-circle__line--2 {
      height: 86.7%;
    }
    &.multi-quadrant-circle__line--3 {
      height: 50.5%;
    }
    &.multi-quadrant-circle__line--4 {
      height: 49.98%;
    }
  }
}
.circle-letter-box {
  pointer-events: none;
  position: absolute;
  z-index: 100;
  &--mobile {
    display: flex;
    &.circle-letter-box--di {
      left: 117px;
      top: 17px;
    }

    &.circle-letter-box--d {
      left: 57px;
      top: 51px;
    }
    &.circle-letter-box--dc {
      left: 18px;
      top: 114px;
    }
    &.circle-letter-box--cd {
      left: 18px;
      top: 185px;
    }

    &.circle-letter-box--c {
      left: 53px;
      top: 250px;
    }
    &.circle-letter-box--cs {
      left: 112px;
      top: 280px;
    }

    &.circle-letter-box--sc {
      right: 112px;
      top: 280px;
    }
    &.circle-letter-box--s {
      right: 53px;
      top: 250px;
    }

    &.circle-letter-box--si {
      right: 18px;
      top: 185px;
    }
    &.circle-letter-box--is {
      right: 18px;
      top: 114px;
    }
    &.circle-letter-box--i {
      right: 57px;
      top: 51px;
    }
    &.circle-letter-box--id {
      right: 117px;
      top: 17px;
    }
  }

  &--desktop {
    display: none;

    &.circle-letter-box--di {
      left: 201px;
      top: 30px;
    }

    &.circle-letter-box--d {
      left: 96px;
      top: 91px;
    }
    &.circle-letter-box--dc {
      left: 28px;
      top: 200px;
    }
    &.circle-letter-box--cd {
      left: 28px;
      bottom: 194px;
    }

    &.circle-letter-box--c {
      left: 90px;
      bottom: 90px;
    }
    &.circle-letter-box--cs {
      left: 190px;
      bottom: 25px;
    }

    &.circle-letter-box--sc {
      right: 190px;
      bottom: 25px;
    }
    &.circle-letter-box--s {
      right: 93px;
      bottom: 90px;
    }

    &.circle-letter-box--si {
      right: 30px;
      bottom: 195px;
    }
    &.circle-letter-box--is {
      right: 30px;
      top: 200px;
    }
    &.circle-letter-box--i {
      right: 100px;
      top: 88px;
    }
    &.circle-letter-box--id {
      right: 200px;
      top: 30px;
    }
  }

  @include breakpoint($circle-bp) {
    &--desktop {
      display: block;
    }
    &--mobile {
      display: none;
    }
  }
}

// with SVGs
.circle-fodder {
  pointer-events: none;
}

.quadrant-circle-container {
  width: 300px;
  height: 300px;
  @include breakpoint($bp-small) {
    width: 500px;
    height: 500px;
  }
  @include breakpoint($circle-bp) {
    width: 600px;
    height: 600px;
  }
}

.quad-circle1 {
  transition: $trans;
  cursor: pointer;
  &:focus,
  &:hover,
  &.open {
    outline: none;
    border: none;

    &.quad-circle1--g {
      fill: $category-green-hover;
    }
    &.quad-circle1--b {
      fill: $category-blue-hover;
    }
    &.quad-circle1--y {
      fill: $category-yellow-hover;
    }
    &.quad-circle1--r {
      fill: $category-red-hover;
    }
  }
}
