.icon-direction-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include breakpoint($bp-small) {
    display: none;
  }
}
.icon-direction-grid__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include x-rem(gap, 17px);
  @include x-rem(margin, 17px 0);
  p {
    margin: 0;
    width: 95px;
    text-align: center;
    line-height: normal;
  }
}
.harness-steps-img {
  display: none;
  @include breakpoint(37.5em) {
    display: block;
  }
}
