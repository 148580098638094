.custom-blog-box {
  position: relative;
  @include x-rem(padding-top, 20px);
}
.custom-blog-box__wrapper {
  @include breakpoint(62.5em) {
    display: flex;
    align-items: flex-start;
    gap: 30px;
  }
}
.custom-blog-box__col1 {
  flex: 0 0 21%;
  flex: 0 0 18%;
}
.section-blog {
  @include x-rem(padding-bottom, 29px);
  @include x-rem(margin-bottom, 36px);
  border-bottom: 1px solid $color-gray4;
  scroll-margin-top: 200px;
  @include breakpoint(62.5em) {
    scroll-margin-top: 80px;
    @include x-rem(padding-bottom, 46px);
    @include x-rem(margin-bottom, 65px);
  }
  &:last-child {
    padding: 0;
    margin: 0;
    border: none;
  }
}
.blog-content-svg {
  @include x-rem(height, 120px);
  @include x-rem(width, 120px);
}
.blog-content-svg--small {
  @include x-rem(height, 112px);
  @include x-rem(width, 112px);
}

.blog-2col {
  @include resetList;
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 20px);
}
.blog-2col__wrapper {
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 40px);

  @include breakpoint(37.5rem) {
    flex-direction: row;
  }
}
.blog-2col__box1 {
  flex-shrink: 0;
  img {
    width: 171px;
  }
  &.blank{
    width: 171px;
  }
}
