.recent-blogs {
  @include resetList;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @include x-rem(gap, 50px);
  @include x-rem(margin-bottom, 56px);

  @include breakpoint(45.625em) {
    @include x-rem(margin-bottom, 80px);
    grid-template-columns: repeat(2, 1fr);
    @include x-rem(gap, 64px 32px);
  }
  @include breakpoint($bp-x-large) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.recent-blogs__item {
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 20px);
}
.recent-blogs__imgbox {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 56%;
  margin: 0 auto;
  border-radius: 10px;
  @include x-rem(min-height, 143px);

  @include breakpoint($bp-small) {
    @include x-rem(min-height, 164px);
  }
  @include breakpoint($bp-large) {
    @include x-rem(min-height, 273px);
  }
  img {
    @extend .fit-image;
    position: absolute;
    top: 0;
    left: 0;
  }
  &:hover,
  &:focus {
    img {
      transform: scale(1.1);
    }
  }
}
.recent-blogs__content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.recent-blogs__title {
  @include x-rem(margin-bottom, 11px);
  @include x-rem(font-size, 20px);
  @include x-rem(line-height, 27px);
  font-weight: $fontWeight-bold;
  @include breakpoint($bp-small) {
    @include x-rem(font-size, 24px);
    @include x-rem(line-height, 33px);
    @include x-rem(margin-bottom, 14px);
  }
}
.recent-blogs__link {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
.recent-blogs__info {
  margin: 0;
  margin-top: auto;
  font-size: 1rem;
  @include x-rem(line-height, 24px);
}

.blogs-box-container__load,
.recent-blogs__loadmore {
  display: block;
  width: fit-content;
  margin: 0 auto;
}
