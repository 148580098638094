.search-results {
  @include resetList;
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 47px);
}
.search-results__item {
  background: $body-bg;
  border: 1px solid $color-gray2;
  box-shadow: $box-shadow1;
  border-radius: 10px;
  @include x-rem(padding, 28px 20px 30px 20px);
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 26px);

  @include breakpoint(62.5em) {
    flex-direction: row;
    @include x-rem(gap, 47px);
  }

  @include breakpoint($bp-x-large) {
    @include x-rem(padding, 45px 40px 50px 40px);
  }
}
.search-results__top {
  @include x-rem(padding-bottom, 33px);
  border-bottom: 1px solid $color-gray4;
  display: flex;
  flex-direction: column;

  @include breakpoint($bp-m) {
    flex-direction: row;
    justify-content: space-between;
    @include x-rem(gap, 15px);
  }
  @include breakpoint(62.5em) {
    padding-bottom: 0;
    @include x-rem(padding-right, 39px);
    border-bottom: none;
    border-right: 1px solid $color-gray4;
    flex-direction: column;
    justify-content: initial;
    width: 37%;
  }
}
.search-results__logo {
    align-self: center;
    width: 200px;
    max-width: 100%;
    height: auto;
    image-rendering:pixelated;
    margin-bottom: 1rem;
    @include breakpoint($bp-m) {
        width: 300px;
        height: auto;
        image-rendering: auto;
    }
}

.search-results__top .search-results__content {
  @include breakpoint($bp-m) {
    width: 100%;
    @include x-rem(max-width, 287px);
  }
}
.search-results__content {
  margin-top: auto;
  @include breakpoint($bp-m) {
    margin: initial;
  }
}
.search-results__locations {
  @include x-rem(font-size, 18px);
  @include x-rem(line-height, 28px);
  @include x-rem(margin-bottom, 17px);
}
.search-results__cta {
  @include x-rem(font-size, 18px);
  @include x-rem(line-height, 28px);
  font-weight: $fontWeight-bold;
  text-decoration: underline;
  color: $color-black5;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.search-results__bottom {
  display: flex;
  position: relative;
  @include x-rem(gap, 40px);
  @include breakpoint(62.5em) {
    width: 63%;
  }
}
.search-results__bottom-left {
  width: 75%;
  max-width:100%;
  @include breakpoint(34.375em) {
    width: initial;
  }
  @include breakpoint(62.5em) {
    width: 70%;
  }
}
.search-results__title {
  @include x-rem(min-height, 66px);
  @include x-rem(margin-bottom, 15px);
  @include x-rem(font-size, 24px);
  @include x-rem(line-height, 33px);
  font-weight: $fontWeight-bold;
  @include breakpoint(34.375em) {
    min-height: initial;
  }
  @include breakpoint(62.5em) {
    @include x-rem(margin-bottom, 22px);
    @include x-rem(font-size, 30px);
    @include x-rem(line-height, 41px);
  }
}
.search-results__text {
  @include x-rem(margin-bottom, 20px);
  @include x-rem(font-size, 20px);
  @include x-rem(line-height, 30px);

  @include breakpoint(62.5em) {
    @include x-rem(font-size, 22px);
  }
}
.search-results__website,
.search-results__email a {
  @include x-rem(font-size, 18px);
  @include x-rem(line-height, 28px);
  font-weight: $fontWeight-bold;
  text-decoration: underline;
  color: $color-black5;
  overflow-wrap:anywhere;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.search-results__email {
  margin: 0;
  @include x-rem(font-size, 18px);
  @include x-rem(line-height, 28px);
}
.search-results__telephone,
.search-results__telephone a {
  @include x-rem(font-size, 18px);
  @include x-rem(line-height, 28px);
  color: $color-base;
  a {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
.search-results__award-box {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;

  img {
    width:70px;
    height:auto;
  }
  @include breakpoint($bp-m) {
    position: relative;
    right: initial;
    top: initial;
    img {
    width:90px !important;
    height:auto;
    }
  }
  @include breakpoint(37.5em) {
    margin-left: auto;
    @include x-rem(gap, 10px);
  }
}
.search-results__award {
  flex-shrink: 0;
}
.search-results__award-text {
  color: $color-black6;
  @include x-rem(font-size, 8px);
  line-height: 1.5;
  font-weight: $fontWeight-bold;
  width: 100%;
  text-align: center;
  @include x-rem(max-width, 88px);
  @include breakpoint(62.5em) {
    @include x-rem(font-size, 10px);
  }
}
