.site-header {
  background: $body-bg;
  background: transparent;
  position: relative;
  @include breakpoint(81.25em) {
    padding: 20px 0;
  }
}

.header-container {
  display: flex;
  @include breakpoint($bp-small) {
    @include x-rem(gap, 28px);
    justify-content: space-between;
  }
}
.header-container__right {
  display: flex;
  align-items: center;
  @include x-rem(gap, 32px);
  flex: 1;
  justify-content: space-between;
  @include breakpoint(43.75em) {
    justify-content: initial;
  }
  @include breakpoint(81.25em) {
    @include x-rem(gap, 47px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(gap, 32px);
  }
}
.header-logo {
  position: relative;
  z-index: 3;

  a {
    display: block;
    @include x-rem(height, 51px);
    @include x-rem(width, 175px);
  }
}
.header-logo__svg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: $trans;
  &--dark {
    opacity: 0;
  }
}
.active-sticky, .not-transparent-active-sticky {
  .header-logo__svg--dark {
    opacity: 1;
  }
  .header-logo__svg--light {
    opacity: 0;
  }
}
.header-bottom {
  flex: 1;
}
.header-utility {
  display: none;
  align-items: center;
  position: relative;
  z-index: 3;
  @include breakpoint(43.75em) {
    display: flex;
  }
}
.culture-selector {
  position: relative;
  @include x-rem(margin-right, 20px);
  color: $color-invert;
  display: none;

  @include breakpoint(81.25em) {
    display: flex;
  }
}

.culture-select-btn {
  @include resetBtn;
  font-family: $font-primary;
  @include x-rem(font-size, 24px);
  font-weight: $fontWeight-semibold;
  line-height: normal;
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  @include x-rem(gap, 10px);
  position: relative;
  color: $color-base;
  @include breakpoint(81.25em) {
    @include x-rem(font-size, 14px);
    color: $color-invert;
    font-weight: normal;
    &:after {
      opacity: 0;
      transition: $trans;
    }
    &.active::after {
      content: "";
      display: block;
      @include x-rem(height, 24px);
      @include x-rem(width, 24px);
      position: absolute;
      left: 18px;
      bottom: -41px;
      z-index: 1;
      background-color: $body-bg;
      transform: rotate(45deg);
      opacity: 1;
    }
  }

  &:hover,
  &:focus {
    .culture-select__arrow {
      transform: rotate(360deg);
    }
  }
}
.culture-select__text {
  @include x-rem(font-size, 24px);
  @include breakpoint(81.25em) {
    @include x-rem(font-size, 14px);
  }
}
.culture-select__globe {
  @include x-rem(height, 25px);
  @include x-rem(width, 25px);
  fill: $body-bg;
}
.culture-selector__list {
  @include resetList;
  border-radius: 4px;
  box-shadow: $box-shadow2;
  position: absolute;
  top: 215%;
  left: 0;
  white-space: nowrap;
  width: fit-content;
  background: $body-bg;
  z-index: 1001;
  display: none;
  @include breakpoint(81.25em) {
    @include x-rem(padding, 17px 0 20px 0);
    @include x-rem(min-width, 210px);
  }
}
.culture-selector__item {
}
.culture-selector__link {
  text-decoration: none;
  display: block;
  @include x-rem(font-size, 20px);
  font-weight: $fontWeight-semibold;
  color: $color-black4;
  width: 100%;
  @include x-rem(padding, 7px 15px);
  &:hover,
  &:focus {
    background: $color-gray;
    color: $color-primary;
  }
  @include breakpoint(81.25em) {
    font-size: 1rem;
  }
}
.culture-select__arrow {
  @include x-rem(height, 10px);
  @include x-rem(width, 17px);
  fill: $body-bg;
  border-radius: 4px;
}

.header-bottom {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 100;
  @include breakpoint(81.25em) {
    position: relative;
    display: block;
    top: auto;
    width: auto;
  }
}

.header-utility .primary-button {
  @include x-rem(margin-right, 24px);
}

.header-bottom {
  background: $body-bg;
  height: calc(100vh - 85px);
  overflow: scroll;
  @include breakpoint(81.25em) {
    background: transparent;
    overflow: initial;
    height: initial;
  }
}

.header-bottom__mobile-cta {
  @include x-rem(padding, 21px 26px);
  border-bottom: 1px solid $color-gray3;

  @include breakpoint(43.75em) {
    display: none;
  }
  .primary-button {
    @include x-rem(margin-right, 21px);
  }
  .ghost-white {
    color: $color-base;
    outline: 1px solid $color-base;
  }
}

.culture-selector-mobile {
  .culture-selector {
    display: block;
    border-bottom: 1px solid $color-gray3;
    @include x-rem(padding, 0 26px 25px 0);
    @include x-rem(margin-left, 20px);
    @include x-rem(margin-right, 20px);
    &.active {
      border-bottom: none;
    }
  }

  .culture-select-btn {
    @include x-rem(padding-left, 10px);
    text-decoration-thickness: 2px;
    text-decoration: underline solid currentColor 2px;
    text-underline-offset: 5px;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  .culture-selector__list {
    position: relative;
    width: 100%;
    top: 20px;
    box-shadow: none;
    border-radius: 0;
  }
  .culture-selector__item {
    @include x-rem(padding, 10px);
  }
  svg {
    fill: $color-base;
  }

  @include breakpoint(81.25em) {
    display: none;
  }
}

// util links mobile
.header-util {
  @include resetList;
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 30px);
  border-bottom: 1px solid $color-gray3;
  @include x-rem(margin, 0 20px);
  @include x-rem(padding, 32px 0);
  @include breakpoint(81.25em) {
    display: none;
  }
}
.header-util__item {
}
.header-util__link {
  @include x-rem(font-size, 24px);
  font-weight: $fontWeight-semibold;
  color: $color-base;
  text-decoration-thickness: 2px;
  text-decoration: underline solid currentColor 2px;
  text-underline-offset: 5px;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
// styles for when the nav BG turns white
.sticky-header-desktop {
  .ghost-white {
    outline: 1px solid $color-base;
    color: $color-base;
  }
  svg {
    fill: $color-base;
  }
}
// header styles when hovered
.hovered-nav {
  background: $body-bg;

  .header-logo__svg--dark {
    opacity: 1;
  }
  .header-logo__svg--light {
    opacity: 0;
  }

  a,
  [data-expander-toggle] {
    color: $color-base !important;
  }
  a.skip-to-main {
    color: $color-invert !important;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  svg {
    fill: $color-base;
  }
  .ghost-white {
    outline-color: $color-base;
    &:hover,
    &:focus {
      outline-color: $color-yellow;
    }
  }
}
