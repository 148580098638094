$alignments: left, right, center;
$flex-alignments: flex-start, flex-end, center, space-between, space-around,
  stretch;
$displays: none, inline, inline-block, block, table, table-cell, inline-flex,
  flex;
$flexDirection: row, column, row-reverse, column-reverse;

$grids: (
  "2x2": 2,
  "3x3": 3,
  "4x4": 4,
  "5x5": 5,
  "6x6": 6,
);

$sides: (
  all: "",
  vertical: "y",
  horizontal: "x",
  top: "t",
  right: "r",
  bottom: "b",
  left: "l",
);

$breakpoints: (
  "xs": $bp-x-small,
  "sm": $bp-small,
  "md": $bp-m,
  "lg": $bp-large,
  "xl": $bp-x-large,
  "xxl": $bp-xx-large,
);
$spacer: $grid-gutter;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer * 0.75,
  4: $spacer * 1,
  5: $spacer * 1.25,
  6: $spacer * 1.5,
  7: $spacer * 1.75,
  8: $spacer * 2,
  9: $spacer * 2.25,
  10: $spacer * 2.5,
  11: $spacer * 2.75,
  12: $spacer * 3,
  13: $spacer * 3.25,
  14: $spacer * 3.5,
  15: $spacer * 3.75,
  16: $spacer * 4,
  17: $spacer * 4.25,
  18: $spacer * 4.5,
  19: $spacer * 4.75,
  20: $spacer * 5,
  21: $spacer * 5.25,
  22: $spacer * 5.5,
  23: $spacer * 5.75,
  24: $spacer * 6,
  50: $spacer * 15,
);
$flex-box-items: (
  1: 10%,
  2: 20%,
  3: 30%,
  4: 40%,
  5: 50%,
  6: 60%,
  7: 70%,
  8: 80%,
  9: 90%,
  10: 100%,
);

/* triangles */
// up + down triangles
.triangle-buttons {
  position: relative;
  &::after {
    content: "";
    border-style: solid;
    height: 0px;
    width: 0px;
    position: absolute;
    right: 0;
  }
}
.triangle-buttons__top {
  &::after {
    border-color: transparent transparent #d1d5db;
    border-width: 0 0.5rem 0.5rem;
  }
}
.triangle-buttons__right {
  &::after {
    border-color: transparent transparent transparent #d1d5db;
    border-width: 0.5rem 0 0.5rem 0.5rem;
  }
}

.triangle-buttons__bottom {
  &::after {
    border-color: #d1d5db transparent transparent;
    border-width: 0.5rem 0.5rem 0;
  }
}

.triangle-buttons__left {
  &::after {
    border-color: transparent #d1d5db transparent transparent;
    border-width: 0.5rem 0.5rem 0.5rem 0;
  }
}

.fit-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.whiten-text {
  p:last-child {
    margin: 0;
  }
  &,
  p,
  ol,
  ul,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-invert;
  }
  a:not(.btn) {
    color: $color-invert;
  }
}
.blue-curve {
  background-image: url("../images/shapes/blue-curve-bottom.png");
  @include bgImg;
  @extend .whiten-text;
}
.no-margin-last {
  p:last-child {
    margin: 0;
  }
}
.go-back-cta {
  color: $color-base;
  font-weight: $fontWeight-bold;
  font-size: 1rem;
  text-decoration-thickness: 2px;
  text-decoration: underline solid currentColor 2px;
  text-underline-offset: 5px;
  text-decoration: underline;
  &:hover,
  &:focus {
    text-decoration: underline;
    color: $color-link-heavy;
  }
  &.white {
    color: $color-invert;
  }
}
.bottom-border1 {
  border-bottom: 1px solid $color-gray4;
}
.flex-shrink0 {
  flex-shrink: 0;
}
.bottom-curve {
  position: relative;

  &:before {
    content: "";
    background-image: url("../images/shapes/bottomcurvemobilehighres.png");
    @include bgImg;
    background-size: cover;
    background-position: bottom;
    position: absolute;
    z-index: 3;
    width: 100%;
    bottom: -1px;
    left: 0;
    height: 100%;
  }
  @include breakpoint($bp-m) {
    &:before {
      background-image: url("../images/shapes/bottomcurve.png");
    }
  }
}
.center-text {
  text-align: center;
}
.disclaimer-text {
  @include x-rem(font-size, 24px);
  line-height: 1.5;
  letter-spacing: -0.32px;
  font-style: italic;
  @media (min-width: $bp-large) {
    @include x-rem(font-size, 32px);
  }
}
.resetList {
  @include resetList;
}
.two-col-list {
  @media (min-width: $bp-m) {
    column-count: 2;
  }
}

.list-columns {
  @extend .resetList;
  display: flex; 
  justify-content: center; 
}
.list-columns .list-columns__item {
}

.list-columns--3-col {
  .list-columns__item {
    @include breakpoint($bp-small) {
      width: 50%;
    }
    @include breakpoint($bp-m) {
      width: 33.333333%;
    }
  }
}

.list-columns--2-col {
  .list-columns__item {
    @include breakpoint($bp-small) {
      width: 50%;
    }
  }
}
.testimonial-card {
  position: relative;
  @include x-rem(margin, 32px 0);
  background: $body-bg;
  box-shadow: 25px 25px 50px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  @include x-rem(padding, 26px 22px 26px 45px);

  p:last-child {
    margin: 0;
  }
  p,
  & {
    @include x-rem(font-size, 26px);
    letter-spacing: -0.26px;
    line-height: normal;
  }
  &:before {
    content: "";
    position: absolute;
    left: 20px;
  }
  @media (min-width: $bp-m) {
    @include x-rem(padding, 32px 32px 32px 80px);
    p,
    & {
      @include x-rem(font-size, 32px);
      letter-spacing: -0.32px;
    }
    &:before {
      top: 50%;
      left: 40px;
      transform: translateY(-50%);
      width: 2px;
      height: 65%;
      background: $color-gray4;
    }
  }
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-no-wrap {
  flex-wrap: nowrap;
}
[class*="grid-"] {
  display: grid;
  gap: $grid-gutter;
}
@each $grid, $value in $grids {
  .grid-#{$grid} {
    grid-template-columns: repeat($value, minmax(0px, 1fr));
  }

  .grid-#{$grid}-auto {
    grid-template-columns: repeat($value, minmax(auto, auto));
  }
}
// Display
@each $display in $displays {
  .display-#{$display} {
    display: $display !important;
  }
}
// vertical flex alignments
@each $vertical-alignment in $flex-alignments {
  .align-items-#{$vertical-alignment} {
    align-items: $vertical-alignment;
  }
}

@each $horizontal-alignment in $flex-alignments {
  .justify-content-#{$horizontal-alignment} {
    justify-content: $horizontal-alignment;
  }
}
// Text Alignment
@each $alignment in $alignments {
  .text-#{$alignment} {
    text-align: $alignment;
  }
}

// margin, padding, gap spacers
@each $side, $sideValue in $sides {
  @each $space, $spaceValue in $spacers {
    @if ($side == "all") {
      .padding-#{$sideValue}-#{$space} {
        padding: $spaceValue !important;
      }

      .margin-#{$sideValue}-#{$space} {
        margin: $spaceValue !important;
      }

      .gap-#{$space} {
        gap: $spaceValue !important;
      }
    } @else if($side == "vertical") {
      .padding-#{$sideValue}-#{$space} {
        padding-top: $spaceValue !important;
        padding-bottom: $spaceValue !important;
      }

      .margin-#{$sideValue}-#{$space} {
        margin-top: $spaceValue !important;
        margin-bottom: $spaceValue !important;
      }

      .-margin-#{$sideValue}-#{$space} {
        margin-#{$side}: -$spaceValue !important;
      }

      .row-gap-#{$space} {
        row-gap: $spaceValue !important;
      }
    } @else if ($side == "horizontal") {
      .padding-#{$sideValue}-#{$space} {
        padding-left: $spaceValue !important;
        padding-right: $spaceValue !important;
      }

      .margin-#{$sideValue}-#{$space} {
        margin-left: $spaceValue !important;
        margin-right: $spaceValue !important;
      }

      .column-gap-#{$space} {
        column-gap: $spaceValue !important;
      }
    } @else {
      .padding-#{$sideValue}-#{$space} {
        padding-#{$side}: $spaceValue !important;
      }

      .-margin-#{$sideValue}-#{$space} {
        margin-#{$side}: -$spaceValue !important;
        position: relative;
        z-index: 1;
      }

      .margin-#{$sideValue}-#{$space} {
        margin-#{$side}: $spaceValue !important;
      }
    }
  }
}
/* Media Queries
================================================================ */
@each $bpAbbv, $bp in $breakpoints {
  @media (min-width: $bp) {
    @each $grid, $value in $grids {
      .grid-#{$bpAbbv}-#{$grid} {
        grid-template-columns: repeat($value, minmax(0px, 1fr));
      }

      .grid-#{$bpAbbv}-#{$grid}-auto {
        grid-template-columns: repeat($value, minmax(auto, auto));
      }
    }

    // Image Alignment
    @each $alignment in $alignments {
      @if $alignment == left {
        .image-#{$bpAbbv}-#{$alignment} {
          display: block;
          margin: 0 0 0 auto;
        }
      } @else if $alignment == right {
        .image-#{$bpAbbv}-#{$alignment} {
          display: block;
          margin: 0 auto 0 0;
        }
      } @else if $alignment == center {
        .image-#{$bpAbbv}-#{$alignment} {
          display: block;
          margin: 0 auto;
        }
      }
    }

    // Displays
    @each $display in $displays {
      .display-#{$bpAbbv}-#{$display} {
        display: $display !important;
      }
    }

    @each $vertical-alignment in $flex-alignments {
      .align-items-#{$vertical-alignment}-#{$bpAbbv} {
        align-items: $vertical-alignment !important;
      }
    }

    @each $horizontal-alignment in $flex-alignments {
      .justify-content-#{$horizontal-alignment}-#{$bpAbbv} {
        justify-content: $horizontal-alignment !important;
      }
    }

    // flex direction
    @each $direction in $flexDirection {
      .flex-#{$bpAbbv}-#{$direction} {
        flex-direction: $direction !important;
      }
    }

    // Text Alignments
    @each $alignment in $alignments {
      .text-#{$bpAbbv}-#{$alignment} {
        text-align: $alignment !important;
      }
    }

    // Spacing
    @each $side, $sideValue in $sides {
      @each $space, $spaceValue in $spacers {
        @if ($side == "all") {
          .p#{$sideValue}-#{$bpAbbv}-#{$space} {
            padding: $spaceValue !important;
          }

          .m#{$sideValue}-#{$bpAbbv}-#{$space} {
            margin: $spaceValue !important;
          }
        } @else if($side == "vertical") {
          .p#{$sideValue}-#{$bpAbbv}-#{$space} {
            padding-top: $spaceValue !important;
            padding-bottom: $spaceValue !important;
          }

          .m#{$sideValue}-#{$bpAbbv}-#{$space} {
            margin-top: $spaceValue !important;
            margin-bottom: $spaceValue !important;
          }
        } @else if ($side == "horizontal") {
          .p#{$sideValue}-#{$bpAbbv}-#{$space} {
            padding-left: $spaceValue !important;
            padding-right: $spaceValue !important;
          }

          .m#{$sideValue}-#{$bpAbbv}-#{$space} {
            margin-left: $spaceValue !important;
            margin-right: $spaceValue !important;
          }
        } @else {
          .p#{$sideValue}-#{$bpAbbv}-#{$space} {
            padding-#{$side}: $spaceValue !important;
          }

          .m#{$sideValue}-#{$bpAbbv}-#{$space} {
            margin-#{$side}: $spaceValue !important;
          }
        }
      }
    }
  }
}

@media (min-width: $bp-large) {
  @each $col, $colValue in $flex-box-items {
    .flex-box-item--#{$col} {
      width: $colValue;
    }
  }
}
//  color classes
.text-blue {
  color: $category-blue;
}
.text-green {
  color: $category-green;
}
.text-red {
  color: $category-red;
}
.text-yellow {
  color: $category-yellow;
}
.text-white {
  color: $color-invert;
}
.title-black-text {
  color: $color-black8;
}
.link-black-text {
  color: $color-black9;
}

// bottom border
$border-red: #c63039;
$border-blue: #00a1d1;
$border-yellow: #f3cc23;
$border-green: #00ae42;
$border-white: $color-invert;

[class*="header-bottom-border--"] {
  @include x-rem(padding-bottom, 30px);
  border-bottom: 7px solid transparent;
}
.header-bottom-border {
  &--red {
    border-color: $border-red;
  }
  &--blue {
    border-color: $border-blue;
  }
  &--yellow {
    border-color: $border-yellow;
  }
  &--green {
    border-color: $border-green;
  }
  &--white {
    border-color: $border-white;
  }
  &--none {
    padding: 0;
    border: none;
  }
}
.fit-logo-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
