.carousel1-box__wrapper {
  @include x-rem(padding-bottom, 50px);
  @include breakpoint($bp-small) {
    padding: 0;
  }
}
.carousel1-box__wrapper .title-box {
  @include x-rem(margin-bottom, 50px);
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 100px);
  }
}
.carousel1-box {
  position: relative;
  z-index: 5;
}

.procedure-listing {
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 30px);
  @include x-rem(margin-bottom, 40px);
  @include breakpoint($bp-small) {
    @include x-rem(padding, 0 40px);
  }
  @include breakpoint($bp-m) {
    @include x-rem(padding, 0 70px);
    margin-bottom: 0;
  }
  @include breakpoint($bp-large) {
    @include x-rem(padding, 0 53px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(padding, 0 66px);
  }
  @include breakpoint($bp-xx-large) {
    @include x-rem(padding, 0 112px);
  }
}
.procedure-listing__container {
  display: flex;
  flex-direction: column;
  position: relative;
  // @include x-rem(padding-bottom, 70px);
  @include breakpoint($bp-large) {
    flex-direction: row;
    @include x-rem(gap, 24px);
    padding: 0;
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(gap, 40px);
  }
  @include breakpoint($bp-xx-large) {
    @include x-rem(gap, 32px);
  }
}
.procedure-listing__content {
  @include x-rem(margin-bottom, 32px);
  @include breakpoint($bp-large) {
    margin: 0;
    width: 100%;
    @include x-rem(max-width, 528px);
    &.full-content {
      max-width: 100%;
    }
  }
}
.procedure-listing__title {
  font-weight: $fontWeight-bold;
}
.procedure-listing__text {
  @extend .largest;
  p:last-child {
    margin: 0;
  }
}
.procedure-listing__l-imgbox {
  width: 100%;

  &--mobile {
    position: absolute;
    bottom: 0px;
    left: 35px;
    z-index: 3;
  }
  &--tablet {
    display: none;
  }
  &--desktop {
    display: none;
  }
   
  @include breakpoint(26.25em) {
    width: fit-content;
    position: relative;
    bottom: initial;
    left: initial;
    max-width: initial;
    height: initial;
  }

  @include breakpoint($bp-large) {
    margin-left: auto;
    right: -135px;
    top: 15px;
    &--mobile {
      display: none;
    }
    &--desktop {
 
      display: block;
      margin-left: auto;
      right: -135px;
      top: 15px;
      img {
          @include fitImage;
      }
    }
  }
}
.procedure-listing__imgbox {
  img {
    @include fitImage;
  }
  &--mobile {
    max-width: 220px;
    margin-left: auto;
    position: relative; 
    z-index: 2;
  }
  &--tablet {
    display: none;
  }
  &--desktop {
    display: none;
  }

  @include breakpoint(26.25em) {
    margin: 0;
    max-width: 100%;
    height: initial;
  }

  @include breakpoint($bp-large) {
    flex: 1;
    width: initial;
    margin: initial;
    img {
      height: initial;
      width: initial;
    }

    &--mobile {
      display: none;
    }
    &--desktop {
      display: block;
    }
  }
}
.procedure-listing-slider .progress-bar-wrapper {
  @include x-rem(margin-bottom, 45px);
  @include breakpoint($bp-small) {
    @include x-rem(margin-bottom, 53px);
  }
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 65px);
  }
}

.procedure-listing-slider .progress-bar {
  position: absolute;
  top: -1px;
  left: 0;
}
.procedure-listing-slider__btn-wrapper {
  display: flex;
  justify-content: space-between;
  @include x-rem(padding, 0 20px);
  @include breakpoint($bp-small) {
  }
}
// slider buttons

.procedure-listing-slider {
  .slider-button {
    @include resetBtn;
    svg {
      @include x-rem(height, 40px);
      @include x-rem(width, 20px);
    }
    &:hover,
    &:focus {
      svg {
        transform: scale(1.3);
      }
    }
  }
  .slick-disabled {
    pointer-events: none;
    svg {
      opacity: 0.2;
    }
  }

  @include breakpoint($bp-small) {
    .slider-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &--left {
        left: 0;
      }
      &--right {
        right: 0;
      }
    }
  }
  @include breakpoint($bp-large) {
    .slider-button {
      top: 254px;
      transform: initial;
    }
  }
}
// individual slide contents

.procedure-slide-container {
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 32px);
}
.procedure-slide-wrapper {
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 32px);
  @include breakpoint($bp-small) {
    flex-direction: row;
    align-items: center;
  }
}
.procedure-slide-wrapper__imgbox {
  flex-shrink: 0;
} // slider styles for overlapping content on mobile
.procedure-listing__l-imgbox {
  // position: absolute;
}
