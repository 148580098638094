/*================================================================
            MAIN NAVIGATION STYLES
================================================================*/

.main-navigation {
  position: relative;
  @include x-rem(margin-bottom, 23px);

  @include breakpoint(81.25em) {
    padding: 0;
    margin: 0;
    border-bottom: 0;
  }
  //Nav Defaults

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
  }
  a,
  button {
    display: block;
    width: 100%;
    border: 0;
    padding: 10px;
    text-align: left;
    background: none;
    cursor: pointer;
    text-decoration: none;

    span {
      font-family: $font-primary;
    }

    &:hover,
    &:focus {
    }

    &[data-expander-toggle] {
      position: relative;
    }
  }

  .main-navigation__heading {
    font-weight: bold;
  }

  // 1st Level
  // ********************************************* //
  > ul,
  .main-navigation-mega > ul {
    @include x-rem(padding-bottom, 50px);
    border-bottom: 1px solid $color-gray3;
    @include x-rem(margin-left, 20px);
    @include x-rem(margin-right, 20px);
    background: $body-bg;
    @include x-rem(padding-top, 43px);
    > li {
      @include x-rem(margin-bottom, 30px);
      &:last-child {
        margin: 0;
      }
      > a,
      > button {
        color: $color-base;
        font-weight: $fontWeight-semibold;
        @include x-rem(font-size, 26px);
        @include x-rem(line-height, 35px);
        text-decoration-thickness: 2px;
        text-decoration: underline solid currentColor 2px;
        text-underline-offset: 10px;
        text-decoration: none;
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
      [data-expander-toggle] {
        position: relative;

        .main-navigation__top-svg {
          @include x-rem(height, 10px);
          @include x-rem(width, 17px);
          @include x-rem(margin-left, 30px);
        }

        &:hover,
        &:focus {
          .main-navigation__top-svg {
            transform: rotate(180deg);
          }
        }
        &.active {
          text-decoration: underline;
          .main-navigation__top-svg {
            transform: rotate(180deg);
          }
        }
      }
      .current-page {
        text-decoration: underline;
      }
      // 2nd Level
      // ********************************************* //
      > ul {
        @include x-rem(margin-top, 30px);
        @include x-rem(margin-left, 20px);
        > li {
          > a,
          > button {
            color: $color-base;
            font-weight: $fontWeight-semibold;
            @include x-rem(font-size, 20px);
            @include x-rem(line-height, 25px);
            @include x-rem(margin-bottom, 24px);
            &:hover,
            &:focus {
              text-stroke: intitial !important;
              -webkit-text-stroke: initial !important;
              background: $color-gray;
              color: $color-primary;
            }
          }

          // 3rd Level
          // ********************************************* //

          > ul {
            background: #555555;

            > li {
              > a,
              > button {
                &:hover,
                &:focus {
                }
              }

              // 4th Level
              // ********************************************* //

              > ul {
                background: #333333;

                > li {
                  > a,
                  > button {
                    &:hover,
                    &:focus {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 81.25em) {
    //Nav Defaults

    ul {
      padding-top: 0;
    }
    li {
    }
    a,
    button {
      &:hover,
      &:focus {
      }
    }
    .main-navigation__top-svg {
      display: none;
    }
    .current-page {
      border-bottom: 2px solid currentColor;
    }

    // 1st Level
    // ********************************************* //
    > ul {
      display: flex;
      position: relative;
      background: none;
      background: transparent;
      @include x-rem(gap, 32px);
      border: none;
      margin: 0;
      padding: 0;

      > li {
        margin-bottom: 0;
        position: relative;
        &:after {
          content: "";
          opacity: 0;
        }

        &[data-type="mega"] {
          position: static;
        }

        &.active::after {
          display: block;
          @include x-rem(height, 24px);
          @include x-rem(width, 24px);
          position: absolute;
          left: 18px;
          bottom: -33px;
          z-index: 1;
          background-color: $body-bg;
          transform: rotate(45deg);
          opacity: 1;
          transition: $trans;
        }
        > a,
        > button {
          font-weight: $fontWeight-normal;
          padding: 0;
          @include x-rem(padding-bottom, 4px);
          @include x-rem(font-size, 17px);
          @include x-rem(line-height, 23px);
          color: $color-invert;
          border-bottom: 2px solid transparent;

          &:hover,
          &:focus,
          &.active {
            text-stroke: 0.3px currentColor;
            -webkit-text-stroke: 0.3px currentColor;
            border-bottom: 2px solid currentColor;
          }

          &[data-expander-toggle] {
            &::after {
              content: none;
            }
          }
        }

        // 2nd Level
        // ********************************************* //
        > ul {
          position: absolute;
          width: 100%;
          top: 170%;
          @include x-rem(min-width, 227px);
          background: $body-bg;
          border-radius: 4px;
          overflow: hidden;
          z-index: 2;
          margin: 0;
          white-space: nowrap;
          width: fit-content;
          @include x-rem(padding, 12px 0);
          box-shadow: $box-shadow1;

          > li {
            @include x-rem(margin, 3px 0);
            > a,
            > button {
              color: $color-black4;
              font-weight: $fontWeight-semibold;
              font-size: 1rem;
              @include x-rem(line-height, 20px);
              @include x-rem(padding, 10px 18px);
              @include x-rem(padding-right, 20px);
              margin: 0;

              &:hover,
              &:focus {
                text-stroke: 0.3px currentColor;
                -webkit-text-stroke: 0.3px currentColor;
                background: $color-gray;
                color: $color-primary;
              }
            }

            // 3rd Level
            // ********************************************* //

            > ul {
              background: #555555;

              > li {
                > a,
                > button {
                  &:hover,
                  &:focus {
                  }
                }

                // 4th Level
                // ********************************************* //

                > ul {
                  background: #333333;

                  > li {
                    > a,
                    > button {
                      &:hover,
                      &:focus {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      // Mega
      // ********************************************* //
      [data-nav-type="mega"] {
        position: static;

        .main-navigation-mega {
          padding: 20px;
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          background: #ccc;

          ul {
            display: block;
            animation: none;
            background: none;
          }

          > ul {
            display: flex;

            // Mega 1st Level
            // ********************************************* //
            > li {
              flex: 1;

              > a,
              > button {
                background: red;

                &:hover,
                &:focus {
                }
              }

              // Mega 2nd Level
              // ********************************************* //
              > ul {
                > li {
                  > a,
                  > button {
                    background: blue;

                    &:hover,
                    &:focus {
                    }
                  }

                  // Mega 3rd Level
                  // ********************************************* //
                  > ul {
                    > li {
                      > a,
                      > button {
                        background: green;

                        &:hover,
                        &:focus {
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
