/* ******************************************************
   Main Import File
        - Variables / Utilities / Globals
        - Components (Navigation/Tools/Modules)
        - Vendor Imports (Third party plugins)
        - Overwrite Imports (Print/admin)
   ****************************************************** */

/* ================================================================
   Variables / Utilities / Globals
   ================================================================ */

// Variables
@import "variables";

// SASS Functions and Modules
@import "utilities";

// Normalize
@import "globals/normalize";

// Base Styles
@import "globals/boilerplate";
@import "globals/grid";

// Global Element Styles
@import "globals/typography";
@import "globals/buttons";
@import "globals/data-expander";
@import "globals/tables";
@import "globals/forms";
@import "globals/utility-classes";

// Layout Styles
@import "globals/layout/base";
@import "globals/layout/header";
@import "globals/layout/header-sticky";
@import "globals/layout/interior";
@import "globals/layout/footer";

// Special
@import "globals/animations";

/* ================================================================
   Component Imports - Comment/uncomment what you don't need/need.
   ================================================================ */

// Navigation
@import "components/navigation/main-navigation";
@import "components/navigation/rail-navigation";
@import "components/navigation/hamburger";
@import "components/navigation/breadcrumbs";

// Tools
@import "components/tools/pagination";
@import "components/tools/pagetools";
@import "components/tools/search";

// Modules
@import "components/modules/banner";
@import "components/modules/events";
@import "components/modules/news";
@import "components/modules/videos";
@import "components/modules/alerts";
@import "components/modules/cookie-consent";
@import "components/modules/lists";
@import "components/modules/filters";
@import "components/modules/hero";
@import "components/modules/page-banner";
@import "components/modules/cards";
@import "components/modules/side-by-side";
@import "components/modules/image-slider";
@import "components/modules/img-block";
@import "components/modules/profiles";
@import "components/modules/procedural-slider";
@import "components/modules/modules";
@import "components/modules/featured-articles";
@import "components/modules/global-impact";
@import "components/modules/upcoming-dates";
@import "components/modules/personality-model";
@import "components/modules/location-selector";
@import "components/modules/expert-guidance";
@import "components/modules/quote-bg";
@import "components/modules/semifooter";
@import "components/modules/latest-blogs";
@import "components/modules/search-results";
@import "components/modules/hero2";
@import "components/modules/hero3";
@import "components/modules/hero4";
@import "components/modules/hero5";
@import "components/modules/hero6";
@import "components/modules/infographics";
@import "components/modules/training-kits";
@import "components/modules/social-medias";
@import "components/modules/circle-pattern";
@import "components/modules/disclaimers";
@import "components/modules/video-banner";
@import "components/modules/progress-bar";
@import "components/modules/timeline";
@import "components/modules/landscape-cards";
@import "components/modules/sharethis"; 
@import "components/modules/light-blue-banner"; 
@import "components/modules/tab-table"; 
@import "components/modules/quadrants";  
@import "components/modules/harness-steps";
@import "components/modules/roi-table";  
@import "components/modules/newsletter-hero"; 
@import "components/modules/quadrants-multi";
@import "components/modules/spinner";

// layouts for custom blogs 
@import "components/modules/customblogs/layout";  
@import "components/modules/customblogs/disc-style";
@import "components/modules/customblogs/sidebyside";
@import "components/modules/customblogs/infographics-box";
@import "components/modules/customblogs/comparison-cards";
@import "components/modules/customblogs/accordions";
@import "components/modules/customblogs/blog-navigation";
@import "components/modules/customblogs/blog-process";
@import "components/modules/customblogs/stats";

/* ================================================================
   Vendor Imports
   ================================================================ */

@import "vendor/slick";
@import "vendor/modaal";
@import "vendor/froala";

/* ================================================================
   Pages
   ================================================================ */

// Admin styles, resets and fixes
@import "pages/styleguide";
@import "pages/faq.scss";
@import "pages/ebooks-detail";
@import "pages/blog-page";
@import "pages/thankyou-page";
@import "pages/partnership";
@import "pages/page-not-found";

/* ================================================================
   Overwrite Imports
   ================================================================ */

// Print
@import "globals/print";
