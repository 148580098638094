.tab-spinner-holder {
  position: relative;
  transform: scale(0.6);
  left: -60px;
  @include x-rem(padding, 76px 20px 96px 20px);
  @include x-rem(margin-top, -160px);
  @include x-rem(margin-bottom, -140px);
  @include breakpoint(31.875em) {
    left: -20px;
    transform: scale(0.8);
  }
  @include breakpoint($bp-small) {
    transform: scale(1);
    left: initial;
    margin: 0;
  }
}
.tab-spinner-holder .container.container--medium {
  position: relative;
  @include x-rem(padding-top, 180px);
  @include x-rem(padding-bottom, 180px);

  @include breakpoint(68.75em) {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.spinner-board-box {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
  position: relative;
  @include x-rem(width, 550px);
  @include x-rem(height, 550px);
}
.spinner-board-box__circlesvg {
  position: absolute;
  pointer-events: none;
}
.spinner-board-box__circletext {
  position: absolute;
  pointer-events: none;
}
.spinner-board {
  position: relative;
  &:before,
  &:after {
    content: "";
    background: $body-bg;
    position: absolute;
    z-index: 2;
    pointer-events: none;
  }
  &:before {
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 10px;
    height: 100%;
  }
  &:after {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 10px;
    width: 100%;
  }
}
.spinner-board__letters {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;
}
.spinner-board__spin-line {
  width: 38px;
  position: absolute;
  z-index: 3;
  pointer-events: none; 

  &:before,
  &:after {
    content: "";
    pointer-events: none;
    background: $color-black7;
  }
  &:before {
    display: block;
    border-radius: 50%;
    @include x-rem(width, 38px);
    @include x-rem(height, 38px);
  }
  &:after {
    width: 5px;
    @include x-rem(height, 386px);
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
  }
}
.spinner-board__spin-line[data-rotation="spinner-d"] {
  left: 50px;
  top: 50px;
  rotate: -45deg;
}
.spinner-board__spin-line[data-rotation="spinner-i"] {
  top: 50px;
  right: 50px;
  rotate: 45deg;
}
.spinner-board__spin-line[data-rotation="spinner-s"] {
  right: 50px;
  rotate: 135deg;
  bottom: 50px;
}
.spinner-board__spin-line[data-rotation="spinner-c"] {
  left: 50px;
  rotate: -135deg;
  bottom: 50px;
}

.spinner-board__blob {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  &--d {
    top: -1px;
    left: 1px;
  }
  &--i {
    left: 133px;
    top: -5px;
  }
  &--s {
    bottom: -14px;
    right: -5px;
  }
  &--c {
    bottom: -6px;
    left: -5px;
  }
}
.spinner-board__quadrant{
  &:focus, &:hover {
    outline: none;
  }
}
.spinner-board__quadrant--d { 
  &.open,
  &:hover,
  &:focus {
    + .spinner-board__blob--d {
      opacity: 1;
    }
  }
}
.spinner-board__quadrant--c {
  &.open,
  &:hover,
  &:focus {
    + .spinner-board__blob--c {
      opacity: 1;
    }
  }
}
.spinner-board__quadrant--i {
  &.open,
  &:hover,
  &:focus {
    + .spinner-board__blob--i {
      opacity: 1;
    }
  }
}
.spinner-board__quadrant--s {
  &.open,
  &:hover,
  &:focus {
    + .spinner-board__blob--s {
      opacity: 1;
    }
  }
}
.spinner-board__quadrant {
  @include resetBtn;
  display: inline-block;
  background: $color-gray9;
  @include x-rem(width, 220px);
  @include x-rem(height, 220px);
}
.spinner-board__top-left {
  border-top-left-radius: 100%;
  border-width: 4px 2px 2px 4px;
}

.spinner-board__top-right {
  border-top-right-radius: 100%;
  border-width: 4px 4px 2px 2px;
}

.spinner-board__bottom-left {
  border-bottom-left-radius: 100%;
  border-width: 2px 2px 4px 4px;
}

.spinner-board__bottom-right {
  border-bottom-right-radius: 100%;
  border-width: 2px 4px 4px 2px;
}
.spinner-board__bottom{
  height: 220px;
}
.spinner-board__quadrant--s, .spinner-board__quadrant--c{
  position: absolute;
}
.spinner-board__quadrant--s{
  right: 0;
}
// items

.spinner-board-item__text {
  border: 2px solid transparent;
  border-radius: 10px;
  box-shadow: $box-shadow1;
  @include x-rem(padding, 16px 20px 16px 24px);
  p {
    margin: 0;
    @include x-rem(font-size, 18px);
    @include x-rem(line-height, 24px);
  }
  &--d {
    border-color: $category-green;
  }
  &--i {
    border-color: $category-red4;
  }
  &--s {
    border-color: $category-blue;
  }
  &--c {
    border-color: $category-yellow4;
  }
  @include breakpoint($bp-m) {
    @include x-rem(padding, 16px 27px 16px 24px);
  }
}
.spinner-board-item {
  transform: initial !important;
  animation: none;

  &.open {
    display: block;
  }
}
.spinner-board-item__text {
  width: 100%;
  transform: scale(1.5);
  @include x-rem(max-width, 304px);
  @include breakpoint($bp-small) {
    transform: scale(1);
  }
}
.spinner-board-item__text--s,
.spinner-board-item__text--d {
  &.spinner-board-item__text--top {
    top: 0;
    left: 55px;
    position: absolute;
    @include breakpoint($bp-small) {
      left: 0;
    }
  }
  &.spinner-board-item__text--bottom {
    bottom: 0;
    right: -145px;
    position: absolute;
    @include breakpoint(31.875em) {
      right: 0;
    }
  }
}
.spinner-board-item__text--c,
.spinner-board-item__text--i {
  &.spinner-board-item__text--top {
    bottom: 0;
    left: 0;
    left: 55px;
    position: absolute;
    @include breakpoint($bp-small) {
      left: 0;
    }
  }
  &.spinner-board-item__text--bottom {
    top: 0;
    right: 0;
    right: -145px;
    position: absolute;
    @include breakpoint(31.875em) {
      right: 0;
    }
  }
}
