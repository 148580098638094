.sharing-box__title {
  text-align: center;
  @include x-rem(margin-bottom, 32px);
  @include x-rem(font-size, 32px);
}

.social-medias {
  @include resetList;
  display: flex;
  justify-content: center;
  align-items: center;
  @include x-rem(gap, 15px);
}
.social-medias__item {
  svg {
    @include x-rem(height, 28px);
    @include x-rem(width, 40px);
  }
}
