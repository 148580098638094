.semifooter {
  text-align: center;
  position: relative;
  @include breakpoint($bp-small) {
    &--right {
      .semifooter__textbox {
        margin: initial;
        margin-left: auto;
      }
    }

    &--left {
      .semifooter__textbox {
        margin: initial;
        margin-right: auto;
      }
    }
  }
}

.newsletter-box {
  position: relative;
  z-index: 2;
  @include x-rem(margin-bottom, -30px);
  @include breakpoint(39.375em) {
    @include x-rem(margin-bottom, -60px);
  }
  @include breakpoint(62.5em) {
    @include x-rem(margin-bottom, -100px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(margin-bottom, -150px);
  }
  @include breakpoint($bp-x-large-m) {
    @include x-rem(margin-bottom, -208px);
  }
}
.semifooter__bottom {
  @include bgImg;
  background-position: initial;
  @include x-rem(min-height, 540px);
  @include x-rem(padding, 74px 0 77px 0);
  @include breakpoint($bp-m) {
    @include x-rem(min-height, 700px);
    @include x-rem(padding, 175px 0 200px 0);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(min-height, 1074px);
    @include x-rem(padding, 307px 0 300px 0);
  }
  @include breakpoint($bp-x-large-m) {
    background-position: 100% 520%;
    background-size: 101%;
  }

  @include breakpoint($bp-xx-large) {
    background-position: initial;
    background-size: cover;
  }
}
.newsletter__content {
  @include x-rem(margin-bottom, 18px);

  p:last-child {
    margin: 0;
  }
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 32px);
  }
}
.semifooter__textbox {
  width: 100%;
  @include x-rem(max-width, 329px);
  margin: 0 auto;

  @include breakpoint($bp-small) {
    text-align: left;
    @include x-rem(max-width, 342px);
  }
  @include breakpoint($bp-m) {
    @include x-rem(max-width, 458px);
  }
  @include breakpoint($bp-large) {
    @include x-rem(max-width, 658px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(max-width, 728px);
  }
}
.semifooter__title {
  @include x-rem(margin-bottom, 32px);
}
.semifooter--left {
  .semifooter__bottom {
  }
  .semifooter__img {
    @include bgImg;
    background-position: left;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .semifooter__textbox {
    position: relative;
    z-index: 6;
  }
}
.semifooter--right {
  @include breakpoint($bp-large) {
    @include x-rem(margin-top, -150px);
  }

  @include breakpoint($bp-large) {
    @include x-rem(margin-top, -180px);
  }
  @include breakpoint($bp-x-large-m) {
    @include x-rem(margin-top, -240px);
  }

  .semifooter__bottom {
    @include x-rem(padding, 74px 0 150px 0);
    @include breakpoint($bp-large) {
      @include x-rem(padding, 220px 0 360px 0);
    }
    @include breakpoint($bp-x-large-m) {
      @include x-rem(padding, 300px 0 360px 0);
    }
  }
  .semifooter__img {
    display: none;
    @include bgImg;

    @include breakpoint($bp-large) {
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    @include breakpoint($bp-x-large-m) {
    }

    @include breakpoint(90.0625em) {
      background-size: cover;
    }
  }

  .semifooter__textbox {
    @include x-rem(max-width, 625px);
    position: relative;
    z-index: 6;
    margin: 0 auto;
    @include breakpoint($bp-small) {
      text-align: center;
    }

    @include breakpoint($bp-large) {
      @include x-rem(max-width, 458px);
      text-align: left;
      margin: 0;
      margin-left: auto;
    }
    @include breakpoint($bp-x-large) {
      @include x-rem(max-width, 728px);
    }
  }
}
// light blue theme for mobile only

.semifooter--right.light-blue-banner {
  padding: 0;

  &:before,
  &:after {
    z-index: 1;
    left: 25%; 
  }
  @include breakpoint($bp-small) {
    @include x-rem(padding, 100px 0);
  }

  @include breakpoint($bp-large) {
    &:before,
    &:after {
      content: none;
    }
  }
}
