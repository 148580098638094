.progress-bar-wrapper {
  width: 100%;
  border: 1px solid $color-gray4;
  border-radius: 100px;
  position: relative;
}

.progress-bar {
  border-radius: 100px;
  background: $color-primary;
  height: 3px;
  transition: $trans;
}
