.blog-page {
  display: block;
  position: relative;

  .go-back-cta {
    position: absolute;
    top: 94px;
    z-index: 4;
    @include breakpoint($bp-large) {
      top: 130px;
    }
  }
}

.blog-top {
  display: block;
  @include x-rem(gap, 56px);
  @include x-rem(padding, 20px 0 46px 0);
  @include breakpoint($bp-m) {
    display: flex;
    justify-content: space-between;
  }
}

.blog-top__stats {
  margin: 0;
  flex: 1;
  font-size: 1rem;
}

.blog-top__author {
    margin: 0;
    flex: 1;
    font-size: 1rem;
}

.blog-top__logo {
    padding: 1rem;
    display: block;
    margin: 0 auto;
}

.share-listing1 {
  @include resetList;
  display: flex;
  @include x-rem(gap, 20px);
}
.share-listing__item svg {
  @include x-rem(height, 24px);
  @include x-rem(width, 24px);
}

.blog-share {
  @include x-rem(padding, 42px 0 50px 0);
  text-align: center;
}
.blog-share__title {
  @include x-rem(margin-bottom, 32px);
  @include x-rem(font-size, 26px);
  @include x-rem(line-height, 35px);
  @include breakpoint($bp-large) {
    @include x-rem(font-size, 32px);
    @include x-rem(line-height, 44px);
  }
}
.blog-share {
  .share-listing1 {
    justify-content: center;
  }
  .share-listing__item svg {
    fill: $color-yellow;
  }
}

// styles for success story detail pages (with logo, data/read time, and share tools)

.blog-top__2cols {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  @include breakpoint($bp-large) {
    flex-direction: row;
    align-items: center;
  }
}
.blog-top__col1 {
  display: flex;
  flex-direction: column;
  gap: 5px;

  img {
    max-height: 50px;
    width: fit-content;
  }

  @include breakpoint($bp-small) {
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }
}

.blog-top__col2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  @include breakpoint($bp-small) {
    flex-direction: row;
    align-items: center;
    gap: 33px;
  }
}
.blog-top__1cols .blog-top__col2 {
  flex: 1;
  justify-content: space-between;
}
.blog-detail {
  @include x-rem(padding-bottom, 62px);
  @include breakpoint($bp-large) {
    @include x-rem(padding-bottom, 100px);
  }
}
.blog-detail__main {
  & {
    @extend .largest;
  }
}
.blog-bottom {
  form {
    @include x-rem(padding-top, 50px);
    @include breakpoint($bp-large) {
      @include x-rem(padding-top, 100px);
    }
  }
  .blue-curve {
    background-position: bottom;
    @include x-rem(padding, 42px 0);
    @include breakpoint($bp-large) {
      @include x-rem(padding, 100px 0);
    }
  }
}
.newsletter-formbox {
  @include x-rem(padding, 54px 0 100px 0);
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 31px);

  @include breakpoint($bp-large) {
    @include x-rem(padding, 100px 0 240px);
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.newsletter-formbox__box1 {
  text-align: center;
  @include breakpoint($bp-large) {
    width: 100%;
    @include x-rem(max-width, 560px);
    text-align: left;
  }
}
.newsletter-formbox__title {
}
.newsletter-formbox__text {
}

.blog-form-wrapper {
  .blog-bottom__flex {
    display: flex;
    align-items: center;
    flex-direction: column;
    @include x-rem(gap, 30px);
    text-align: center;
    @include x-rem(padding-top, 50px);
    @include breakpoint($bp-x-large) {
      flex-direction: row;
      @include x-rem(gap, 112px);
      @include x-rem(padding-top, 100px);
      text-align: left;
    }
  }
  .gray-curve-section__left {
    flex: 1;
  }
  .gray-curve-section__left h2 {
    @include x-rem(font-size, 54px);
    @include x-rem(line-height, 64px);
    @include x-rem(margin-bottom, 25px);
    @include breakpoint($bp-large) {
      @include x-rem(font-size, 72px);
      @include x-rem(line-height, 72px);
      @include x-rem(margin-bottom, 16px);
    }
  }
  .gray-curve-section__formbox2 {
    flex: 1;
    @include x-rem(padding, 0 20px);
    @include breakpoint($bp-large) {
      padding: 0;
    }
  }
  .gray-curve-section__formbox2 form {
    padding-top: 0;
    a {
      @extend .heavy-link;
      text-underline-offset: initial;
      font-weight: $fontWeight-bold !important;
    }
  }
  label,
  .form-field {
    text-align: left;
  }
  .form-field a {
    font-size: 1rem !important;
  }
  input[type="submit"] {
  }
  .ktc-default-section {
    @include x-rem(margin-top, 32px);
    @include x-rem(padding-top, 12px);
    border-top: 1px solid $color-gray4;
    .ktc-checkbox {
      display: flex;
      align-items: flex-start;
      gap: 5px;
    }
    .ktc-checkbox a,
    .ktc-checkbox label {
      @include x-rem(font-size, 17px);
      line-height: normal;
    }
    input[type="checkbox"] {
      height: 23px !important;
      width: 23px !important;
      margin: 0 !important;
      position: relative;
      top: 5px;
    }
  }
}

// share widget styles for blog page top + bottom
.blog-page,
.blog-form-wrapper {
  .share-page-box {
    border-top: 0;
    padding-top: 0;
  }
}
.blog-top {
  .title-box {
    display: none;
  }
  .share-icons {
    @include x-rem(min-width, 170px);
  }
  .share-icons__icon,
  .copy-link-btn__icon {
    @include x-rem(height, 21px);
  }
}
.blog-bottom {
  .share-icons {
    @include x-rem(gap, 20px);
    @include x-rem(min-width, 330px);
  }
  .title-box h2 {
    @include x-rem(margin-bottom, 32px);
    @include x-rem(font-size, 26px);
    @include x-rem(line-height, 35px);
    @include breakpoint($bp-large) {
      @include x-rem(font-size, 32px);
      @include x-rem(line-height, 44px);
    }
  }
  .share-icons__link,
  .copy-link-btn,
  .copy-modal-btn {
    &:hover,
    &:focus {
      svg {
        fill: $color-invert;
      }
    }
  }
  .share-icons__icon,
  .copy-link-btn__icon {
    fill: $color-yellow;
    @include x-rem(height, 35px);
  }
}
