$table-bp: $bp-m;

.tab-table-box {
}
.tab-table-box--scroller {
  overflow-x: scroll;
}

.tab-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 8px;
}
.tab-table th,
.tab-table td {
  @include x-rem(padding, 8px);
  text-align: center;
  @include x-rem(font-size, 18px);
  font-weight: $fontWeight-bold;
  @include breakpoint($table-bp) {
    @include x-rem(font-size, 20px);
  }
}
.tab-table th[scope="col"] {
  background: $color-primary;
  color: $color-invert;
}

.tab-table th[scope="row"] {
  background: $color-skyblue;
  color: $color-black5;
}
.tab-table td {
  font-weight: normal;
  border: 1px solid $color-gray4;
}

// mobile / dekstop variants

.tab-table-mobile {
  .tab-table {
    margin: 0;
    @include x-rem(margin-bottom, -8px);
    th,
    td {
      width: 10%;
    }
  }
  .tab-table-group {
    @include x-rem(margin-bottom, 50px);
    &:last-child {
      margin: 0;
    }
  }
  .tab-table td {
    background-color: $color-gray2;
  }
  .tab-table-group--odd .tab-table td {
    background: $body-bg;
  }
  @include breakpoint($table-bp) {
    display: none;
  }
}
.tab-table-desktop {
  display: none;
  .tab-table tr:nth-child(odd) {
    td {
      background: $color-gray2;
    }
  }

  @include breakpoint($table-bp) {
    display: block;
  }
}
.tab-table-mobile--small {
  .tab-table {
    table-layout: fixed;
    th {
      width: 15%;
    }
  }
}
