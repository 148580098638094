.quote-bg {
  position: relative;
  @include x-rem(padding, 90px 0);

  @include breakpoint($bp-small) {
  }

  @include breakpoint($bp-m) {
    @include x-rem(padding-top, 125px);
    padding-bottom: 0
  }
  @include breakpoint($bp-large) {
    @include x-rem(padding-top, 200px);
    padding: 0;
  }
}
.quote-bg--curve {
  position: relative;
  @include breakpoint($bp-m) {
    &:before {
      content: "";
      background-image: url("../images/shapes/bottomcurve.png");
      @include bgImg;
      background-size: cover;
      background-position: bottom;
      position: absolute;
      z-index: 3;
      width: 100%;
      bottom: -1px;
      left: 0;
      height: 100%;
    }
  }
}

.quote-bg__box {
  background: $body-bg;
  border: 1px solid $color-gray2;
  box-shadow: $box-shadow1;
  border-radius: 10px;
  @include x-rem(padding, 29px 12px 30px 27px);
  width: 100%;
  position: relative;
  z-index: 12;

  @include breakpoint($bp-small) {
    @include x-rem(padding, 29px 31px 28px 37px);
  }
  @include breakpoint($bp-m) {
  }
  @include breakpoint($bp-large) {
    @include x-rem(padding, 30px 34px 32px 38px);
    // top: 380px;
  }

  @include breakpoint($bp-x-large) {
    @include x-rem(padding, 32px 60px 24px 38px);
  }
}

.quote-bg__container {
  @include breakpoint($bp-m) {
    margin-bottom: -290px;
  }
  @include breakpoint($bp-large) {
    margin-bottom: -380px;
  }
}

.quote-bg__wrapper {
  position: relative;
  @include x-rem(padding-left, 23px);
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    background: $color-gray4;
  }
  @include breakpoint($bp-large) {
    @include x-rem(padding-left, 30px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(padding-left, 36px);
  }
}
.quote-bg__top {
  @include x-rem(margin-bottom, 22px);
  @include breakpoint($bp-small) {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    @include x-rem(gap, 33px);
    @include x-rem(margin-bottom, 33px);
  }
}
.quote-bg__company-name {
  margin: 0;
}
.quote-bg__quote {
  font-style: italic;
  letter-spacing: -0.01em;
  p,
  & {
    @include x-rem(font-size, 24px);
    @include x-rem(line-height, 34px);
  }
  p:last-child {
    margin: 0;
  }

  @include breakpoint($bp-small) {
    p,
    & {
      @include x-rem(font-size, 26px);
      @include x-rem(line-height, 36px);
    }
  }
  @include breakpoint($bp-large) {
    p,
    & {
      @include x-rem(font-size, 28px);
      @include x-rem(line-height, 40px);
    }
  }
  @include breakpoint($bp-x-large) {
    p,
    & {
      @include x-rem(font-size, 32px);
      @include x-rem(line-height, 44px);
    }
  }
}
.quote-bg__bottom {
  @include x-rem(margin-top, 20px);
}
.quote-bg__name,
.quote-bg__job-title {
  margin: 0;
  @include x-rem(font-size, 20px);
  @include x-rem(line-height, 26px);
}

.quote-bg__imgbox {
  @include breakpoint($bp-m) {
    display: block;
    width: 100%;
    @include bgImg;
    background-position: 74%;
    @include x-rem(height, 573px);
  }
  @include breakpoint($bp-large) {
    @include x-rem(height, 617px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(height, 834px);
    background-position: center;
  }
  @include breakpoint($bp-xx-large) {
    background-position: initial;
  }
}

// styles for left/right alignment

.quote-bg {
  @include breakpoint($bp-m) {
    .testimonial-slider {
      position: relative;
      z-index: 12;
    }
    &--right {
      .testimonial-slider {
        @include x-rem(max-width, 546px);
        margin-left: auto;
      }

      .quote-bg__box {
        @include x-rem(max-width, 546px);
        margin-left: auto;
      }
    }

    &--left {
      .testimonial-slider {
        @include x-rem(max-width, 462px);
      }
      .quote-bg__box {
        @include x-rem(max-width, 462px);
        margin-right: auto;
      }
    }
  }

  @include breakpoint($bp-large) {
    &--right {
      .testimonial-slider {
        @include x-rem(max-width, 629px);
      }
      .quote-bg__box {
        @include x-rem(max-width, 629px);
      }
    }

    &--left {
      .testimonial-slider {
        @include x-rem(max-width, 562px);
      }
      .quote-bg__box {
        @include x-rem(max-width, 562px);
      }
    }
  }

  @include breakpoint($bp-x-large) {
    &--right {
      .testimonial-slider {
        @include x-rem(max-width, 751px);
      }
      .quote-bg__box {
        @include x-rem(max-width, 751px);
      }
    }

    &--left {
      .testimonial-slider {
        @include x-rem(max-width, 672px);
      }
      .quote-bg__box {
        @include x-rem(max-width, 672px);
      }
    }
  }
}

// slider styles
.quote-bg {
  .testimonials-slider-container {
    width: 100%;
    border-radius: 10px;
    @include x-rem(max-width, 672px);
    max-width: 100%;
  }
  &--left {
    .testimonials-slider-container {
      margin-right: auto;
    }
  }
  &--right {
    margin-left: auto;
  }
}
.quote-bg__box.slick-slide {
  box-shadow: none;
}
.slick-list {
  border-radius: 10px;
}
.testimonial-slider__btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  @include x-rem(margin-top, 24px);

  .slider-button {
    @include resetBtn;
    @include x-rem(height, 80px);
    @include x-rem(width, 80px);
    background: $body-bg;
    border-radius: 50%;
    border: 1px solid $color-gray2;
    box-shadow: $box-shadow1;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &:focus {
      background: $color-yellow;
      border-color: $color-yellow;
    }
  }
  svg {
    @include x-rem(height, 40px);
    @include x-rem(width, 20px);
  }
  .slick-disabled {
    pointer-events: none;
    background: rgba($color-invert, 0.15);
    border: none;
    svg {
      opacity: 0.15;
    }
    &:focus {
      background: rgba($color-invert, 0.15);
    }
  }

  @include breakpoint($bp-m) {
    width: 100%;
    @include x-rem(max-width, 1312px);
    position: absolute;
    left: 50%;
    bottom: 113px;
    transform: translateX(-50%);
    z-index: 33;
    padding: 0 $grid-gutter;
  }
  @include breakpoint($bp-x-large) {
    bottom: 150px;
  }
}
// slider progress bar
.quote-bg {
  .progress-bar-outer {
    width: 100%;
    @include x-rem(max-width, 1312px);
    @include x-rem(margin-top, 36px);
    @include x-rem(margin-bottom, 55px);
  }
  @include breakpoint($bp-m) {
    .progress-bar-outer {
      margin: 0;
      position: absolute;
      bottom: 62px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 33;
      padding: 0 $grid-gutter;
    }
  }
  @include breakpoint($bp-x-large) {
    .progress-bar-outer {
      bottom: 65px;
    }
  }
}
