/* ================================================================
   Variables
   ================================================================ */

// Color Variables
$body-bg: #fff;
$color-base: #000;
$color-invert: #fff;
$color-primary: #003764;
$color-secondary: #97dbff;
$color-yellow: #f3cc23;
$color-black2: #232323;
$color-black3: #232323;
$color-black4: #222222;
$color-black5: #323131;
$color-black6: #414042;
$color-black7: #302f2f;  
$color-black8: #313131;
$color-black9: #353535;
$color-gray: #f0f0f0;
$color-gray2: #f2f2f2;
$color-gray3: #d9d9d9;
$color-gray4: #d9d8d6;
$color-gray5: #98989a;
$color-gray6: #b5b5b7;
$color-gray7: #696969;
$color-gray8: #d8d6d3;
$color-gray9: #d0d2d3;
$color-gray10: #e8e8e8;
$color-link: #00a1d1;
$color-link-heavy: #006ba6;
$color-category-blue: #004b87;
$color-skyblue: #a2d3eb;
$category-green: #00ae42;
$category-red: #cd3741;
$category-yellow: #f3cc23;
$category-blue: #00a1d1;
$category-green2: #00ac4c;
$category-red2: #cc3840;
$category-blue2: #00a0d0;
$category-yellow2: #f2cc23;
$category-green3: #0baa4b;
$category-red3: #cc3840;
$category-blue3: #029dcb;
$category-yellow3: #f3c923;
$category-yellow4: #f4cc25;
$category-red4: #ce3741;
$category-green-hover: #b9daae;
$category-red-hover: #ebafb3;
$category-blue-hover: #9ddaed;
$category-yellow-hover: #faeba8;
$color-sky-blue-gray: #dcf0f8;

$linear-gradient-navy: linear-gradient(328deg, #002849 20.84%, #003764 65.86%);
$linear-gradient-dark: linear-gradient(
  180deg,
  rgba(0, 0, 0, 0) 5.41%,
  rgba(0, 0, 0, 0.7) 59.61%
);

$box-shadow1: 25px 25px 50px rgba(0, 0, 0, 0.15);
$box-shadow2: 0 2px 4px rgba(0, 0, 0, 0.1);

// Typography
$font-primary: "Open Sans", sans-serif;
$font-secondary: Tahoma, Geneva, sans-serif;
$font-heading: $font-primary;

$fontWeight-light: 300;
$fontWeight-normal: 400;
$fontWeight-medium: 500;
$fontWeight-semibold: 600;
$fontWeight-bold: 700;
$fontWeight-extrabold: 800;

$typography-margin-bottom: 1em;

// Breakpoints
// You can convert these at http://pxtoem.com
$bp-s: 20em; // 320px
$bp-s-max: 29.938em; // 479px
$bp-sw: 30em; // 480px
$bp-sw-max: 47.938; // 767px
$bp-m-max: 59.938em; // 959px
$bp-mw: 60em; // 960px
$bp-mw-min: 68.75em; // 1100px
$bp-mw-max: 71.188em; // 1139px
$bp-l: 71.25em; // 1140px
$bp-l-max: 81.188em; // 1299px
$bp-xl: 81.25em; // 1300px

// specific BP
$bp-x-small: 23.4375em; // 375px
$bp-small: 36em; // 576px
$bp-m: 48em; // 768px
$bp-large: 62em; // 992px
$bp-x-large: 75em; // 1200px
$bp-x-large-m: 87.5em; // 1400px
$bp-xx-large: 90em; // 1440px

// Transitions
$trans-duration: 0.18s;
$trans-ease: ease-in-out;
$trans: all $trans-duration $trans-ease;

// Grid
$container-size: 84.5rem; // 1352px
$container-size-medium: 70.5rem; //1128px
$container-size-paragraph: 72.125rem; //1154px
$container-size-x-small: 60.5em; //968px
$container-size-small: 64.5rem; // 1032px
$container-size-large: 117.5rem; // 1880px
$grid-gutter: 20px;
$grid-gutter-em: 0.938em; // Changing grid gutter to em causes a compiling error :/
