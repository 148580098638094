.blank-banner {
  @include x-rem(padding, 124px 0 23px 0);
  @include breakpoint($bp-large) {
    @include x-rem(padding, 184px 118px);
  }

  &--detail-page {
    padding: 0;
    @include x-rem(padding-top, 32px);
  }
}
.blank-banner__content {
  text-align: center;
}
.standard-banner__title {
  @include x-rem(margin-top, 40px);
  @include x-rem(margin-bottom, 17px);
  @include breakpoint($bp-large) {
    @include x-rem(margin-bottom, 32px);
  }
}
.standard-banner__text {
  p:last-child {
    margin: 0;
  }
  p {
    @include x-rem(font-size, 20px);
    @include x-rem(line-height, 36px);
    color: $color-primary;
    font-weight: $fontWeight-bold;
  }
}
