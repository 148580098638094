.hero-sbs {
  @extend .bottom-curve;
  z-index: 2;
  background: $linear-gradient-navy;
  padding: 158px 0 55px;

  @include breakpoint($bp-large) {
    @include x-rem(padding, 128px 0 100px 0);
    min-height: 701px;
  }
}
.hero-sbs__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 38px;
  position: relative;
  z-index: 3;
  @include breakpoint($bp-m) {
    flex-direction: row;
  }
}
.hero-sbs__textbox {
  color: $color-invert;
  text-align: center;
}
.hero-sbs__title {
  margin-bottom: 26px;
  color: $color-invert;
}
.hero-sbs__subtitle {
  color: $color-invert;
  margin: 0;
  @include x-rem(font-size, 26px);
}
.hero-sbs__imgbox {
  @include breakpoint($bp-large) {
    flex-shrink: 0;
  }
}
