.disclaimer-box {
  text-align: center;
  & > * {
    @include x-rem(font-size, 24px);
    @include x-rem(line-height, 36px);
    letter-spacing: -0.01em;
  }
  p:last-child {
    margin: 0;
  }
  @include breakpoint(62.5em) {
    & > * {
      @include x-rem(font-size, 32px);
      @include x-rem(line-height, 44px);
    }
  }
}
