.blog-stats {
  @include resetList;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.blog-stats--4col {
  gap: 36px;
  @include breakpoint($bp-m) {
    gap: 20px 85px;
  }
}
.blog-stats--3col {
  gap: 15px;
  @include breakpoint($bp-small) {
    gap: 40px;
  }
}
.blog-stats__item {
  width: 100%;
  display: flex;
  @include breakpoint($bp-small) {
    flex-direction: column;
    width: calc(50% - 36px);
    @include breakpoint($bp-m) {
      width: calc(50% - 85px);
    }
  }
}
.blog-stats--4col .blog-stats__item {
  gap: 20px;
  @include breakpoint($bp-m) {
    gap: 25px;
  }
}
.blog-stats--3col .blog-stats__item {
  align-items: center;
  gap: 15px;
  &:last-child {
    width: 100%;
  }
  @include breakpoint($bp-small) {
    flex-direction: row;
    width: calc(50% - 40px);
  }
  @include breakpoint($bp-x-large) {
    width: calc(33.3333333% - 40px);
    &:last-child {
      width: calc(33.3333333% - 40px);
    }
  }
}
.blog-stats__icon {
  width: 112px;
  height: 112px;
}
.blog-stats__text {
  margin: 0;
}
