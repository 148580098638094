.comparison-cards2 {
  @include resetList;
  display: flex;
  flex-wrap: wrap;
  @include x-rem(gap, 5px);
  justify-content: center;
}
.comparison-cards2__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;

  @include breakpoint($bp-small) {
    width: calc(50% - 3px);
  }

  @include breakpoint($bp-large) {
    width: calc(33.33333% - 4px);
  }

  @include breakpoint($bp-x-large) {
    width: calc(20% - 4px);
  }
}
.comparison-cards2__top {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-primary;
  text-align: center;
  padding: 20px;
  flex-shrink: 0;
  font-weight: $fontWeight-bold;
  @include breakpoint($bp-x-large) {
    min-height: 84px;
  }
  p {
    margin: 0;
    color: $color-invert;
    line-height: 22px;
  }
}
.comparison-cards2__logo,
.comparison-cards2__text {
  background: $body-bg;
  border: solid 1px $color-gray10;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0.5rem;
  min-height: 152px;

  img {
   max-height: 55px;
  }
}
.comparison-cards2__text {
  @include x-rem(font-size, 15px);
  line-height: normal;
  text-align: center;
}
