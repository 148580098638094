$blog-nav-bp: 62.5em;

.blog-navigation-box {
  background: $body-bg;
  width: 100%;
  min-height: 85px;
  position: relative;
  @include x-rem(padding, 28px 70px);
  @include breakpoint($blog-nav-bp) {
    background: transparent;
    padding: 0;
    width: fit-content;
    min-height: initial;
  }
  &.fixed {
    position: fixed;
    top: 85px;
    left: 0;
    z-index: 3;
    @include breakpoint($blog-nav-bp) {
      top: 150px;
      left: initial;
    }
  }
  &.slim-out {
    @include breakpoint($blog-nav-bp) {
      width: 10%;
    }
  }
}
.blog-navigation {
  @include resetList;
}
.blog-navigation__item {
  margin-right: 40px;
  margin-left: 12px;
  @include breakpoint($blog-nav-bp) {
    margin: 0 0 30px 0;
  }
}
.blog-navigation__link {
  position: relative;
  text-decoration: none;
  @include x-rem(padding, 4px 0);
  color: $color-primary;
  display: inline-block;
  font-weight: $fontWeight-bold;
  @include x-rem(font-size, 18px);
  line-height: normal;
  letter-spacing: -0.3px;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    background: $color-yellow;
    transition: 0.3s all ease-out;
  }
  &:hover,
  &:focus,
  &.active {
    &:before {
      width: 100%;
    }
  }
  @include breakpoint($blog-nav-bp) {
    @include x-rem(font-size, 20px);
    @include x-rem(padding, 2px 0);
  }
}

// nav slider styles

.nav-slider__btn-wrapper {
  .slider-button {
    @include resetBtn;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &:hover,
    &:focus {
      opacity: 0.4;
    }
  }
  .slider-button--left {
    left: 23px;
  }
  .slider-button--right {
    right: 23px;
  }
  .slider-button__svg {
    fill: $color-primary;
    width: 15px;
    height: 30px;
  }
  .slider-button {
    &:hover,
    &:focus {
      fill: $color-yellow;
    }
  }
  .slick-disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  @include breakpoint($blog-nav-bp) {
    .slider-button {
      display: none;
    }
  }
}
