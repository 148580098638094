// 2 circle pattern

.two-circle-pattern {
  svg {
    position: absolute;
    z-index: 1;
  }
  &--dashed {
    left: 30px;
    top: 55px;
    @include x-rem(height, 115px);
    @include x-rem(width, 115px);
  }
  &--opaque {
    top: 240px;
    left: 0;
    @include x-rem(height, 348px);
    @include x-rem(width, 348px);
  }
  @include breakpoint($bp-m) {
    &--dashed {
      top: 145px;
    }
    &--opaque {
      top: 330px;
    }
  }
}
