.hero {
  @extend .bottom-curve;
  background-image: url("../images/shapes/hero-curve-blue.png");
  @include bgImg;
  background-position: bottom;
  @include x-rem(padding, 128px 0 53px 0);

  @include breakpoint($bp-m) {
    background-image: none;
    @include x-rem(min-height, 780px);
  }
  @include breakpoint($bp-large) {
    @include x-rem(min-height, 790px);
  }
  @include breakpoint(70.125em) {
    @include x-rem(min-height, 840px);
  }
  @include breakpoint($bp-x-large) {
    &.hero--bump { 
    }
  }

  // force anchor tag to have spacing between text + underline for rich text

  a {
    text-decoration: underline solid currentColor 2px;
    text-underline-offset: 9px;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  .btn {
    text-decoration: none;
  }
}

// hero styles dependant on chosen layout

.hero {
  @include breakpoint($bp-m) {
    &--right {
      .hero__content {
        margin-left: auto;
      }
      .hero__fg {
        left: -40px;
      }
    }
    &--left {
      .hero__content {
        margin-right: auto;
      }

      .hero__fg {
        width: fit-content;
        margin-left: auto;
        right: -285px;
        top: -60px;
      }
    }
  }
  @include breakpoint($bp-large) {
    &--left {
      .hero__fg {
        right: -215px;
      }
    }
  }
}

.hero {
  @include breakpoint($bp-x-large) {
    &--right {
      .hero__content {
        @include x-rem(max-width, 530px);
      }
    }
    &--left {
      .hero__content {
        @include x-rem(max-width, 640px);
      }
    }
  }
}

.hero {
  @include breakpoint($bp-xx-large) {
    &--right {
      .hero__bg {
        background-position: initial;
      }
    }
    &--left {
      .hero__bg {
        background-position: initial;
      }
    }
  }
}
.hero__bg {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  @include bgImg;

  @include breakpoint($bp-m) {
    display: block;
  }
}

.hero__content {
  text-align: center;
  position: relative;
  z-index: 3;
  width: 100%;
  @include breakpoint($bp-m) {
    @include x-rem(max-width, 354px);
    text-align: left;
  }
  @include breakpoint($bp-large) {
    @include x-rem(max-width, 463px);
  }
  @include breakpoint($bp-x-large) {
    &.hero__content--bump {
        top: 20px;
    }
  }
}
.hero__headline {
  @include x-rem(margin-bottom, 13px);
  color: $color-invert;
  @include x-rem(font-size, 18px);
  @include x-rem(line-height, 25px);
  font-weight: $fontWeight-bold;
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 16px);
    @include x-rem(font-size, 20px);
    @include x-rem(line-height, 27px);
  }
}
.hero__title {
  color: $color-invert;
  @include x-rem(margin-bottom, 44px);
}
.hero__text,
.hero__additional-content {
  @include x-rem(margin-bottom, 32px);
  color: $color-invert;

  p,
  ol,
  ul {
    color: $color-invert;
  }
  a:not(.btn) {
    color: $color-invert;
  }
}
.hero__fg {
  display: none;
  @include breakpoint($bp-m) {
    display: block;
    position: relative;
  }
}

.hero__text {
  @include x-rem(margin-bottom, 12px);
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 32px);
  }
}
.hero__cta {
  @include x-rem(padding, 16px 32px);
  @include x-rem(min-width, 181px);
}

.hero__additional-content {
  @include x-rem(margin-top, 16px);
  @include breakpoint($bp-m) {
    @include x-rem(margin-top, 32px);
    @include x-rem(max-width, 411px);
  }
  & > * {
    font-size: 1rem;
    @include x-rem(line-height, 28px);
  }
}

// location dropdown hero
.hero__select-wrapper {
  width: 100%;
  @include x-rem(margin-top, 44px);
  position: relative;

  @include breakpoint($bp-m) {
    @include x-rem(max-width, 304px);
  }
}

.hero__select {
  width: 100%;
  background: $color-yellow;
  @include x-rem(padding, 16px 25px 16px 25px);
  @include x-rem(height, 55px);
  color: $color-black5;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @include x-rem(gap, 10px);
  text-align: left;

  &.active {
    outline: none;
  }
}

.hero__select-icon {
  @include x-rem(height, 18px);
  @include x-rem(width, 18px);

  &--up {
    display: none;
  }
}
.hero__select.active {
  .hero__select-icon--up {
    display: flex;
  }
  .hero__select-icon--down {
    display: none;
  }
}
.location-listing1 {
  @include resetList;
  position: absolute;
  background: $body-bg;
  width: 100%;
}
.location-listing1__link {
  display: block;
  @include x-rem(padding, 16px 25px 14px 25px);
  text-decoration: none;
  border: 3px solid transparent;
  font-size: 1rem;
  font-weight: $fontWeight-semibold;
  color: $color-black5;
  text-align: left;

  &:hover,
  &:focus {
    background: $color-gray;
    border: 3px solid #97dbff;
  }
}
