
.timeline {
    margin-bottom: 3rem;
    transition: all ease 1s !important;

    @include breakpoint (l) {
        max-width: 68.75rem;
        margin: 0 auto 10rem;
    }

    @include breakpoint (xl) {
        max-width: 84.5rem;
    }

    &__item {
        width: 100%;
        padding: 1.5rem 2rem;
        border: 1px solid $color-gray2;
        border-radius: 10px;
        position: relative;
        margin-bottom: 10rem;

        @include breakpoint (l) {
            @include x-rem(max-width, 470px);
            margin-bottom: 0rem;
        }

        @include breakpoint (xl) {
            @include x-rem(max-width, 560px);
        }

        &:before {
            @include pseudo;
            height: 60px;
            width: 30px;
            border-radius: 0 150px 150px 0;
            background-color: $color-gray4;
            top: calc(100% - 15px);
            left: 50%;
            transform: translateX(-50%) rotate(90deg);

            @include breakpoint(l) {
                top: 50%;
                left: 100%;
                transform: translateY(-50%);
            }
        }

        &:after {
            @include breakpoint(l) {
                @include pseudo;
                width: 51px;
                height: 2px;
                background: $color-gray4;
                top: 50%;
                left: calc(100% + 30px);
                transform: translateY(-50%);
            }
        }

        .vertical-line {
            @include pseudo;
            width: 2px;
            background: $color-gray4;
            top: calc(100% + 30px);
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            transition: all ease .5s;

            @include breakpoint(l) {
                top: 50%;
                height: 150%;
                left: calc(100% + 80px);
            }


        }

        &:nth-child(even) {
            @include breakpoint(l) {
                left: calc(470px + 160px);
                margin: -50px 0;

                &:before {
                    right: 100%;
                    left: auto;
                    transform: translateY(-50%) rotate(180deg);
                }

                &:after {
                    right: calc(100% + 30px);
                    left: auto;
                }

                .vertical-line {
                     left: auto;
                     right: calc(100% + 80px);
                 }
            }

            @include breakpoint(xl) {
                left: calc(560px + 160px);
            }
        }

        &:last-child {
            &:before,
            &:after {
                display: none;
            }

            .vertical-line {
                height: 1px !important; // Override the height added by the JS code for the last item.
            }

            @include breakpoint(l) {
                &:before,
                &:after {
                    display: block;
                }
            }
        }

        &.highlight {
            box-shadow: 5px 20px 10px 0px rgba(0, 0, 0, 0.1);
        }

        &.active {
            border-color: $color-primary;

            &:before,
            &:after,
            .vertical-line {
                background-color: $color-primary;
            }

            .vertical-line {
                z-index: 2;
            }

            &.highlight {
                .vertical-line {
                    background-color: $color-gray4;
                }
            }
        }
    }

    &__year {
        margin: 0;
        text-align: center;
    }

    &__subheader {
        display: block;
        text-align: center;
        @include x-rem(font-size, 20px);
        font-weight: $fontWeight-bold;
        margin-bottom: .75rem;
    }

    &__content {
        margin: 0;
        @include x-rem(font-size, 16px);
        line-height: 26px;
    }

    ul {
        @include x-rem(font-size, 16px);
    }
}