.upcoming-listing {
  @include resetList;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @include x-rem(gap, 30px);
  @include breakpoint($bp-m) {
    @include x-rem(gap, 21px);
    margin: 0 auto;
  }

  @include breakpoint($bp-large) {
    grid-template-columns: repeat(2, 1fr);
    @include x-rem(row-gap, 64px);
  }
} 
.upcoming-listing__item {
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 27px);
  @include breakpoint($bp-x-small) {
    flex-direction: row;
  }
  @include breakpoint($bp-small) {
    @include x-rem(gap, 32px);
  }
}
.upcoming-listing__date-box {
  border-radius: 50%;
  @include x-rem(height, 100px);
  @include x-rem(width, 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-skyblue;
  flex-shrink: 0;
  @include breakpoint($bp-m) {
    @include x-rem(height, 112px);
    @include x-rem(width, 112px);
  }
}
.upcoming-listing__date {
  margin: 0;
  font-weight: $fontWeight-bold;
  @include x-rem(font-size, 20px);
  @include x-rem(line-height, 27px);
  color: $color-primary;
}
.upcoming-listing__content-box {
}
.upcoming-listing__link {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
.upcoming-listing__title {
  @include x-rem(margin-bottom, 7px);
  font-weight: $fontWeight-bold;
  @include x-rem(font-size, 20px);
  @include x-rem(line-height, 27px);

  @include breakpoint($bp-large) {
    @include x-rem(margin-bottom, 13px);
  }
}
.upcoming-listing__text {
  margin: 0;
  @include x-rem(font-size, 20px);
  @include x-rem(line-height, 24px);
}
.upcoming-date-box__disclaimer {
  @include x-rem(margin-top, 77px);
  @include x-rem(margin-bottom, 41px);
  text-align: center;

  @include breakpoint($bp-m) {
    @include x-rem(margin-top, 107px);
    @include x-rem(margin-bottom, 35px);
  }
}

.upcoming-date-box__cta {
  text-align: center;
}
