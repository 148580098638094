.ebook-details {
  @include x-rem(padding, 32px 0 205px 0);
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 77px);

  @include breakpoint($bp-m) {
    @include x-rem(padding-bottom, 305px);
  }
  @include breakpoint($bp-large) {
    @include x-rem(padding, 64px 0 393px 0);
    flex-direction: row;
    align-items: flex-start;
    @include x-rem(gap, 34px);
  }
}
.ebook-details__col1,
.ebook-details__col2 {
  flex: 1;
}

.ebook-details__imgbox {
  @include x-rem(margin-bottom, 35px);
  text-align: center;

  @include breakpoint($bp-small) {
    text-align: left;
  }
}

.ebook-details__col2 {
  border: 1px solid $color-gray4;
  box-shadow: $box-shadow1;
  border-radius: 10px;
  @include x-rem(padding, 32px 20px 42px 20px);

  @include breakpoint($bp-m) {
    @include x-rem(padding, 32px 32px 52px 32px);
  }
}
.ebook-details__col2__required {
  font-size: 1rem;
}
.ebook-details__title {
  @include x-rem(font-size, 32px);
  @include x-rem(line-height, 44px);
  letter-spacing: -0.01em;
  @include x-rem(margin-bottom, 32px);
  text-align: center;
}

// download form
.ebook-details__col2 form[id] {
  .form-field {
    position: relative;
    @include x-rem(margin-bottom, 15px);
  }
  label.control-label {
  }
  input,
  select {
    background: $body-bg;
    border: 1px solid $color-gray5;
    @include x-rem(padding, 15px 13px);
    @include x-rem(height, 55px);
  }
  .ktc-checkbox {
    a,
    label {
      line-height: normal;
      @include x-rem(font-size, 16px);
    }
  }
  a {
    @extend .heavy-link;
    text-underline-offset: initial;
  }
  .ktc-radio.ktc-radio-list {
    display: flex;
    @include x-rem(gap, 35px);
    @include x-rem(padding-bottom, 32px);
    border-bottom: 1px solid $color-gray4;
    @include x-rem(margin-bottom, 21px);
  }
  .ktc-radio,
  .ktc-checkbox {
    display: flex;
    align-items: center;
    @include x-rem(gap, 10px);
  }
  input[type="radio"],
  input[type="checkbox"] {
    margin: 0;
    @include x-rem(height, 23px);
    @include x-rem(width, 23px);
  }
  input[type="checkbox"] {
    position: relative;
    top: 5px;
  }
  .ktc-radio label {
    margin: 0;
  }
  .ktc-checkbox {
    align-items: flex-start;
   
  }
  input[type="radio"] {
  }
  input[type="submit"] {
    @extend .btn;
    @extend .primary-button;
    @include x-rem(min-width, 180px);
    display: block;
    @include x-rem(margin, 34px auto 12px auto);
  }
  @include breakpoint($bp-large) {
    .form-field {
      @include x-rem(margin-bottom, 18px);
    }
    .ktc-radio.ktc-radio-list {
      @include x-rem(gap, 64px);
    }
    .ktc-radio,
    .ktc-checkbox {
      @include x-rem(gap, 16px);
    }
    input[type="submit"] {
      @include x-rem(min-width, 190px);
      @include x-rem(margin, 32px auto 12px auto);
    }
  }
}
