.roi-table-box {
  width: 100%;
  //   @include x-rem(max-width, 752px);
  margin-left: auto;
  position: relative;
  z-index: 223;

  @include breakpoint($bp-m) {
    @include x-rem(max-width, 463px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(max-width, 752px);
  }
}
.roi-table {
  border-radius: 10px;
  border: 1px solid $color-gray4;
  background: $body-bg;
  box-shadow: $box-shadow1;

  p {
    margin: 0;
  }
}
.roi-listing {
  @include resetList;
}
.roi-listing__item {
  display: flex;
  position: relative;
  &:before {
    content: "";
    display: block;
    height: 1px;
    width: 86%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 43;
    background: $color-gray4;

    @include breakpoint(31.25em) {
      width: 95%;
    }
  }
  &--last {
    &:before {
      content: none;
    }
  }
}
.roi-listing__box1 {
  width: 100%;
  @include x-rem(max-width, 90px);
  background: $color-gray2;
  @include x-rem(padding, 14px 11px);
  position: relative;
  z-index: 33;
  transform: scale(1.02);
  &--first {
    border-top-left-radius: 10px;
  }
  &--last {
    border-bottom-left-radius: 10px;
  }
}
.roi-listing__box2 {
  @include x-rem(padding, 14px 16px);
}
.roi-listing__th {
  @include x-rem(font-size, 18px);
  font-weight: $fontWeight-bold;
  text-align: right;
  color: $color-primary;
  @include breakpoint($bp-m){
    @include x-rem(font-size, 20px);
  }
}
.roi-listing__th2 {
  @include x-rem(font-size, 16px);
  @include x-rem(line-height, 24px);
  font-weight: $fontWeight-bold;
  text-align: right;
  color: $color-black5;
}
.roi-listing__td {
  text-align: left;
  @include x-rem(font-size, 14px);
  @include x-rem(line-height, 22px);
  color: $color-black5;

  @include breakpoint($bp-m) {
    @include x-rem(line-height, 24px);
  }
}
.roi-table__caption {
  margin: 0;
  text-align: center;
  @include x-rem(margin, 30px 0);
  @include x-rem(font-size, 16px);
  @include x-rem(line-height, 22px);
  font-style: italic;
  color: $color-black5;
  @include breakpoint($bp-m) {
    @include x-rem(line-height, 24px);
  }
}
