.light-blue-banner {
  position: relative;
  overflow: hidden;
  background: $color-skyblue;
  @include x-rem(padding-top, 66px);
  @include x-rem(padding-bottom, 142px);
  @include x-rem(min-height, 521px);

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
    display: block;
    @include bgImg;
    background-size: contain;
    width: 100%;
    @include x-rem(height, 398px);
  }
  &:before {
    top: -130px;
    background-image: url("/assets/dist/images/ellipse-top.png");
  }
  &:after {
    bottom: -90px;
    background-image: url("/assets/dist/images/ellipse.png");
  }
  @include breakpoint($bp-small) {
    @include x-rem(padding-bottom, 189px);
    @include x-rem(padding-top, 130px);
    &:before {
      top: -231px;
      background-image: url("/assets/dist/images/ellipse.png");
    }
  }

  .light-blue-banner__wrapper {
    display: flex;
    align-items: flex-start;
    @include x-rem(gap, 40px);
    width: 100%;
    @include breakpoint($bp-x-large) {
      align-items: center;
    }
  }
  @include breakpoint($bp-m) {
    @include x-rem(padding-top, 84px);
    @include x-rem(padding-bottom, 289px);
    &:before,
    &:after {
      transform: initial;
      background-position: left;
      left: 56px;
    }
    &:before {
      top: -190px;
    }
    &:after {
      bottom: 60px;
    }
  }

  @include breakpoint($bp-x-large) {
    @include x-rem(padding-top, 36px);
    @include x-rem(padding-bottom, 224px);
    &:before,
    &:after {
      left: 96px;
    }
  }
}
.light-blue-banner__profiles {
  display: none;
  position: relative;
  z-index: 3;
  @include breakpoint($bp-m) {
    display: flex;
    width: 50%;
  }
}
.circle-profiles {
  @include resetList;
  display: flex;
  flex-direction: column;
}

.circle-profiles__item {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  background: $body-bg;
  border: 3px solid $category-red;
  @include x-rem(height, 115px);
  @include x-rem(width, 115px);
  box-shadow: 25px 25px 50px 0px rgba(0, 0, 0, 0.25);

  &--red {
    border-color: $category-red;
    left: 60px;
  }
  &--blue {
    border-color: $category-blue;
    top: -45px;
  }
  &--yellow {
    border-color: $category-yellow;
    left: 219px;
    top: -30px;
  }

  @include breakpoint($bp-x-large) {
    @include x-rem(height, 160px);
    @include x-rem(width, 160px);

    &--red {
      left: 60px;
    }
    &--blue {
      top: -45px;
    }
    &--yellow {
      left: 285px; 
    }
  }
}
.circle-profiles__img {
  @extend .fit-image;
  position: absolute;
  bottom: 0;
  right: 0;
}
.light-blue-banner__textbox {
  text-align: center;
  position: relative;
  z-index: 3;
  @include breakpoint($bp-m) {
    text-align: left;
    width: 50%;
    @include x-rem(padding-top, 10px);
  }
  @include breakpoint($bp-x-large) {
    padding-top: 0;
  }
}
.light-blue-banner__title {
  @include x-rem(margin-bottom, 55px);
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 35px);
  }
}
