//Sticky Header On Scroll Up Defaulted to mobile only

.sticky-header {
  position: absolute;
  width: 100%;
  z-index: 4;
  transition: $trans;
}
.active-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: $body-bg;
  a,
  [data-expander-toggle] {
    color: $color-base !important;
  }
  a.main-navigation__top-lvl,
  a.culture-selector__link {
    &:hover,
    &:focus {
      color: $color-primary !important;
    }
  }
  svg {
    fill: $color-base;
  }
  .ghost-white {
    outline-color: $color-base;
    &:hover,
    &:focus {
      outline-color: $color-yellow;
    }
  }
}
.not-transparent-active-sticky {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: $body-bg;
  a,
  [data-expander-toggle] {
    color: $color-base !important;
  }
  a.main-navigation__top-lvl,
  a.culture-selector__link {
    &:hover,
    &:focus {
      color: $color-primary !important;
    }
  }
  svg {
    fill: $color-base;
  }
  .ghost-white {
    outline-color: $color-base;
    &:hover,
    &:focus {
      outline-color: $color-yellow;
    }
  }
}
// admin only styles
.admin-container {
  .sticky-header {
    background: $body-bg;
    position: relative;
    @include x-rem(margin-bottom, 20px);
    .header-logo__svg--dark {
      opacity: 1;
    }
    .header-logo__svg--light {
      opacity: 0;
    }
    a,
    [data-expander-toggle] {
      color: $color-base !important;
    }
    svg {
      fill: $color-base;
    }
    .ghost-white {
      outline-color: $color-base;
      &:hover,
      &:focus {
        outline-color: $color-yellow;
      }
    }
  }
}
