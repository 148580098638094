.quadrants-board-box {
}
.quadrants-board-box__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include x-rem(gap, 32px);
  @include x-rem(margin, 50px 0);

  @include breakpoint($bp-m) {
    flex-direction: row;
    @include x-rem(gap, 23px);
    @include x-rem(margin, 80px 0);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(gap, 48px);
  }
}

.quadrants-board {
  font-size: 0;
  @include x-rem(width, 352px);
  @include x-rem(height, 352px);

  @include breakpoint($bp-large) {
    @include x-rem(width, 460px);
    @include x-rem(height, 460px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(width, 600px);
    @include x-rem(height, 600px);
  }
}

.quadrants-board__quadrant {
  @include x-rem(width, 175px);
  @include x-rem(height, 175px);
  display: inline-block;
  border: 1px solid $color-gray6;
  @include resetList;
  cursor: pointer;
  background: $color-gray2;
  position: relative;
  &:hover,
  &:focus,
  &.active,
  &.open {
    outline: none;
    &.quadrants-board__quadrant--d {
      background: $category-green2;
    }
    &.quadrants-board__quadrant--i {
      background: $category-red2;
    }
    &.quadrants-board__quadrant--c {
      background: $category-yellow2;
    }
    &.quadrants-board__quadrant--s {
      background: $category-blue2;
    }
  }
  @include breakpoint($bp-large) {
    @include x-rem(width, 228px);
    @include x-rem(height, 228px);
  }

  @include breakpoint($bp-x-large) {
    @include x-rem(width, 298px);
    @include x-rem(height, 298px);
  }
}
.quadrants-board__top-left {
  border-top-left-radius: 100%;
  border-width: 4px 2px 2px 4px;
}

.quadrants-board__top-right {
  border-top-right-radius: 100%;
  border-width: 4px 4px 2px 2px;
}
.quadrants-board__bottom {
  position: relative;
}

.quadrants-board__bottom-left {
  border-bottom-left-radius: 100%;
  border-width: 2px 2px 4px 4px;
}

.quadrants-board__bottom-right {
  border-bottom-right-radius: 100%;
  border-width: 2px 4px 4px 2px;
}
.quadrants-board__svg {
  position: absolute;
  &--active {
    display: none;
  }
}

.quadrants-board__quadrant--d {
  .quadrants-board__svg {
    @include x-rem(width, 44px);
    @include x-rem(height, 58px);
    @include breakpoint($bp-x-large) {
      bottom: 56px;
      @include x-rem(width, 75px);
      @include x-rem(height, 98px);
    }
  }
}
.quadrants-board__quadrant--i {
  .quadrants-board__svg {
    right: 95px;
    @include x-rem(width, 12px);
    @include x-rem(height, 58px);

    @include breakpoint($bp-x-large) {
      @include x-rem(width, 20px);
      @include x-rem(height, 98px);
      right: 163px;
      bottom: 56px;
    }
  }
}
.quadrants-board__quadrant--c {
  .quadrants-board__svg {
    top: 35px;
    @include x-rem(width, 46px);
    @include x-rem(height, 60px);
    @include breakpoint($bp-x-large) {
      @include x-rem(width, 79px);
      @include x-rem(height, 101px);

      top: 60px;
    }
  }
}
.quadrants-board__quadrant--s {
  .quadrants-board__svg {
    right: 81px;
    top: 35px;
    @include x-rem(width, 42px);
    @include x-rem(height, 57px);

    @include breakpoint($bp-x-large) {
      @include x-rem(width, 71px);
      @include x-rem(height, 97px);
      right: 128px;
      top: 60px;
    }
  }
}

.quadrants-board__quadrant--c,
.quadrants-board__quadrant--s {
  position: absolute;
}
.quadrants-board__quadrant--s {
  right: 2px;
  @include breakpoint($bp-large) {
    right: 4px;
  }
}
.quadrants-board__quadrant {
  &.active,
  &:hover,
  &:focus,
  &.open {
    .quadrants-board__svg--active {
      display: initial;
    }
    .quadrants-board__svg--inactive {
      display: none;
    }
  }
}
.quadrants-popup-box__item {
  background: $body-bg;
  box-shadow: 25px 25px 50px 0px rgba(0, 0, 0, 0.15);
  width: 100%;

  @include x-rem(padding, 20px 20px 25px 20px);
  border-radius: 10px;
  p {
    margin: 0;
    line-height: 24px;
  }
  @include breakpoint($bp-m) {
    @include x-rem(padding, 24px 30px 18px 30px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(max-width, 432px);
  }
  &--d {
    border: 2px solid $category-green2;
  }
  &--i {
    border: 2px solid $category-red2;
  }
  &--s {
    border: 2px solid $category-yellow2;
  }
  &--c {
    border: 2px solid $category-blue2;
  }
  &.open {
    display: flex;
  }
}

// .multi-quadrant-circle-target__item, .quadrants-popup-box__item, .quadrants-board__quadrant{
//   &.active {
//     display: none;
//   }
 
// }
// .multi-quadrant-circle-target__item, .quadrants-popup-box__item, {
//   &.open{
//     display: flex;
//   }

  
// }