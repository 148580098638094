$infographic-blue: #c8eafb;

// infographic box
.blog-infographic {
  @include x-rem(padding, 40px 30px);
  box-shadow: $box-shadow1;
  border-radius: 14px;

  &--red {
    background: $category-red;
    color: $color-invert;
  }
  &--blue {
    background: $infographic-blue;
    .blog-infographic__text,
    .blog-infographic__title {
    }
    color: $color-invert;
  }
  &--yellow {
    background: $category-yellow;
  }
  &--green {
    background: $category-green;
    color: $color-invert;
  }
}
.blog-infographic__title {
  p.text-white {
    line-height: normal;
  }
}
