/* ================================================================
Base styles
================================================================ */

.site-container {
  min-height:calc(100vh - 180px);
}

img[width="1"][height="1"] {
    display: none;
}

.section-container {
  padding: 30px 0;
  position: relative;

  &--padding-small {
    padding: 15px 0;

    &.section-container--padding-top {
      padding: 15px 0 0;
    }
    &.section-container--padding-bottom {
      padding: 0 0 15px;
    }
  }

  &--padding-medium {
    padding: 40px 0;

    &.section-container--padding-top {
      padding: 40px 0 0;
    }
    &.section-container--padding-bottom {
      padding: 0 0 40px;
    }
  }

  &--padding-large {
    padding: 50px 0;

    &.section-container--padding-top {
      padding: 50px 0 0;
    }
    &.section-container--padding-bottom {
      padding: 0 0 50px;
    }
  }
  &--padding-lopsided {
    padding: 95px 0 65px 0;

    &.section-container--padding-top {
      padding: 95px 0 0;
    }
    &.section-container--padding-bottom {
      padding: 0 0 65px;
    }
  }
  &--curve-padding {
    padding: 70px 0;
    &.section-container--padding-top {
      padding: 70px 0 0;
    }
    &.section-container--padding-bottom {
      padding: 0 0 70px;
    }
  }

  &--no-padding {
    padding: 0;
  }

  &--white {
    background-color: $body-bg;
  }
  &--gray {
    background-color: $color-gray2;
  }

  &--navy {
    background-color: $color-primary;
    @extend .whiten-text;
  }
  &--bottom-curve {
    @include x-rem(padding-top, 45px);
    @include x-rem(padding-bottom, 68px);
    background-image: url("../images/shapes/gray-curve.png");
    @include bgImg;
  }
  &--top-curve {
    @include x-rem(padding-top, 72px);
    background-image: url("../images/shapes/gray-curve-top.png");
    @include bgImg;
  }
  &--blue-bottom-curve {
    @include x-rem(padding-top, 56px);
    @include x-rem(padding-bottom, 55px);
    background-image: url("../images/shapes/blue-curve-bottom.png");
    @include bgImg;
    @extend .whiten-text;
  }
}

@media (min-width: $bp-m) {
  .section-container {
    padding: 50px 0;

    &--padding-small {
      padding: 20px 0;

      &.section-container--padding-top {
        padding: 20px 0 0;
      }
      &.section-container--padding-bottom {
        padding: 0 0 20px;
      }
    }

    &--padding-medium {
      padding: 88px 0;

      &.section-container--padding-top {
        padding: 88px 0 0;
      }
      &.section-container--padding-bottom {
        padding: 0 0 88px;
      }
    }

    &--padding-large {
      padding: 100px 0;

      &.section-container--padding-top {
        padding: 100px 0 0;
      }
      &.section-container--padding-bottom {
        padding: 0 0 100px;
      }
    }

    &--padding-lopsided {
      padding: 150px 0 100px 0;

      &.section-container--padding-top {
        padding: 150px 0 0;
      }
      &.section-container--padding-bottom {
        padding: 0 0 100px;
      }
    }

    &--curve-padding {
      padding: 150px 0;
      &.section-container--padding-top {
        padding: 150px 0 0;
      }
      &.section-container--padding-bottom {
        padding: 0 0 150px;
      }
    }

    &--no-padding {
      padding: 0;
    }
    &--bottom-curve {
      @include x-rem(padding-top, 88px);
      @include x-rem(padding-bottom, 118px);
      background-position: bottom;
    }

    &--top-curve {
      @include x-rem(padding-top, 150px);
      background-image: url("../images/shapes/gray-curve-top.png");
      background-position: initial;
    }
    &--blue-bottom-curve {
      @include x-rem(padding-top, 64px);
      @include x-rem(padding-bottom, 64px);
      background-position: bottom;
    }
  }
}

// section curves
.section-curves {
  &:before,
  &:after {
    position: absolute;
    @include bgImg;
    z-index: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &:before {
    background-image: url("../images/shapes/topcurve.png");
    background-position: top;
    top: 0;
  }

  &:after {
    background-image: url("../images/shapes/bottomcurve.png");
    background-size: cover;
    background-position: bottom;
    bottom: -1px;
  }

  &--curve-both {
    // padding: 150px 0 !important;
    &:before,
    &:after {
      content: "";
    }
  }
  &--curve-top {
    &:before {
      content: "";
    }
  }

  &--curve-bottom {
    &:after {
      content: "";
    }
  }
  &--inward {
  }
  &--outward {
  }
  &--no-curve {
    &:before,
    &:after {
      content: none;
    }
  }
}

// making buttons clickable on admin
.admin-container {
  .section-curves {
    &--curve-bottom,
    &--curve-top {
      &:before,
      &:after {
        z-index: -12312;
      }
    }
  }
}

// gray curve offset section
.gray-curve-section {
  position: relative;
  z-index: 3;
  background: $color-gray2;
  scroll-margin-top: 500px;
  @include breakpoint($bp-m) {
    scroll-margin-top: 400px;
  }

  &:before {
    content: "";
    position: absolute;
    @include bgImg;
    z-index: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("../images/shapes/topcurve.png");
    background-position: top;
    top: 0;
  }
}
.gray-curve-section__wrapper {
  position: relative;
  z-index: 4;
}
.gray-curve-section__top {
  position: relative;
  @include x-rem(margin-top, 557px);
  @include x-rem(margin-bottom, -480px);
  top: -480px;

  @include breakpoint($bp-m) {
    @include x-rem(margin-top, 390px);
    @include x-rem(margin-bottom, -284px);
    top: -284px;
  }
}
.gray-curve-section__formbox {
  border-radius: 10px;
  border: 1px solid $color-gray4;
  background: $body-bg;
  box-shadow: $box-shadow1;
  @include x-rem(padding, 28px 25px 75px 25px);
  @include x-rem(margin-bottom, 50px);
  @include breakpoint($bp-m) {
    @include x-rem(padding, 32px 32px 86px 32px);
    @include x-rem(margin-bottom, 45px);
  }
}
.gray-curve-section__bottom {
}
// adjusting form styles for the section

.gray-curve-section form {
  input[type="checkbox"] {
    @include x-rem(height, 23px);
    @include x-rem(width, 23px);
  }
  input[type="radio"] {
    @include x-rem(height, 15px);
    @include x-rem(width, 15px);
  }
  input[type="submit"] {
    display: block;
    margin: 0 auto;
  }

  a {
    @extend .heavy-link;
    text-underline-offset: initial;
    font-weight: $fontWeight-bold !important;
    font-size: 1rem;
    line-height: normal;
  }

  .editing-form-control-nested-control .ktc-checkbox {
    &:before {
      position: absolute;
      bottom: 250px;
      left: 50%;
      transform: translateX(-50%);
      width: 93%;
      height: 1px;
      background: $color-gray4;
    }
  }
}
.gray-curve-section__formbox .formwidget-submit-text {
  text-align: center;
  padding: 50px 0 0 0;
  @include breakpoint($bp-m) {
    padding: 132px 20px 100px 20px;
  }
}
.gray-curve-section__formbox .formwidget-submit-text h2 {
  font-size: 30px;
  line-height: normal;
  margin-bottom: 15px;
}
.gray-curve-section__formbox .formwidget-submit-text {
}
// negating the footer overlap
.section-container.footer-push,
.footer-push {
  @include x-rem(padding-bottom, 198px);
  @include breakpoint($bp-small) {
    @include x-rem(padding-bottom, 233px);
  }

  @include breakpoint($bp-m) {
    @include x-rem(padding-bottom, 331px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(padding-bottom, 365px);
  }
}
