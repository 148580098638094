.side-by-side {
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 36px);
  @include breakpoint($bp-small) {
    @include x-rem(gap, 76px);
  }

  @include breakpoint($bp-large) {
    flex-direction: row;
    @include x-rem(gap, 23px);
  }
}

.side-by-side__textbox {
  @include breakpoint($bp-large) {
    flex: 1;
  }
}

.side-by-side__title {
  @include x-rem(margin-bottom, 34px);
  text-align: center;
  @include breakpoint($bp-large) {
    text-align: left;
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(margin-bottom, 24px);
  }
}
.side-by-side__content {
  @include x-rem(margin-bottom, 41px);
  p:last-child {
    margin: 0;
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(margin-bottom, 32px);
  }
}
.side-by-side__cta {
  display: block;
  margin: 0 auto;
  width: fit-content;
  @include breakpoint($bp-large) {
    margin: 0;
  }
}
.side-by-side__img {
  width: 100%;
  @include x-rem(height, 300px);

  @include breakpoint($bp-small) {
    @include x-rem(max-width, 465px);
    margin: 0 auto;
  }
  @include breakpoint($bp-large) {
    margin: 0;
    max-width: 100%;
    flex: 1;
    //placeholder
    height: 600px;
  }
}
// with table
.side-table-box {
  @include x-rem(padding-top, 58px);
  @include x-rem(padding-bottom, 100px);
  @include breakpoint($bp-large) {
    @include x-rem(padding-top, 100px);
  }
}
.side-table-box .section-title {
  margin: 0;
  @include x-rem(margin-bottom, 36px);
  h2 {
    @include x-rem(margin-bottom, 32px);
  }
}
.sbs-table {
}
.sbs-table__img {
  display: none;
  position: absolute;
  bottom: -100px;
  z-index: 222;
  @include x-rem(height, 825px);

  @include breakpoint($bp-m) {
    display: block;
    left: -110px;
  }
  @include breakpoint($bp-large) {
    left: 0;
  }
}
