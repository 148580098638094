.share-page-box {
  @include x-rem(padding-top, 50px);
  border-top: 1px solid $color-gray4;
  @include breakpoint($bp-m) {
    @include x-rem(padding-top, 64px);
  }
}
.share-page-box .title-box {
  margin-bottom: 0;
}
.share-icons {
  @include resetList;
  width: fit-content;
  @include x-rem(min-width, 192px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  @include breakpoint($bp-m) {
    @include x-rem(min-width, 295px);
  }
}
.share-icons__item {
}
.share-icons__link {
  display: flex;
  &:hover,
  &:focus {
    .share-icons__icon {
      fill: $color-yellow;
    }
  }
}
.share-icons__icon,
.copy-link-btn__icon {
  @include x-rem(height, 28px);
  @include x-rem(width, 28px);
  @include breakpoint($bp-m) {
    @include x-rem(height, 35px);
    @include x-rem(width, 35px);
  }
}
.copy-link-btn, .copy-modal-btn {
  @include resetBtn;
  &:hover,
  &:focus {
    .copy-link-btn__icon {
      fill: $color-yellow;
    }
  }
}
.copy-link-btn__status {
  display: none;
  margin: 0;
}
.copy-link-btn.copied {
  pointer-events: none;
  cursor: text;
  .copy-link-btn__icon {
    display: none;
  }
  .copy-link-btn__status {
    display: block;
  }
}
// modal
.copy-modal {
  .modaal-close {
    @extend .visually-hidden;
    visibility: hidden;
  }
  .copy-link-modal__exit-modal {
    @include resetBtn;

    &:hover,
    &:focus {
      .copy-link-modal__exit-svg {
        transform: rotate(90deg);
      }
    }
  }
  .copy-link-modal__exit-svg {
    @include x-rem(height, 16px);
    @include x-rem(width, 16px);
    position: absolute;
    top: 27px;
    right: 29px;
  }
  .modaal-inner-wrapper {
    @include x-rem(padding, 10px);
  }
  .modaal-container {
    border-radius: 10px;
    border: 1px solid $color-gray2;
    background: $body-bg;
    box-shadow: $box-shadow1;
    position: relative;
  }
  .modaal-content-container {
    @include x-rem(padding, 15px);
    @include breakpoint(32.5em) {
      @include x-rem(padding, 58px 78px 90px 78px);
    }
  }
  .copy-modal__label1 {
    @include x-rem(font-size, 26px);
    line-height: normal;
    @include x-rem(margin-bottom, 12px);
    letter-spacing: -0.26px;
    font-weight: $fontWeight-bold;
    color: $color-primary;
  }
  .copy-modal__headline {
    @include x-rem(font-size, 34px);
    line-height: normal;
    letter-spacing: -0.34px;
    @include x-rem(margin-bottom, 54px);
  }
  .copy-link-modal__input {
    width: 100%;
  }
  .copy-link-modal__btns {
    @include x-rem(margin-top, 28px);
    display: flex;
    flex-direction: column;
    @include x-rem(gap, 14px);
    button {
      @include x-rem(min-width, 144px);
    }

    @include breakpoint($bp-small) {
      flex-direction: row;
      justify-content: center;
    }
  }
  .copy-link-modal__copy.copied {
    display: none;
  }
  .copy-link-btn__status.copied {
    display: flex;
    align-self: center;
  }
}
