.blog-process{
    @include resetList;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    text-align: center;
    @include breakpoint($bp-m){
        flex-direction: row;
        gap: 40px;
        text-align: left;
    }
}