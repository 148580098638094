.location-form {
  background: $body-bg;
  border: 1px solid $color-gray3;
  box-shadow: 25px 25px 50px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  @include x-rem(padding, 50px 20px 43px 20px);

  input,
  select {
    width: 100%;
    border: 1px solid $color-gray5;
    @include x-rem(height, 58px);
    @include x-rem(padding, 0 21px);
    color: $color-primary;
    @include x-rem(font-size, 18px);
    @include x-rem(line-height, 26px);
    font-weight: $fontWeight-bold;
  }
  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    &::-ms-expand {
      display: none;
    }
  }

  input::placeholder {
    color: $color-primary;
  }
  @include breakpoint($bp-small) {
    @include x-rem(padding, 50px 20px 43px 20px);
  }

  @include breakpoint($bp-xx-large) {
    @include x-rem(padding, 52px 80px 52px 68px);
  }
}
.location-form__wrapper {
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 23px);

  @include breakpoint($bp-m) {
    flex-direction: row;
    .location-form__select-wrapper,
    .location-form__input {
      flex: 1;
    }
    .location-form__select-wrapper.visually-hidden {
      flex: 0;
      width: 0;
      height: 0;
    }
  }
}
.location-form__select-wrapper {
  position: relative;
}
.location-form__select-svg {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  @include x-rem(height, 7px);
  @include x-rem(width, 12px);
}
.location-form__submit {
  display: block;
  width: fit-content;
  margin: 0 auto;
  @include x-rem(padding, 16px 32px);
  @include x-rem(margin-top, 30px);
  @include breakpoint($bp-m) {
    @include x-rem(margin-top, 35px);
  }
}
