.hero-curve {
  position: relative;
  &:before {
    content: "";
    background-image: url("../images/shapes/bottomcurve.png");
    @include bgImg;
    background-size: cover;
    background-position: bottom;
    position: absolute;
    z-index: 3;
    width: 100%;
    bottom: -1px;
    left: 0;
    height: 100%;
  }
}
.squared-hero {
  position: relative;
  background: $color-primary;
  @include x-rem(padding, 92px 0 115px 0);
  @include breakpoint($bp-m) {
    @include x-rem(padding, 150px 0 185px 0);
  }
}

.squared-hero__box {
  @include bgImg;
  position: relative;
  border-radius: 10px;
  box-shadow: $box-shadow1;
  @include x-rem(padding, 163px 26px 30px 22px);

  &:before {
    content: "";
    border-radius: 10px;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: rgba(0, 0, 0, 0.35);
  }
  @include breakpoint($bp-small) {
    @include x-rem(padding, 252px 34px 32px 30px);
  }

  @include breakpoint($bp-m) {
    @include x-rem(padding, 271px 30px 32px 30px);
  }

  @include breakpoint($bp-large) {
    @include x-rem(padding, 318px 25px 53px 56px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(padding, 318px 25px 53px 56px);
  }
  @include breakpoint($bp-xx-large) {
    @include x-rem(padding, 318px 334px 53px 56px);
  }
}
.squared-hero__textbox {
  position: relative;
  z-index: 3;
  @extend .whiten-text;
  width: 100%;

  @include breakpoint($bp-large) {
    @include x-rem(max-width, 868px);
  }
}
.squared-hero__title {
  @include x-rem(font-size, 34px);
  @include x-rem(line-height, 40px);
  font-weight: $fontWeight-bold;
  @include x-rem(margin-bottom, 14px);
  @include breakpoint($bp-m) {
    @include x-rem(font-size, 42px);
    @include x-rem(line-height, 46px);
    @include x-rem(margin-bottom, 18px);
  }
}
.squared-hero__text {
  @include x-rem(margin-bottom, 18px);
  p:last-child {
    margin: 0;
  }
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 34px);
  }
}
