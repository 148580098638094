/* ================================================================
   News - Majority of styling is done as part of the lists.scss
   ================================================================ */

.news {
    &.detail { }

    &.listing { }
}

