.infographic-box {
  scroll-margin-top: 100px;
}
// dark variant theme new
.infographic-box--dark {
  border: 2px solid $color-primary;
  .infographic-buttons {
  }
  .infographic-buttons__button {
    background: $color-primary;
    color: $color-invert;
    border: none;
    text-align: left;
    @include breakpoint($bp-small) {
      text-align: center;
    }
    &:hover,
    &:focus,
    &.active {
      color: $color-primary;
      background: $body-bg;
    }
    &.active {
      border-bottom: none;
    }
  }
  .infographic__content {
    @include x-rem(padding, 66px 23px 52px 23px);

    @include breakpoint($bp-large) {
      @include x-rem(padding, 66px 23px 52px 23px);
    }
  }
}
 
// light variant theme
.infographic-box--light {
  .infographic-buttons {
    padding: 0 1px;
  }
  .infographic-buttons__button {
    background: $body-bg;
    color: $color-primary;
    margin: -1px;
    &:hover,
    &:focus,
    &.active {
      color: $color-invert;
      background: $color-primary;
    }
  }
  .infographic-data{
    margin-top: 1px;
  }
}
.infographic-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include breakpoint($bp-m) {
    flex-wrap: nowrap;
  }
}
.infographic-buttons__button {
  @include resetBtn;
  border: 2px solid $color-primary;
  text-align: center;
  @include x-rem(padding, 15px 10px);
  width: 50%;
  font-weight: $fontWeight-bold;
  @include x-rem(font-size, 18px);
  @include x-rem(line-height, 22px);
  @include x-rem(min-height, 81px);
}
.infographic-buttons__button {
  @include breakpoint($bp-m) {
    width: fit-content;
    flex: 1;
  }

  @include breakpoint($bp-large) {
    @include x-rem(font-size, 20px);
    @include x-rem(line-height, 27px);
    @include x-rem(min-height, 91px);
  }
}

.infographic__content {
  background: $body-bg;
  @include x-rem(padding-top, 57px);
  @include breakpoint($bp-m) {
    @include x-rem(padding-top, 62px);
  }
}

// tab-content
// globals

[class*="tab-"] {
}

// tab model
.tab-the-model {
}
.tab-the-model .tab-the-model__wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  @include x-rem(gap, 45px);
  @include x-rem(margin-bottom, 43px);

  @include breakpoint($bp-large) {
    @include x-rem(gap, 64px);
    flex-direction: row;
    @include x-rem(margin-bottom, 97px);
  }
}

.bottom-tab-border {
  border-bottom: 1px solid $color-gray4;
  @include x-rem(padding-bottom, 50px);
  @include breakpoint($bp-large) {
    @include x-rem(padding-bottom, 100px);
  }
}
.last-para-element {
  p:last-child {
    margin: 0;
  }
}
.tab-the-model .flex-box-item {
  @include breakpoint($bp-large) {
    width: 50%;
  }
}
.tab-the-model .disc-model-grid {
  display: grid;
  grid-template-columns: auto;
  width: fit-content;
  @include x-rem(gap, 28px);

  .disc-model-grid__item {
    display: flex;
    align-items: center;
    @include x-rem(gap, 12px);
  }
  .margin-b-0 {
    @include x-rem(font-size, 20px);
  }

  @include breakpoint($bp-small) {
    grid-template-columns: 1fr 1fr;
    @include x-rem(gap, 32px);
    width: 100%;

    .disc-model-grid__item {
      @include x-rem(gap, 8px);
    }
  }
  @include breakpoint($bp-large) {
    grid-template-columns: auto auto;
  }
}
.tab-two-dimension .flex-box {
  align-items: center;
}
.tab-two-dimension .flex-box-item__img {
  flex-shrink: 0;
}
// disc research
.tab-disc-research {
  @include x-rem(padding-top, 57px);

  @include breakpoint($bp-large) {
    @include x-rem(padding-top, 77px);
  }
}

// scales

.tab-scales {
  .scales-grid {
    @include resetList;
    display: flex;
    flex-wrap: wrap;
    @include x-rem(gap, 77px);
    @include x-rem(margin-top, 77px);
    @include breakpoint($bp-m) {
      @include x-rem(margin-top, 154px);
      @include x-rem(gap, 86px 32px);
    }
  }
  .scales-grid__item {
    position: relative;
    width: 100%;
    @include x-rem(padding, 32px);
    border-radius: 10px;
    border: 1px solid $category-green;

    @include breakpoint($bp-m) {
      width: calc(50% - 16px);
    }

    @include breakpoint($bp-l) {
      width: calc(33.33333% - 22px);
    }

    @include breakpoint($bp-x-large) {
      width: calc(25% - 24px);
    }
    &--green {
      border-color: $category-green;
    }
    &--red {
      border-color: $category-red;
    }
    &--blue {
      border-color: $category-blue;
    }
    &--yellow {
      border-color: $category-yellow;
    }
  }
  .scales-grid__text {
    margin: 0;
  }
  .scales-grid__icon {
    position: absolute;
    top: -55px;
    right: 32px;
    @include x-rem(height, 76px);
    @include x-rem(width, 62px);
  }
}
// everything disc horizontal / vertical
.tab-two-dimensions2 .tab-two-dimensions2__flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include x-rem(gap, 30px);
  @include x-rem(margin-bottom, 65px);
  @include breakpoint($bp-m) {
    flex-direction: row;
    @include x-rem(gap, 32px);
  }
}
.tab-two-dimensions2__text {
  flex: 1;
}
.tab-two-dimensions2__text h3 {
  text-align: center;
  @include breakpoint($bp-m) {
    text-align: left;
  }
}
.tab-two-dimensions2__img {
  flex-shrink: 0;
}
// dos and donts

.dos-and-donts-box {
}
.dos-and-donts {
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 25px);
}
.dos-and-donts__item {
  display: flex;
}

// disc research
.validation-infographic {
  @include x-rem(margin-bottom, 77px);
  h3 {
    text-align: center;
    @include breakpoint($bp-m) {
      text-align: left;
    }
  }
}
.validation-infographic__wrapper {
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 66px);
  @include breakpoint($bp-m) {
    flex-direction: row;
    @include x-rem(gap, 63px);
    align-items: center;
  }
}
.validation-infographic__headline {
  @include x-rem(font-size, 30px);
  line-height: normal;
  @include x-rem(margin-bottom, 55px);
  @include breakpoint($bp-m) {
    @include x-rem(font-size, 32px);
  }
}
.validation-infographic__col1,
.validation-infographic__col2 {
  @include breakpoint($bp-m) {
    width: 50%;
  }
  @include breakpoint($bp-x-large) {
    width: 50%;
  }
}
.validation-infographic__col1 {
  @include breakpoint($bp-x-large) {
    @include x-rem(max-width, 497px);
  }
}
.validation-infographic__col2 {
  @include breakpoint($bp-x-large) {
    flex: 1;
  }
}
//dotplacement
.tab-dotplacement-box {
}
.dotplacement-circles {
  @include resetList;
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 20px);
  align-items: center;
  @include x-rem(margin, 20px 0 55px 0);
  @include breakpoint($bp-m) {
    flex-direction: row;
    justify-content: space-between;
  }
  @include breakpoint($bp-xx-large) {
    @include x-rem(gap, 78px);
  }
}
.disc-relationship-box__img {
  @include x-rem(margin, 36px 0 56px 0);
  @include breakpoint($bp-m) {
    @include x-rem(margin, 64px 0);
  }
}
// dos/donts
.tab-donts-box__main-header {
  @include x-rem(margin-bottom, 38px);
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 64px);
  }
}
.tab-donts-box__min-header {
  @include x-rem(margin-bottom, 32px);
}
.tab-donts-box__block1 {
  @include x-rem(margin-bottom, 44px);
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 64px);
  }
}
.dos-listing {
  @include resetList;
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 32px);
  @include breakpoint($bp-m) {
    @include x-rem(gap, 70px);
  }
}
.dos-listing__item {
  display: flex;
  flex-direction: column-reverse;
  @include x-rem(gap, 32px);
  @include breakpoint($bp-m) {
    flex-direction: row;
    align-items: center;
    &.dos-listing__item--reverse {
      flex-direction: row-reverse;
    }
  }
}
.dos-listing__content-block {
  h3 {
    line-height: normal;
    @include x-rem(margin-bottom, 38px);
    @include breakpoint($bp-m) {
      @include x-rem(margin-bottom, 16px);
    }
  }
}
.dos-listing__imgbox {
  flex-shrink: 0;
  @include x-rem(height, 352px);
  img {
    @include fitImage;
  }
}
.dos-listing__imgbox,
.dos-listing__content-block {
  @include breakpoint($bp-m) {
    width: 50%;
  }
}
// specific bottom padding for each content tab

.tab-the-model {
  @include x-rem(padding-bottom, 40px);
  @include breakpoint($bp-m) {
    @include x-rem(padding-bottom, 100px);
  }
}
.tabs-fourquads-box {
  @include x-rem(padding-bottom, 52px);
  @include breakpoint($bp-m) {
    @include x-rem(padding-bottom, 100px);
  }
}
.tab-two-dimensions2 {
  @include x-rem(padding-bottom, 70px);
  @include breakpoint($bp-m) {
    @include x-rem(padding-bottom, 151px);
  }
}
.disc-research-box {
  @include x-rem(padding-bottom, 70px);
  @include breakpoint($bp-m) {
    @include x-rem(padding-bottom, 100px);
  }
}
.tab-scales {
  @include x-rem(padding-bottom, 92px);
  @include breakpoint($bp-m) {
    @include x-rem(padding-bottom, 100px);
  }
}

.tab-table-box {
  @include x-rem(padding-bottom, 80px);
  @include breakpoint($bp-m) {
    @include x-rem(padding-bottom, 100px);
  }
}
.tab-table-box--huge {
  @include x-rem(padding-bottom, 90px);
  @include breakpoint($bp-m) {
    @include x-rem(padding-bottom, 100px);
  }
}
.tab-results-box {
  @include x-rem(padding-bottom, 64px);
}
.tab-dotplacement-box {
  @include x-rem(padding-bottom, 74px);
}
.tab-shading-box {
  @include x-rem(padding-bottom, 100px);
  @include breakpoint($bp-m) {
    @include x-rem(padding-bottom, 140px);
  }
}
.tab-shading-box .tab-shading-box__svg {
  @include x-rem(width, 300px);
  @include x-rem(height, 300px);
  @include breakpoint($bp-small) {
    @include x-rem(width, 435px);
    @include x-rem(height, 435px);
  }
  @include breakpoint($bp-large) {
    @include x-rem(width, 500px);
    @include x-rem(height, 500px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(width, 550px);
    @include x-rem(height, 550px);
  }
}
.disc-relationship-box {
  @include x-rem(padding-bottom, 70px);
  @include breakpoint($bp-m) {
    @include x-rem(padding-bottom, 94px);
  }
}
.tab-dos-box {
  @include x-rem(padding-bottom, 70px);
  @include breakpoint($bp-m) {
    @include x-rem(padding-bottom, 100px);
  }
}
.tab-twelve-box {
  @include x-rem(padding-bottom, 80px);
  @include breakpoint($bp-m) {
    @include x-rem(padding-bottom, 120px);
  }
}
//  tab variant 2 contents
.tab2-simple-wrapper {
}
p.tab2-simple__flavortxt {
  text-align: center;
  @include x-rem(margin-bottom, 45px);
}
.tab2-simple {
  @include resetList;
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 20px);
  @include breakpoint($bp-x-large) {
    flex-direction: row;
    justify-content: space-between;
    @include x-rem(gap, 32px);
  }
}
.tab2-simple__item {
  display: flex;
  @include x-rem(gap, 15px);
}
.tab2-simple__svg {
  flex-shrink: 0;
  @include x-rem(width, 68px);
  @include x-rem(height, 56px);
  @include breakpoint($bp-large) {
    @include x-rem(width, 80px);
    @include x-rem(height, 69px);
  }
}
.tab2-simple__content {
  margin: 0;
  font-size: 1rem;
  @include x-rem(line-height, 24px);
}
