$accordion-blue: #a2d3eb;
.blog-accordions {
  @include resetList;
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 5px);
}

.blog-accordions__button {
  @include resetBtn;
  background: $accordion-blue;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include x-rem(gap, 10px);
  @include x-rem(padding, 10px);
  &:hover,
  &:focus {
    .blog-accordions__svg {
      transform: scale(1.3);
    }
  }
}
.blog-accordions__title {
  @include x-rem(font-size, 20px);
  font-weight: $fontWeight-bold;
  color: $color-primary;
  margin: 0;
  flex: 1;
}
.blog-accordions__svg {
  @include x-rem(height, 30px);
  @include x-rem(width, 30px);
  margin-left: auto;
}
.blog-accordions__svg--up {
  display: none;
}
.blog-accordions__button.active {
  .blog-accordions__svg--up {
    display: flex;
  }
  .blog-accordions__svg--down {
    display: none;
  }
}
.blog-accordions__content {
  @include x-rem(padding, 7px 0 43px 0);
}

.blog-accordions__item {
  &:last-child {
    .blog-accordions__content {
      @include x-rem(padding, 7px 0 0 0);
    }
  }
}
.blog-accordions__link {
  display: flex;
  justify-content: center;
}
