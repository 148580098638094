.qna-box {
  scroll-snap-type: y mandatory; 
}

.category-links-box {
  border-bottom: 1px solid $color-gray4;
  @include x-rem(padding-bottom, 52px);
  @include x-rem(margin-bottom, 32px);
  @include breakpoint($bp-large) {
    @include x-rem(padding-bottom, 40px);
  }
}
.category-links {
  @include resetList;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include x-rem(gap, 10px);
  @include breakpoint($bp-large) {
    @include x-rem(gap, 20px);
  }
}
.category-links__item {
  width: fit-content;
}
.category-links__link {
  @include x-rem(padding, 10px 12px);
  color: $color-base;
  font-weight: $fontWeight-bold;
  font-size: 1rem;
  @include x-rem(line-height, 36px);
  text-decoration: none;
  position: relative;

  &:after {
    content: "";
    display: block;
    width: calc(100% - 24px);
    height: 2px;
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    background: $color-base;
  }
  &:hover,
  &:focus {
    background: $color-gray2;
  }
}
// faq list/container
.qna-section {
  width: 100%;
  @include x-rem(max-width, 1152px);
  margin: 0 auto;
}
.qna-container {
  @include x-rem(padding-bottom, 95px);
  @include x-rem(margin-bottom, 32px);
  scroll-snap-align: start;
  scroll-margin-top: 91px;
  @include breakpoint($bp-m) {
    @include x-rem(padding-bottom, 74px);
  }
  .qna-container {
    padding: 0;
    border-bottom: none;
    margin: 0;
  }
  &--last {
    padding: 0;
    margin: 0;
  }
}

.qna-container__title {
  border-radius: 10px;
  @include x-rem(margin-bottom, 3px);
  @include x-rem(font-size, 26px);
  @include x-rem(line-height, 35px);
  @include breakpoint($bp-m) {
    @include x-rem(font-size, 32px);
    @include x-rem(line-height, 44px);
    @include x-rem(padding-left, 32px);
  }
}
.faqs-tab {
  border-radius: 10px;
  @include x-rem(padding, 0 21px 0 29px);
  @include breakpoint($bp-m) {
    @include x-rem(padding, 0 32px);
  }
  &.active {
    border: 1px solid $color-gray2;
    box-shadow: $box-shadow1;
    @include x-rem(margin, 45px 0);
  }
  &--first {
    &.active {
      margin-top: 0;
    }
  }
}

.faqs-tab__btn {
  @include resetBtn;
  text-align: left;
  font-weight: $fontWeight-bold;
  @include x-rem(font-size, 20px);
  @include x-rem(line-height, 27px);
  letter-spacing: -0.01em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include x-rem(gap, 10px);
  @include x-rem(padding-top, 40px);
  @include x-rem(padding-bottom, 25px);
  border-bottom: 1px solid $color-gray4;
  &.active {
    border-bottom: none;
    .faqs-tab__svg--down {
      display: none;
    }
    .faqs-tab__svg--up {
      display: flex;
    }
  }
  &:hover,
  &:focus {
    svg {
      transform: scale(1.3) rotate(360deg);
    }
  }
}
.qna-container .faqs-tab:last-child {
  button {
    border-bottom: none;
  }
}
.faqs-tab__svg {
  transition: $trans;
  flex-shrink: 0;
  @include x-rem(height, 14px);
  @include x-rem(width, 24px);
  &--down {
  }
  &--up {
    display: none;
  }
}
.faqs-tab__content {
  p:last-child {
    margin: 0;
  }
  @include x-rem(padding-bottom, 38px);

  @include breakpoint($bp-m) {
    @include x-rem(padding-bottom, 70px);
  }
}
