.random-img {
  position: relative;
  width: 100%;
  @include x-rem(height, 285px);

  @include breakpoint($bp-small) {
    @include x-rem(height, 305px);
  }
  @include breakpoint($bp-m) {
    @include x-rem(height, 391px);
  }
  @include breakpoint($bp-large) {
    @include x-rem(height, 507px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(height, 615px);
  }
  @include breakpoint($bp-xx-large) {
    @include x-rem(height, 708px);
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
}
// .random-img {
//   position: relative;
//   width: 100%;
//   padding-bottom: 76.25%;

//   @include breakpoint($bp-small) {
//     padding-bottom: 53%;
//   }
//   @include breakpoint($bp-m) {
//     padding-bottom: 53%;
//   }
//   @include breakpoint($bp-large) {
//     padding-bottom: 50%;
//   }
//   @include breakpoint($bp-x-large) {
//     padding-bottom: 51.25%;
//   }
//   @include breakpoint($bp-xx-large) {
//     padding-bottom: 49.2%;
//   }

//   img {
//     height: 100%;
//     width: 100%;
//     object-fit: cover;
//     position: absolute;
//     top: 0;
//     left: 0;
//   }
// }
