//start top footer
.site-footer {
  position: relative;
  z-index: 3;
  @include x-rem(margin-top, -80px);
  @include breakpoint($bp-small) {
    @include x-rem(margin-top, -110px);
  }

  @include breakpoint($bp-m) {
    @include x-rem(margin-top, -210px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(margin-top, -250px);
  }
}
.footer-top {
  background-image: url("../images/shapes/footer-curve.png");
  @include bgImg;
  position: relative;
  @include x-rem(padding, 73px 0 35px 0);

  @include breakpoint($bp-small) {
    @include x-rem(padding, 83px 0 43px 0);
  }
  @include breakpoint($bp-m) {
    @include x-rem(padding, 78px 0 48px 0);
  }
  @include breakpoint($bp-large) {
    @include x-rem(padding, 106px 0 57px 0);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(padding, 150px 0 62px 0);
  }
  @include breakpoint($bp-xx-large) {
    @include x-rem(padding, 157px 0 55px 0);
    background-position: top;
  }
}
.footer-main {
  @include x-rem(margin-bottom, 47px);
  @include breakpoint($bp-x-small) {
  }
  @include breakpoint($bp-small) {
    @include x-rem(margin-bottom, 43px);
  }
  @include breakpoint($bp-m) {
    @include x-rem(margin-bottom, 41px);
  }
  @include breakpoint($bp-large) {
    @include x-rem(margin-bottom, 57px);
    column-count: initial;
    display: flex;
    @include x-rem(gap, 15px);
    justify-content: space-between;
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(margin-bottom, 64px);
    @include x-rem(gap, 40px);
  }
}
.footer-social-box {
  width: calc(50% - 8px);

  @include breakpoint($bp-small) {
    width: calc(33.3333333% - 25px);
  }
  @include breakpoint($bp-large) {
    width: calc(25% - 30px);
  }
  @include breakpoint(68.75em) {
    width: initial;
  }
  @include breakpoint($bp-x-large-m) {
    @include x-rem(margin-right, 144px);
  }
}
.footer-logo {
  a {
    display: inline-block;
  }
}
.footer-logo__svg {
  @include x-rem(height, 46px);
  @include x-rem(width, 158px);
  @include x-rem(margin-bottom, 26px);

  @include breakpoint($bp-small) {
    @include x-rem(height, 48px);
    @include x-rem(width, 163px);
    @include x-rem(margin-bottom, 41px);
  }
  @include breakpoint($bp-m) {
    @include x-rem(height, 57px);
    @include x-rem(width, 192px);
    @include x-rem(margin-bottom, 32px);
  }
}
.footer-socials {
  @include resetList;
  display: flex;
  width: 100%;
  @include x-rem(max-width, 126px);
}
.footer-socials__item {
  margin-right: 1rem;
}
.footer-socials__link {
  svg {
    @include x-rem(height, 28px);
    @include x-rem(width, 28px);
    fill: $color-invert;
  }

  &:hover,
  &:focus {
    svg {
      fill: $color-yellow;
    }
  }
}
.footer-nav {
  @include resetList;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @include x-rem(gap, 35px 15px);
  @include breakpoint($bp-small) {
    @include x-rem(gap, 26px 35px);
  }
  @include breakpoint($bp-large) {
    @include x-rem(gap, 58px 35px);
    justify-content: center;
  }
  @include breakpoint(68.75em) {
    @include x-rem(gap, 18px);
    flex-wrap: nowrap;
    @include x-rem(gap, 30px);
    justify-content: space-between;
  }
}
.footer-nav__item {
  width: calc(50% - 8px);

  @include breakpoint($bp-small) {
    width: calc(33.3333333% - 25px);
  }
  @include breakpoint($bp-large) {
    width: calc(25% - 30px);
  }
  @include breakpoint(68.75em) {
    width: initial;
    flex: 1;
    .footer-nav__item {
      flex: initial;
    }
  }
}
.footer-nav__heading {
  @include x-rem(font-size, 15px);
  @include x-rem(line-height, 20px);
  @include x-rem(margin-bottom, 16px);
  color: $color-invert;
  font-weight: $fontWeight-semibold;
}
.footer-nav--nested {
  @include resetList;
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 5px);
  li {
    margin: 0;
    width: 100%;
  }
}
.footer-nav__link {
  @include x-rem(font-size, 15px);
  @include x-rem(line-height, 22px);
  color: rgba($color-invert, 0.75);
  text-decoration: none;
  display: inline-block;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
.footer-util-links {
  width: calc(50% - 8px);

  @include breakpoint($bp-small) {
    width: calc(33.3333333% - 25px);
  }
  @include breakpoint($bp-large) {
    width: calc(25% - 30px);
  }
  @include breakpoint(68.75em) {
    width: initial;
  }
}
.footer-nav2 {
  @include resetList;
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 32px);
}

.footer-nav2__link {
  @include x-rem(font-size, 15px);
  @include x-rem(line-height, 20px);
  color: $color-invert;
  font-weight: $fontWeight-semibold;
  text-decoration: none;
  display: block;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

// copyright
.footer-copyright {
  width: 100%;
  @include x-rem(max-width, 727px);
  margin: 0 auto;
  & > * {
    color: rgba($color-invert, 0.75);
    @include x-rem(font-size, 12px);
    @include x-rem(line-height, 20px);
    text-align: center;
  }
  @include breakpoint($bp-small) {
    @include x-rem(line-height, 22px);
  }
  @include breakpoint($bp-x-large) {
    @include x-rem(max-width, 868px);
  }
}
// start bottom footer
.footer-bottom {
  background: $color-base;
  @include x-rem(padding, 30px 0 20px 0);

  @include breakpoint($bp-small) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.footer-bottom__container {
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 20px);
  @include breakpoint($bp-small) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.footer-bottom__svg {
  @include x-rem(width, 113px);
  @include x-rem(height, 24px);
}
.footer-links2 {
  @include resetList;
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 16px);

  @include breakpoint($bp-small) {
    flex-direction: row;
  }
}
.footer-links2__item {
  @include x-rem(padding-right, 8px);
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background: $body-bg;
  }
  @include breakpoint($bp-small) {
    @include x-rem(padding-right, 17px);
    &::after {
      content: "";
    }
  }
}
.footer-links2__item:last-child {
  &::after {
    content: none;
  }
}
.footer-links2__link {
  color: $color-invert;
  @include x-rem(font-size, 14px);
  @include x-rem(line-height, 19px);
  text-decoration: none;
  display: block;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
.footer-links2__link--btn {
  @include resetBtn;
}
.admin-container {
  .site-footer {
    margin-top: 0;
  }
}
